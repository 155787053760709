import { Table, TableProps } from "antd";
import { FunctionComponent, useMemo } from "react";
import { IBaseField, FieldTypes, IBaseFieldProps } from "./base";

export interface TableSelectOption extends Record<string, any> {
    key: string;
}

export interface ColumnHandler {
    title?: string;
    dataIndex?: string;
    key: string;
}

export interface IFieldTableSelect extends IBaseField {
    type: FieldTypes.TableSelect;
    dataSource: TableSelectOption[];
    columns?: (ColumnHandler | string)[];
    tableProps?: TableProps<any>;
}

export interface ITableSelectProps extends IBaseFieldProps {
    field: IFieldTableSelect;
    value: any;
    onChange: (input: any) => void;
}
export const TableSelect: FunctionComponent<ITableSelectProps> = ({ field, value, onChange }) => {
    const columns = useMemo(() => {
        return Object.keys(field.dataSource[0] || {})
            .filter(e => e !== "key")
            .map(e => ({
                title: e.pretty(),
                dataIndex: e,
                key: e
            }));
    }, [field.dataSource]);
    return (
        <Table
            rowSelection={{
                type: "radio",
                onSelect: onChange,
                selectedRowKeys: value
            }}
            dataSource={field.dataSource}
            columns={
                field.columns
                    ? field.columns.map(column => {
                          if (typeof column === "string")
                              return {
                                  title: column.pretty(),
                                  dataIndex: column,
                                  key: column
                              };
                          else return column;
                      })
                    : columns
            }
            pagination={{ pageSize: 5, showSizeChanger: false }}
            {...field.tableProps}
        />
    );
};
