import { FunctionComponent, useMemo } from "react";
import "antd/dist/antd.min.css";
import { Menu } from "antd";
import routes from "../../navigation/routes";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../images/logo.png";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

interface CRouteHandler {
    route: string;
    label: string;
}

interface Props {
    hasLogo?: boolean;
}

const NavBar: FunctionComponent<Props> = ({ hasLogo }) => {
    const user = useSelector((state: RootState) => state.system.currentUser);
    const navigate = useNavigate();
    const location = useLocation();

    const cRoutes:Record<string, CRouteHandler> = useMemo(() =>  ({
        home: {
            route: routes.home,
            label: "Home"
        },
        // dashboard: {
        //   route: routes.dashboard,
        //   label: "Dashboard",
        // },
        reports: {
            route: routes.reports,
            label: "Instant Reports"
        },
        templates: {
            route: routes.templates,
            label: "Templates"
        },
        users: {
            route: routes.users,
            label: "User Management"
        }
        // settings: {
        //   route: routes.settings,
        //   label: "Settings",
        // },
    }),[]);

    const cKey = useMemo<string>(() => {
        for (let key in cRoutes) {
            if (location.pathname.includes(cRoutes[key].route)) {
                return key;
            }
        }
        return Object.keys(cRoutes)[0];
    }, [location.pathname, cRoutes]);

    const handleClick = (e: any) => {
        navigate(cRoutes[e.key]?.route);
    };

    const filterRoutes = (key: string) => {
        if (key === "users") {
            return user?.accountAdmin === "true";
        }
        return user || key !== "dashboard";
    };

    return (
        <>
            <Menu onClick={handleClick} selectedKeys={[cKey]} mode="horizontal">
                {hasLogo !== false ? (
                    <Menu.Item id="menu-logo" key={"logo"} style={{ cursor: "pointer" }} disabled>
                        <div
                            onClick={() => {
                                handleClick({ key: "home" });
                            }}>
                            <img src={logo} style={{ maxWidth: 150 }} alt={"logo"} />
                        </div>
                    </Menu.Item>
                ) : null}
                {Object.keys(cRoutes)
                    .filter(filterRoutes)
                    .map(key => (
                        <Menu.Item
                            id={`menu-${key}`}
                            key={key}
                            //style={key === "users" ? { "marginLeft": "auto" } : {}} justify-right styling from User Management tab
                        >
                            {cRoutes[key].label}
                        </Menu.Item>
                    ))}
                {/*<Menu.Item key={"home"}>Home</Menu.Item>*/}
                {/*<Menu.Item key="dashboard">Dashboard</Menu.Item>*/}
                {/*<Menu.Item key="reports">Reports</Menu.Item>*/}
                {/*<Menu.Item key="templates">Templates</Menu.Item>*/}
            </Menu>
        </>
    );
};

export default NavBar;
