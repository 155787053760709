import { ColProps } from "antd/lib/grid/col";
import { DatePickerProps, InputProps, TableProps } from "antd";
import { TextProps } from "antd/lib/typography/Text";
import { SearchProps } from "antd/es/input";
import { CSSProperties } from "react";

export enum FieldTypes {
    RadioGroup = "radioGroup",
    checkboxGroup = "checkboxGroup",
    inputText = "inputText",
    inputPassword = "inputPassword",
    Date = "date",
    dateRange = "dateRange",
    hidden = "hidden",
    TableSelect = "tableSelect",
    Label = "label",
    Select = "select",
    LabelWithValue="labelwithValue"
}

export interface Field {
    label?: string;
    key: string;
    colProps?: ColProps;
    isRequired?: boolean;
    style?: CSSProperties
    containerStyle?: CSSProperties;
    disabled?: boolean;
    className?: string;
}

export interface CompanyType {
    value?: string;
    label?: string;
}
export interface OptionType {
    value: string;
    label?: string;
}

export interface FieldRadioGroup extends Field {
    type: FieldTypes.RadioGroup;
    options: OptionType[];
}

export interface FieldCheckboxGroup extends Field {
    type: FieldTypes.checkboxGroup;
    options: OptionType[];
}

export interface FieldInputText extends Field {
    type: FieldTypes.inputText;
    inputProps?: InputProps | SearchProps;
    mode?: "search";
}

export interface FieldInputPassword extends Field {
    type: FieldTypes.inputPassword;
}

export interface FieldDate extends Field {
    type: FieldTypes.Date;
    inputProps?: DatePickerProps;
    mode?: "range";
}

export interface FieldHidden extends Field {
    type: FieldTypes.hidden;
}

export interface TableSelectOption extends Record<string, any> {
    key: string;
}

export interface SelectOption {
    label?: string;
    value: string;
}

export interface ColumnHandler {
    title?: string;
    dataIndex?: string;
    key: string;
}

export interface FieldLabel extends Field {
    type: FieldTypes.Label;
    textProps?: TextProps;
}

export interface FieldLabelWithValue extends Field {
    type: FieldTypes.LabelWithValue;
    textProps?: TextProps;
    value: CompanyType[];
}
export interface FieldTableSelect extends Field {
    type: FieldTypes.TableSelect;
    dataSource: TableSelectOption[];
    columns?: (ColumnHandler | string)[];
    tableProps?: TableProps<any>;
}

export interface FieldSelect extends Field {
    type: FieldTypes.Select;
    options: (SelectOption | string)[];
}

export type FieldErrors = {[key:string]:string}

export type FieldType =
    | FieldRadioGroup
    | FieldCheckboxGroup
    | FieldInputText
    | FieldInputPassword
    | FieldDate
    | FieldHidden
    | FieldLabel
    | FieldTableSelect
    | FieldSelect
    | FieldLabelWithValue
