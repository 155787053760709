import { Button, Col, Row, Table, Tag, Modal, Tooltip } from "antd";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useDeleteUserMutation, useLazyGetUsersQuery, useUpdateUserMutation } from "../../../redux/actions/userApi";
import { IAccount, IClient, IUser, User } from "../../../shapes/multiclient";
import { generateColumn } from "../../../utils";
import UserEditor from "./UserEditor";
import UserActions from "./UserActions";
import { useInterval } from "usehooks-ts";
import { TABLE_REFRESH_INTERVAL_MSECS } from "../../../utils/types";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { withCustomTable } from "../../../hoc/withCustomTable";
import { EditorMode, EditorRecordType } from "../../../components/Editor";
import UserDetails from "./UserDetails";
import { pretty } from "../../../utils/inject";

const UserManagement: FunctionComponent = () => {
    const [getUserList, userList] = useLazyGetUsersQuery();
    const currentUser: any = useSelector<RootState>(state => state.system.currentUser);
    const [userState, updateUserState] = useState<EditorRecordType>(new User(currentUser.accountId));
    const [mode, setMode] = useState<EditorMode | "view">("closed");
    const [updateUser] = useUpdateUserMutation();
    const [deleteUser] = useDeleteUserMutation();
    
    useInterval(
        () => {
            if (!userList.isFetching) {
                getUserList({});
            }
        },
        mode !== "closed" ? null : TABLE_REFRESH_INTERVAL_MSECS
    );

    useEffect(() => {
        getUserList({}, true);
    }, [getUserList]);

    type AugmentedUser = IUser & { accountName: string, accountMFAEnabled: boolean };

    const isLoading = currentUser.isFetching || userList.isFetching;

    const accountList = useMemo(
        () => ({
            isLoading: false,
            isFetching: false,
            isSuccess: true,
            data: [
                {
                    accountId: currentUser?.accountId,
                    accountName: "",
                    state: "active",
                    maxClients: 0,
                    maxUsers: 0,
                    maxLeases: 0
                }
            ] as IAccount[]
        }),
        [currentUser?.accountId]
    );

    const dataSource = useMemo<AugmentedUser[]>(() => {
        if (!currentUser?.accountId || !userList?.data) return [];
        return (userList?.data || []).map((user: IUser) => {
            return { ...user, accountMFAEnabled: currentUser?.accountMFAEnabled };
        });
    }, [currentUser?.accountId, userList?.data, currentUser?.accountMFAEnabled]);
    interface ClientObject {
        name: string;
        roles: string;
    }

    const clientList = useMemo(
        () => ({
            isLoading: false,
            isFetching: false,
            isSuccess: true,
            data: (Object.entries(currentUser?.clients) as [string, ClientObject][]).map(([key, value]) => ({
                accountId: currentUser?.accountId,
                clientId: key,
                clientName: value.name,
                roles: value.roles,
                maxUsers: 0,
                maxLeases: 0,
                state: "active",
                trialEndDate: new Date(),
                usage: "",
                users: {},
                version: 0,
                isDeleted: false
            })) as IClient[]
        }),
        [currentUser?.accountId, currentUser?.clients]
    );

    const UsersTable = withCustomTable<AugmentedUser>(Table as any);
    
    function getTagColor(state: string, federation?: string): string {
        switch (state) {
            case "disabled":
                return "darkgray";
            case "invite set":
                return federation ? "green" : "orange";
            case "active":
            default:
                return "green";
        }
    }

    const columns = useMemo(
        () => [
            generateColumn({ key: "username", title: "Username" }),
            generateColumn({ key: "email", title: "Email" }),
            generateColumn({
                key: "state",
                title: "Status",
                render: (val: any, record: any) => {
                    return <>
                        <Tag style={{ width: 80, textAlign: "center" }} color={getTagColor(val, record.usesFederation)}>{pretty(val || "")}</Tag>
                        {record.accountMFAEnabled && record.mfaEnabled === true ? <Tag style={{ width: 80, textAlign: "center" }} color="blue">MFA</Tag> : null}
                        {record.usesFederation ? <Tooltip title={`Third-Party SSO through '${record.usesFederation}'`}><Tag style={{ width: 80, textAlign: "center" }} color="red">SSO</Tag></Tooltip> : null}
                    </>;
                },
                onFilter: (value: string, record: any) => {
                    return record.accountMFAEnabled && record.mfaEnabled === true ? record.state.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) >= 0 || "mfa".indexOf(value.toLocaleLowerCase()) >= 0 :
                        record.state.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) >= 0;
                }
            }),            
            generateColumn({
                key: "action",
                sorter: false,
                onFilter: false,
                title: "Action",
                render: (val: any, record: any) => {
                    return (
                        <UserActions
                            record={record}
                            setEditorMode={setMode}
                            setSelectedUser={updateUserState}
                        />
                    );
                }
            })
        ],
        [setMode, updateUserState]
    );

    const exportConfig = {
        baseFileName: "User List.xlsx",
        includedAttributes: ["username", "email", "state", "mfaEnabled"],
        mainTabName: "Users",
        columns: columns
    };

    const pageSizeOptions = ["10", "25", "50", "100", "500", "1000"];

    const handleRowClick = async (user: IUser) => {
        try {
            const record = dataSource.find(item => item.username === user.username);
            if (!record) {
                return;
            }
            updateUserState(record);
            setMode("view");
        } catch (error) {
            console.error("Error retrieving client details:", error);
        }
    };

    return (
        <>
            <Row
                justify={"space-between"}
                align="middle"
                gutter={[10, 5]}>
                <Col
                    xs={24}
                    md={{ span: "none" }}>
                    <UsersTable
                        table="users"
                        recordType="user"
                        size="small"
                        dataSource={dataSource}
                        columns={columns}
                        rowKey={item => item?.username}
                        rowClicked={handleRowClick}
                        loading={isLoading}
                        pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions }}
                        sticky
                        exportConfig={exportConfig}
                        exportList={true}
                        additionalButtons={[
                            <Button
                                id="button-add-user"
                                onClick={() => {
                                    updateUserState(new User(currentUser.accountId));
                                    setMode("create");
                                }}>
                                Add User
                            </Button>,
                            <Button
                                style={{ marginLeft: "20px" }}
                                onClick={() => {
                                    getUserList({});
                                }}
                                loading={userList.isFetching}>
                                Refresh List
                            </Button>
                        ]}></UsersTable>
                </Col>
            </Row>
            {mode !== "closed" ? (
                mode === "view" ? (
                    <UserDetails
                        mode={"edit"}
                        state={[userState as EditorRecordType, updateUserState]}
                        isLoading={clientList.isFetching || accountList.isFetching}
                        onAction={async (action, user) => {
                            switch (action) {
                                case "edit":
                                    setMode("edit");
                                    break;
                                case "enable":
                                    updateUserState(await updateUser({ ...user, state: "active" }).unwrap());
                                    break;
                                case "disable":
                                    updateUserState(await updateUser({ ...user, state: "disabled" }).unwrap());
                                    break;
                                case "addUser":
                                    updateUserState(new User(user.accountId));
                                    setMode("create");
                                    break;
                                case "delete":
                                    Modal.confirm({
                                        title: "Delete User",
                                        content: (
                                            <div style={{ maxWidth: 300 }}>
                                                Deleted users cannot be restored. Confirm you wish to proceed.
                                            </div>
                                        ),
                                        okButtonProps: {
                                            danger: true
                                        },
                                        onOk() {
                                            try {
                                                deleteUser(user);
                                                setMode("closed");
                                            } catch {}
                                        }
                                    });
                                    break;
                                case "cancel":
                                    setMode("closed");
                                    break;
                            }
                        }}></UserDetails>
                ) : (
                    <UserEditor
                        mode={mode}
                        state={[userState as EditorRecordType, updateUserState]}
                        isLoading={clientList.isFetching || accountList.isFetching}
                        onAction={(action, client) => {
                            if (action !== "cancel") {
                                if (action === "saveAndAddUser") {
                                    updateUserState(new User(userState!.accountId));
                                    setMode("create");
                                } else {
                                    setMode("closed");
                                }
                            } else {
                                setMode("closed");
                            }
                        }}
                    />
                )
            ) : null}
        </>
    );
};

export default UserManagement;
