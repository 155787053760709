import { FunctionComponent, useMemo } from "react";
import { Table } from "antd";
import { FieldTableSelect } from "./type";
import { Key, SelectionSelectFn } from "antd/lib/table/interface";
import { pretty } from "../../utils/inject";

interface OwnProps {
    // Add some types here
    field: FieldTableSelect;
    value?: Key[];
    onChange?: SelectionSelectFn<any>;
}

type Props = OwnProps;

const TableSelect: FunctionComponent<Props> = ({ field, value, onChange }) => {
    const columns = useMemo(() => {
        return Object.keys(field.dataSource[0] || {})
            .filter(e => e !== "key")
            .map(e => ({
                title: pretty(e),
                dataIndex: e,
                key: e
            }));
    }, [field.dataSource]);
    return (
        <Table
            rowSelection={{
                type: "radio",
                onSelect: onChange,
                selectedRowKeys: value
            }}
            dataSource={field.dataSource}
            columns={
                field.columns
                    ? field.columns.map(column => {
                          if (typeof column === "string")
                              return {
                                  title: pretty(column),
                                  dataIndex: column,
                                  key: column
                              };
                          else return column;
                      })
                    : columns
            }
            pagination={{ pageSize: 5, showSizeChanger: false }}
            {...field.tableProps}
        />
    );
};

export default TableSelect;
