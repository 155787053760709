import React, { CSSProperties, FunctionComponent, useState } from "react";
import { Radio, Space, Button, Tooltip } from "antd";
import { Modal, Typography } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import DropdownButton from "antd/es/dropdown/dropdown-button";
import versions from '../../../data/versions.json';
import { getStreamingInstance } from '../../../redux/slices/systemSlice'

interface OwnProps {
    borderRadius?: number;
    containerStyle?: CSSProperties;
    buttonStyle?: CSSProperties;
    hideSettings?: boolean;
}

type Props = OwnProps;

const SelectVersion: FunctionComponent<Props> = ({ borderRadius, containerStyle, buttonStyle, hideSettings }) => {
    const [selectedVersion, setSelectedVersion] = useState<"new" | "old">("new");
    const [loading, setLoading] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);

    const openSettings = () => setVisible(true);
    const openApp = async () => {
        setLoading(true);
        const params: any = {};
        if (selectedVersion === "old") params.useOld = true;
        const url = await getStreamingInstance(selectedVersion === "old");
        if (url) {
            window.open(url, "_blank");
        }            
        setLoading(false);
    };
    const onClose = () => setVisible(false);
    return (
        <>
            <DropdownButton 
                className="button-manage-leases"
                style={containerStyle}
                loading={loading}
                size={"large"}
                overlay={<></>}
                icon={<SettingOutlined />}
                buttonsRender={(buttons) =>
                    buttons.map((e: any, index: number) => {
                        if (index === 1 && hideSettings)
                            return <div />
                        const isSettings = index === 1;
                        let style = {};
                        const rightStyle = {
                            borderTopRightRadius: borderRadius || 4,
                            borderBottomRightRadius: borderRadius || 4,
                            borderLeftColor: "#F4496B",
                            backgroundColor: "#F4496B"
                        }
                        if (isSettings) {
                            style = rightStyle
                        } else {
                            style = {
                                borderTopLeftRadius: borderRadius || 4,
                                borderBottomLeftRadius: borderRadius || 4,
                                borderRightColor: "#F4496B",
                                backgroundColor: "#F4496B"
                            }
                        }
                        if (hideSettings) {
                            style = {
                                ...style,
                                ...rightStyle,
                                borderWidth: 0
                            }
                        }
                        const element = React.cloneElement(e, {
                            danger: true,
                            style: {
                                ...style,
                                height: selectedVersion !== "new" ? 50 : undefined,
                                ...(buttonStyle || {}),
                            },
                            onClick: isSettings ? openSettings : openApp,
                            type: "primary",
                        });
                        if (isSettings)
                            return (
                                <Tooltip
                                    placement="right"
                                    title="Choose which EZLease version to use"
                                >
                                    {element}
                                </Tooltip>
                            );
                        return element;
                    })
                }
            >
                <span style={{ display: "inline !important",fontSize: "15px", width: "97%",verticalAlign: "middle" }}>Manage my leases</span>
                {selectedVersion !== "new" && (
                    <div style={{fontStyle: "italic", fontSize: "10px"}}>
                        Previous Version ({versions.priorVersion})
                    </div>
                )}
            </DropdownButton>
            <Modal
                title="Select EZLease Version"
                open={visible}
                onOk={onClose}
                closable={false}
                footer={[
                    <Button id="button-okay" key="back" onClick={onClose}>
                        OK
                    </Button>,
                ]}
            >
                <Typography.Paragraph>
                    For a limited time after an update, you can choose to still use the
                    previous version of EZLease for the sake of comparing report outputs
                    between the two versions.
                </Typography.Paragraph>
                <Radio.Group
                    onChange={(e) => setSelectedVersion(e.target.value)}
                    value={selectedVersion}
                >
                    <Space direction="vertical">
                        <Radio value={"new"}>Current Version ({versions.version})</Radio>
                        <Radio value={"old"}>Previous Version ({versions.priorVersion})</Radio>
                    </Space>
                </Radio.Group>
            </Modal>
        </>
    );
};

export default SelectVersion;
