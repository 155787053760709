import { Col, Form as AntdForm, FormItemProps, FormProps, Row, Typography } from "antd";
import { FormLayout } from "antd/lib/form/Form";
import { FunctionComponent } from "react";
import { FieldTypes, IBaseField, IRuleMessage } from "./FieldTypes/base";
import { Button, IButtonProps, IFieldButton } from "./FieldTypes/Button";
import { Checkbox, ICheckboxProps, IFieldCheckbox } from "./FieldTypes/Checkbox";
import { CheckGroup, ICheckGroupProps, IFieldCheckboxGroup } from "./FieldTypes/CheckGroup";
import { IFieldLabel, ILabelProps, Label } from "./FieldTypes/Label";
import { IDateProps, Date, IFieldDate } from "./FieldTypes/Date";
import { IHiddenProps, Hidden, IFieldHidden } from "./FieldTypes/Hidden";
import { IFieldInputNumber, INumberProps, Number as NumberInput } from "./FieldTypes/Number";
import { IFieldInputPassword, IPasswordProps, Password } from "./FieldTypes/Password";
import { IFieldRadioGroup, IRadioGroupProps, RadioGroup } from "./FieldTypes/RadioGroup";
import { IFieldSelect, ISelectProps, Select } from "./FieldTypes/Select";
import { IFieldSwitch, ISwitchProps, Switch } from "./FieldTypes/Switch";
import { IFieldTableSelect, ITableSelectProps, TableSelect } from "./FieldTypes/TableSelect";
import { IFieldInputText, ITextProps, Text } from "./FieldTypes/Text";
import { IFieldSection, ISectionProps, Section } from "./FieldTypes/Section";
import moment from "moment";
import RoleAssignmentGrid, {
    IRoleAssignmentGridProps,
    IFieldRoleAssignmentGrid
} from "./FieldTypes/RoleAssignmentGrid";
import * as EmailValidator from "email-validator";
import { HorizontalRule, IHRProps } from "./FieldTypes/HorizontalRule";
import { JSX, IJSXProps } from "./FieldTypes/JSX";

export type IField =
    | IFieldInputText
    | IFieldInputNumber
    | IFieldRadioGroup
    | IFieldCheckboxGroup
    | IFieldInputPassword
    | IFieldDate
    | IFieldHidden
    | IFieldLabel
    | IFieldTableSelect
    | IFieldSelect
    | IFieldButton
    | IFieldCheckbox
    | IFieldSwitch
    | IFieldRoleAssignmentGrid
    | IFieldSection;

export type IFieldProps =
    | IButtonProps
    | ITextProps
    | ICheckboxProps
    | ICheckGroupProps
    | IDateProps
    | IHiddenProps
    | ILabelProps
    | INumberProps
    | IPasswordProps
    | IRadioGroupProps
    | ISelectProps
    | ISwitchProps
    | ITableSelectProps
    | ITextProps
    | IRoleAssignmentGridProps
    | ISectionProps;

interface IFormProps {
    fields: IField[];
    state: [any, (value: any) => void];
    errors?: { [key: string]: string[] };
    layout?: FormLayout;
    formProps?: FormProps;
    section?: boolean;
    onSubmit?: (e: any) => void | Promise<void>;
}

export const Form: FunctionComponent<IFormProps> & {
    validate: (data: any, fields: IBaseField[]) => { [key: string]: string[] };
} = props => {
    const { fields, state, errors, layout = "vertical", formProps = {}, onSubmit, section } = props;
    const [formData, onChange] = state;

    const calculatePadding = (label: any) => {
        const basePadding = 5;
        const maxLength = 20;
        const labelLength = label ? label.length : 0;

        if (labelLength <= maxLength) {
            return basePadding;
        } else {
            return basePadding - (labelLength - maxLength) * 0.5;
        }
    };

    const renderField = {
        [FieldTypes.Button]: (props: IButtonProps) => <Button {...props} />,
        [FieldTypes.Checkbox]: (props: ICheckboxProps) => <Checkbox {...props} />,
        [FieldTypes.CheckboxGroup]: (props: ICheckGroupProps) => <CheckGroup {...props} />,
        [FieldTypes.Date]: (props: IDateProps) => <Date {...props} />,
        [FieldTypes.Hidden]: (props: IHiddenProps) => <Hidden {...props} />,
        [FieldTypes.InputNumber]: (props: INumberProps) => <NumberInput {...props} />,
        [FieldTypes.InputPassword]: (props: IPasswordProps) => <Password {...props} />,
        [FieldTypes.InputText]: (props: ITextProps) => <Text {...props} />,
        [FieldTypes.Label]: (props: ILabelProps) => <Label {...props} />,
        [FieldTypes.RadioGroup]: (props: IRadioGroupProps) => <RadioGroup {...props} />,
        [FieldTypes.Select]: (props: ISelectProps) => <Select {...props} />,
        [FieldTypes.Switch]: (props: ISwitchProps) => <Switch {...props} />,
        [FieldTypes.TableSelect]: (props: ITableSelectProps) => <TableSelect {...props} />,
        [FieldTypes.RoleAssignmentGrid]: (props: IRoleAssignmentGridProps) => <RoleAssignmentGrid {...props} />,
        [FieldTypes.Section]: (props: ISectionProps) => (
            <Section
                {...props}
                renderField={renderField}
                errors={errors}
            />
        ),
        [FieldTypes.HorizontalRule]: (props: IHRProps) => <HorizontalRule {...props} />,
        [FieldTypes.JSX]: (props: IJSXProps) => <JSX {...props} />
    };

    const renderForm: () => JSX.Element = () => {
        return (
            <>
                {fields.map((field, _index) => {
                    const fieldProps = { field, value: formData, onChange };
                    const fieldErrors = (errors || {})[field.fieldKey] || [];
                    const noLabel = field.type === FieldTypes.Checkbox || !field.label;
                    const containerPadding = calculatePadding(field.label);
                    let errorString = fieldErrors.join(" ").replace(/,\.\s/g, ". ").trim();
                    errorString = errorString
                        ? errorString.endsWith(".")
                            ? errorString
                            : errorString + "."
                        : errorString;
                    const itemProps: FormItemProps = {
                        className:(fieldErrors.length > 0 ?"invalid":""),
                        style: field.containerStyle,
                        validateStatus: (fieldErrors.length > 0 ? "error" : undefined) as "error" | undefined,
                        help: errorString ? errorString : false,
                        ...field.formItemProps
                    };

                    return (
                        <Row key={field.fieldKey}>
                            {!noLabel  && field.type !== FieldTypes.RoleAssignmentGrid &&(
                                <Col span={6}>
                                    <div style={{ paddingLeft: containerPadding }}>
                                        <strong>{field.label}</strong>{" "}
                                        {field.validationProps?.required ? (
                                            <small style={{ marginRight: 3 }}>
                                                <Typography.Text type={"danger"}>*</Typography.Text>
                                            </small>
                                        ) : null}
                                        :
                                    </div>
                                </Col>
                            )}
                            {field.type !== FieldTypes.RoleAssignmentGrid && field.type !== FieldTypes.Section && (
                                <Col span={noLabel ? 24 : 18}>
                                    <AntdForm.Item
                                        {...itemProps}
                                        style={{
                                            paddingRight: calculatePadding(field.label)
                                        }}>
                                        {renderField[field.type](fieldProps as any)}
                                    </AntdForm.Item>
                                </Col>
                            )}
                            {field.type === FieldTypes.Section && (
                                <Col span={24}>
                                    {renderField[field.type](fieldProps as any)}
                                </Col>
                            )}
                            {field.type === FieldTypes.RoleAssignmentGrid && (
                                <Col span={24}>
                                    {renderField[field.type](field as IFieldRoleAssignmentGrid)}
                                </Col>
                            )}
                        </Row>
                    );
                })}
            </>
        );
    };

    return section === true ? (
        renderForm()
    ) : (
        <AntdForm
            layout={layout}
            onSubmitCapture={onSubmit}            
            {...formProps}>
            {renderForm()}            
        </AntdForm>
    );
};

Form.validate = (data: any, fields: IField[]) => {
    const formatError = (ruleMessage: IRuleMessage, fields: IField[], data: any) => {
        if (!ruleMessage?.keys?.length) {
            return ruleMessage?.format;
        }
        let message = ruleMessage.format;
        ruleMessage.keys.forEach((key, i) => {
            message = message.replaceAll(`{l${i}}`, key.label);
            message = message.replaceAll(`{v${i}}`, data[key.key]);
        });

        return message;
    };

    const errors: { [key: string]: string[] } = {};

    const addError = (field: IField, message: IRuleMessage) => {
        if (!errors[field.fieldKey]) {
            errors[field.fieldKey] = [];
        }
        errors[field.fieldKey].push(formatError(message, fields, data));
    };

    const validateField = (field: IField) => {
        if (!field.validationProps) {
            return;
        }
        const rules = field.validationProps as any;
        Object.keys(rules).forEach(ruleKey => {
            const rule = rules[ruleKey];
            switch (ruleKey) {
                case "required":
                case "turnOn":
                    if (!data[field.fieldKey]) {
                        addError(field, rules.required);
                    }
                    break;
                case "min":
                    const min = rule.value as number;
                    if (data[field.fieldKey] < min) {
                        addError(field, rule.message);
                    }
                    break;
                case "max":
                    const max = rule.value as number;
                    if (data[field.fieldKey] > max) {
                        addError(field, rule.message);
                    }
                    break;
                case "integer":
                    const num = Number(data[field.fieldKey]);
                    if (Number.isNaN(num) || !Number.isInteger(num)) {
                        addError(field, rule.message);
                    }
                    break;
                case "noOnlySpaces":
                    const value = data[field.fieldKey];
                    if (typeof value === "string" && value.trim() === "") {
                        addError(field, rules.noOnlySpaces);
                    }
                    break;
                case "noLeadingSpaces":
                    const nlsValue = data[field.fieldKey];
                    if (typeof nlsValue === "string" && nlsValue.length > 0 && nlsValue.trimStart() !== nlsValue) {
                        addError(field, rules.noLeadingSpaces);
                    }
                    break;
                case "noTrailingSpaces":
                    const ntsValue = data[field.fieldKey];
                    if (typeof ntsValue === "string" && ntsValue.length > 0 && ntsValue.trimEnd() !== ntsValue) {
                        addError(field, rules.noTrailingSpaces);
                    }
                    break;
                case "noPast":
                    const before = moment(data[field.fieldKey]).isBefore(moment(), "day");
                    if (before) {
                        addError(field, rule.message);
                    }
                    break;
                case "noFuture":
                    const after = moment(data[field.fieldKey]).isAfter(moment(), "day");
                    if (after) {
                        addError(field, rule.message);
                    }
                    break;
                case "minLength":
                    const minLength = rule.value as number;
                    if (data[field.fieldKey]?.length < minLength) {
                        addError(field, rule.message);
                    }
                    break;
                case "maxLength":
                    const maxLength = rule.value as number;
                    if (data[field.fieldKey]?.length > maxLength) {
                        addError(field, rule.message);
                    }
                    break;
                case "regex":
                    const test = rule.test as RegExp;
                    if (!test.test(data[field.fieldKey])) {
                        addError(field, rule.message);
                    }
                    break;
                case "isEmail":
                    const email = data[field.fieldKey];
                    if (!EmailValidator.validate(email)) {
                        addError(field, rule.message);
                    }
                    break;
            }
        });
    };

    fields.forEach(field => {
        if (field.type === FieldTypes.Section && (field as any).fields) {
            (field as any).fields.forEach((subField: IField) => {
                validateField(subField);
            });
        } else {
            validateField(field);
        }
    });
    return errors;
};

export default Form;
