import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ClientState {
  clientId: string | null;
}

const initialState: ClientState = {
  clientId: null
};

export const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setClient: (state, action: PayloadAction<{ clientId: string }>) => {
      state.clientId = action.payload.clientId;
    },
    resetClient: (state) => {
      state.clientId = null;
    }
  },
});

export const { setClient,resetClient } = clientSlice.actions;
export default clientSlice.reducer;
