import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store";
import { FC, createContext, useEffect, useState } from "react";
import { userApi } from "../redux/actions/userApi";
import { resetClient } from "../redux/slices/clientSlice";
import { setError, getUserDetailsAsync, clearCurrentUser } from "../redux/slices/systemSlice";
import { SRP } from "./SRP";
import { Auth, Hub } from "aws-amplify";
import { AmplifyUser } from "@aws-amplify/ui";

declare global {
    namespace JSX {
        interface IntrinsicElements {
            "amplify-toast": { children: Element; class: string; id: string };
        }
    }
}

interface Props {
    useFed?:string|false;
    children?:any;
}

export const AuthContext = createContext<{amplifyUser?:AmplifyUser}>({ amplifyUser:undefined });

export const AuthContainer: FC<Props> = ({useFed, children}) => {
    const useSRP = !useFed;
    const dispatch = useDispatch<AppDispatch>();
    const user = useSelector((state: RootState) => state.system.currentUser);
    const [amplifyUser, setAmplifyUser] = useState<AmplifyUser|undefined>();

    useEffect(() => {    
        const listener = async (data:any) => {
            console.log('Hub', JSON.stringify(data, null, 2));
            switch (data.payload.event) {
                case 'configured':
                    try {
                        console.log('Auth: Configured, checking for existing session...');
                        await Auth.currentSession();
                        console.log('Auth: Got session, trigger signed in');
                        Hub.dispatch('auth', { event: "signIn" })
                    } catch(err:unknown) {
                        console.log('Auth: Session does not exist', err as Error);
                        // federation does not use amplify ui
                        if (useFed) {
                            // check for previous federation error
                            let params = new URLSearchParams(document.location.search);
                            let pError = params.get("error");
                            if (pError === "invalid_request") {
                                dispatch(setError({ error: "User does not have access to this application." }));
                            } else {
                                console.log('Auth: Federated signin', window.location.href);
                                try {
                                    await Auth.federatedSignIn({ customProvider: useFed });                                
                                } catch (err: unknown) {
                                    console.log('Auth: Federation error', err);
                                    dispatch(setError({ error: "User does not have access to this application." }));
                                }
                            }
                        }
                    }
                    break;
                case 'signIn':
                    try {
                        setAmplifyUser(await Auth.currentAuthenticatedUser());
                        await dispatch(getUserDetailsAsync());
                        console.log('Auth: Signed In');
                    } catch (error:unknown) {
                        console.log('Auth: Signin Error', error as Error);
                    }
                    break;

                case "signOut":
                    setAmplifyUser(undefined);
                    dispatch(clearCurrentUser());
                    dispatch(resetClient());
                    dispatch(userApi.util.resetApiState());
                    console.log('Auth: Signed out');
                    Hub.dispatch('auth', {event: "configured"});
                    break;
            }    
        } 
        Hub.listen('auth', listener , 'authcontainer_listener');

        return () => Hub.remove('auth',listener);
    }, [dispatch, useFed]);

    useEffect(() => Hub.dispatch('auth', {event: 'configured'}), []);

    console.log('Render Body', {user, useSRP, useFed})
    function renderBody() {
        if (user) {
            return <>{children}</>
        } else if (useSRP) {
            console.log('Body: SRP form');
            return <SRP />
        } else if (useFed) {
            console.log('Body: NO body for Fed');
            return null;
        }
        console.log('Body: none of the above?');
        return null;
    }
    return <AuthContext.Provider value={{amplifyUser}}>{renderBody()}</AuthContext.Provider>
};

