import { ColProps, FormItemProps, Modal } from "antd";
import React, { CSSProperties, ReactElement } from "react";

export enum FieldTypes {
    RadioGroup = "radioGroup",
    CheckboxGroup = "checkboxGroup",
    InputText = "inputText",
    InputNumber = "inputNumber",
    InputPassword = "inputPassword",
    Date = "date",
    Hidden = "hidden",
    TableSelect = "tableSelect",
    Label = "label",
    Select = "select",
    Button = "button",
    Checkbox = "checkbox",
    Switch = "switch",
    RoleAssignmentGrid = "clientuserroles",
    Section = "section",
    HorizontalRule = "horizontalRule",
    JSX = "jsx"
}

export interface IBaseField {
    type: FieldTypes;
    fieldKey: string;
    label?: string|ReactElement;
    colProps?: ColProps;
    style?: CSSProperties;
    containerStyle?: CSSProperties;
    disabled?: boolean;
    className?: string;
    formItemProps?: FormItemProps;
    validationProps?: IBaseValidationProps;
    isNewLine?: boolean;
}

export interface IBaseFieldProps {}

export interface IRuleMessage {
    format: string;
    keys?: { key: string; label: string }[];
}

export interface IBaseValidationProps {
    required?: IRuleMessage;
}

export interface IConfirmProps {
    prompt: string | React.ReactElement |  ((current:any, draft:any) => string) | ((current:any, draft:any) => React.ReactElement);
    confirmLabel: string;
    cancelLabel: string;
    onConfirm: (current: any, draft: any) => any;
    onCancel: (current: any, draft: any) => any;
}

const getConfirmPrompt = (current:any, draft:any, confirmOpts:IConfirmProps) => {
    if (typeof confirmOpts.prompt === 'function') {
        return confirmOpts.prompt(current, draft);
    }
    return confirmOpts.prompt;
}

export const getModalConfirmFunc = (confirmOpts:IConfirmProps) => (current:any, draft: any) => new Promise<boolean>((resolve, _reject) => {
    Modal.destroyAll();
    Modal.confirm({
        content: getConfirmPrompt(current, draft, confirmOpts),
        okText: confirmOpts.confirmLabel,
        cancelText: confirmOpts.cancelLabel,
        onOk: async () => { 
            resolve(confirmOpts.onConfirm(current, draft));
        },
        onCancel: () => {
            resolve(false);   
        }
    });
});