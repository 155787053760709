import { FunctionComponent } from "react";
import { useParams } from "react-router-dom";
import AuditTrail from "./AuditTrail";
import CurrentRent from "./CurrentRent";

const DetailsScreen: FunctionComponent = () => {
  const { type } = useParams();

  if (type === "auditTrail") return <AuditTrail />;
  if (type === "currentRent") return <CurrentRent />;
  return (
    <div>
      
    </div>
  );
};

export default DetailsScreen;
