import { InputProps, Tooltip } from "antd";
import Input, { SearchProps } from "antd/lib/input";
import { FunctionComponent } from "react";
import { IBaseField, FieldTypes, IBaseValidationProps, IRuleMessage, IBaseFieldProps } from "./base";

export interface IFieldInputText extends IBaseField {
    inputProps?: InputProps | SearchProps;
    mode?: "search";
    validationProps?: ITextValidationProps;
    toolTip?: string;
    readOnly?:boolean
}

export interface ITextValidationProps extends IBaseValidationProps {
    minLength?: { value: number; message: IRuleMessage };
    maxLength?: { value: number; message: IRuleMessage };
    regex?: { test: RegExp; message: IRuleMessage };
    noOnlySpaces?: IRuleMessage;
    noLeadingSpaces?: IRuleMessage;
    noTrailingSpaces?: IRuleMessage;
    isEmail?: { message: IRuleMessage };
}

export const TextField = (
    fieldKey: string,
    label: string,
    inputProps?: InputProps | SearchProps,
    mode?: "search",
    validationProps?: ITextValidationProps,
    toolTip?:string,
    readOnly?:boolean
) => ({ fieldKey, label, type: FieldTypes.InputText, inputProps, mode, validationProps, toolTip, readOnly });

export interface ITextProps extends IBaseFieldProps {
    field: IFieldInputText;
    value: any;
    onChange: (input: any) => void;
}

export const Text: FunctionComponent<ITextProps> = ({ field, value, onChange }) => {
    const input = !field.readOnly ? field.mode === "search" ? (
            <Input.Search
                id={`search-${field.fieldKey}`}
                value={value[field.fieldKey]}
                onChange={text => onChange((current: any) => ({ ...current, [field.fieldKey]: text.target.value }))}
                {...(field.inputProps || {})}
            />
        ) : (
        <Input
            id={`input-${field.fieldKey}`}
            value={value[field.fieldKey]}
            onChange={text => onChange((current: any) => ({ ...current, [field.fieldKey]: text.target.value }))}
            {...(field.inputProps || {})}
        />
    ) :  <span className="ant-form-text">{value[field.fieldKey]}</span>

    return field.toolTip ? <Tooltip trigger={['hover']} title={field.toolTip} placement="topLeft">{input}</Tooltip> : input;
};
