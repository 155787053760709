import { FunctionComponent } from "react";
import {
    FieldTypes,
    IBaseField,
    IBaseFieldProps,
    IBaseValidationProps,
    IConfirmProps,
    IRuleMessage,
    getModalConfirmFunc
} from "./base";
import { SwitchProps, Switch as AntdSwitch, Tooltip } from "antd";

export interface ISwitchValidationProps extends IBaseValidationProps {
    turnOn?: IRuleMessage;
}

export interface ISwitchProps extends IBaseFieldProps {
    field: IFieldSwitch;
    value: any;
    onChange: (input: any) => void;
}

export interface IFieldSwitch extends IBaseField {
    type: FieldTypes.Switch;
    validationProps?: ISwitchValidationProps;
    switchProps?: SwitchProps;
    tooltip?: string;
    disabled?: boolean;
    onValue?:string;
    offValue?:string;
}

export const Switch: FunctionComponent<ISwitchProps> = ({ field, value, onChange }) => {
    const onValue = field.onValue || true;
    const offValue = field.offValue || false;

    const handleChange = async (newValue: boolean) => {
        const draft = { ...value, [field.fieldKey]: newValue ? onValue : offValue };

        if ("confirmOpts" in field) {
            const opts = field.confirmOpts as IConfirmProps & { direction: "on" | "off" | "both" };
            if (
                (newValue && (opts.direction === "on" || opts.direction === "both")) ||
                (!newValue && (opts.direction === "off" || opts.direction === "both"))
            ) {
                const changes = await (field.confirmOpts as any).onConfirm(value, draft);
                if (changes) {
                    const updatedValue = { ...value, ...changes };
                    onChange(updatedValue);
                    if ("onChange" in field) {
                        (field.onChange as (data: any) => void)(updatedValue);
                    }
                }
            }
        } else {
            onChange(draft);
            if ("onChange" in field) {
                (field.onChange as (data: any) => void)(draft);
            }
        }
    };

    const switchComponent = (
        <AntdSwitch
            id={`switch-${field.fieldKey}`}
            disabled={field.disabled}
            checked={value[field.fieldKey] === onValue}
            onChange={handleChange}
            {...(field.switchProps || {}) as SwitchProps}
        />
    );

    return field.tooltip ? (
        <Tooltip title={field.tooltip}>{switchComponent}</Tooltip>
    ) : (
        switchComponent
    );
};

export const SwitchField = (
    fieldKey: string,
    label: string,
    switchProps?: SwitchProps,
    validationProps?: ISwitchValidationProps
) => ({
    fieldKey,
    label,
    type: FieldTypes.Switch,
    switchProps,
    validationProps
});

export const ConfirmSwitchField = (
    fieldKey: string,
    label: string,
    confirmOpts: IConfirmProps & { direction: "on" | "off" | "both" },
    switchProps?: SwitchProps,
    validationProps?: ISwitchValidationProps,
    onValue?:string,
    offValue?:string,
    disabled?:boolean,
    tooltip?:string
) => ({
    fieldKey,
    label,
    type: FieldTypes.Switch,
    switchProps,
    validationProps,
    onValue,
    offValue,
    confirmOpts: {...confirmOpts, onConfirm: getModalConfirmFunc(confirmOpts)},
    disabled,
    tooltip
});
