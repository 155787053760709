import { FunctionComponent } from "react";
import { Radio } from "antd";
import { FieldRadioGroup as FieldRadioGroupHandler, OptionType } from "./type";
import { StateHandler } from "./index";
import { pretty } from "../../utils/inject";

interface OwnProps {
  id: string;
  options: OptionType[];
  state: StateHandler;
  field: FieldRadioGroupHandler;
}

type Props = OwnProps;

const FieldRadioGroup: FunctionComponent<Props> = ({
  id,
  field,
  options,
  state,
}) => {
  const [data, setData] = state;
  return (
    <Radio.Group id={id}
      onChange={(e) =>
        setData((val: any) => ({ ...val, [field.key]: e.target.value }))
      }
      value={data[field.key]}
    >
      {options.map((option) => (
        <Radio value={option.value} key={option.value}>
          {option.label || pretty(option.value)}
        </Radio>
      ))}
    </Radio.Group>
  );
};

export default FieldRadioGroup;
