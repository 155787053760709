import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../utils/axiosInstance";
import { IUser } from "../../shapes/multiclient";
import { addPin, removePin } from "../slices/systemSlice";

export const userApi = createApi({
    reducerPath: "users",
    baseQuery: axiosBaseQuery(),
    tagTypes: ['user'],
    endpoints: builder => ({
        // get all users (optional account filter)
        getUsers: builder.query({
            query: ({ accountId }) => ({
                url: accountId ? `/client/users/${accountId}` : "/client/users",
                method: "GET"
            })
        }),
        // get the user details for a particular user
        getUser: builder.query({
            query: ({ username }) => ({
                url: `/client/user/${username}`,
                method: "GET"
            }),
            async onQueryStarted(params, { dispatch, queryFulfilled }) {
                try {
                    const user = await queryFulfilled;
                    // update the all cached users
                    dispatch(userApi.util.updateQueryData("getUsers", {}, (users: IUser[]) => users.map(u => u.username === user.data.username ? user.data : u)));
                } catch (err: any) {
                    dispatch(userApi.util.invalidateTags([{ type: "user", id: params.username }]));
                    console.log("userApi.onQueryStarted", err);
                }
            },
            providesTags: (result, error) => error ? [] : [{type: 'user', id: result.username }]
        }),
        // add a new user to this account
        createUser: builder.mutation({
            query: user => ({
                url: `/client/user`,
                method: "PUT",
                data:user
            }),
            async onQueryStarted(_args, { dispatch, queryFulfilled }) {
                try {
                    const user = await queryFulfilled;
                    // inject created user
                    await dispatch(userApi.util.upsertQueryData("getUser", { username: user.data.username }, user));
                    // inject into all users
                    dispatch(userApi.util.updateQueryData("getUsers", {}, (users: IUser[]) => [...users, user.data]));
                    dispatch(addPin({table:"users", value:user.data.username}));
                } catch (err: any) {
                    console.log("userApi.onQueryStarted", err);
                }
            }
        }),
        // update a user
        // note if the accountId in user is different to existing accountId, then this constitutes moving a user between accounts
        updateUser: builder.mutation({
            query: user => ({
                url: `/client/user/`,
                method: "POST",
                data:user
            }),
            async onQueryStarted(_args, { dispatch, queryFulfilled }) {
                try {
                    const user = await queryFulfilled;
                    // update the cached user
                    await dispatch(userApi.util.upsertQueryData("getUser", { username: user.data.username }, user.data));
                    // update the all cached users
                    dispatch(userApi.util.updateQueryData("getUsers", {}, (users: IUser[]) => users.map(u => u.username === user.data.username ? user.data : u)));
                    dispatch(addPin({table:"users", value:user.data.username}));
                } catch (err: any) {
                    console.log("userApi.onQueryStarted", err);
                }
            }
        }),
        // delete a user
        deleteUser: builder.mutation({
            query: user => ({
                url: `/client/user/`,
                method: "DELETE",
                data:user
            }),
            async onQueryStarted(user, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    // remove the cached user
                    dispatch(userApi.util.updateQueryData("getUsers", { }, draft => draft.filter((a:IUser) => a.username !== user.username)));
                    dispatch(removePin({table:"users", value:user.username}));
                } catch (err: any) {
                    console.log("userApi.onQueryStarted", err);
                }
            },
            invalidatesTags: (_result, error, params) => error ? [] : [{type: 'user', id: params.username }]
        }),
        // validate a username as unique and valid
        validateUsername: builder.query({
            query: ({ username }) => ({
                url: `/client/validate`,
                method: "POST",
                username: username
            })
        }),
        resendInvite: builder.mutation({
            query: ({ username }) => ({
                url: `/client/user/${username}/resend-invite`
            })
        }),
        resetMFA: builder.mutation({
            query: ({ username }) => ({
                url: `/client/user/${username}/reset-mfa`
            }),
            async onQueryStarted({}, { dispatch, queryFulfilled }) {
                try {
                    const user = await queryFulfilled;
                    // update the cached user
                    await dispatch(userApi.util.upsertQueryData("getUser", { username: user.data.username }, user.data));
                    // update the all cached users
                    dispatch(userApi.util.updateQueryData("getUsers", {}, (users: IUser[]) => users.map(u => u.username === user.data.username ? user.data : u)));
                    //dispatch(addPin({table:"users", value:user.data.username}));
                } catch (err: any) {
                    console.log("userApi.onQueryStarted", err);
                }
            }
        })
    })
});

export const {
    useLazyGetUsersQuery,
    useLazyGetUserQuery,
    useCreateUserMutation,
    useUpdateUserMutation,
    useDeleteUserMutation,
    useLazyValidateUsernameQuery,
    useResendInviteMutation,
    useResetMFAMutation,
    reducer
} = userApi;
