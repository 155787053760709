import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Card, Form, Select, Table, Typography,Empty } from "antd";
import { useLazyGetNotificationQuery } from "../../../redux/actions/dashboard";
import { pretty } from '../../../utils/inject';

interface NotificationsProps {
    clientId?: string | null;
    scope?: string;
    notificationTypes:{id:string, name:string}[]
}

const { Title } = Typography;

const columns = [
    {
        title: 'Lease',
        dataIndex: 'Lease',
        key: 'Lease',
        ellipsis:true,
        width: 160
    },
    {
        title: 'Notice',
        dataIndex: 'Notice',
        key: 'Notice',
        width: 400
    },
    {
        title: 'Display dates',
        dataIndex: 'Display',
        key: 'Display',
        width: 200
    },
];

const Notifications: FunctionComponent<NotificationsProps> = ({ clientId, scope, notificationTypes }) => {
    const [fetchNotification, notification] = useLazyGetNotificationQuery()
    const [notificationType, setNotificationType] = useState<string>()

    const [currentPageValue, setCurrentPageValue] = useState<number>(1)

    useEffect(() => {
        if (clientId && scope && notificationType)
        {
            setCurrentPageValue(1) 
            fetchNotification({ clientId, scope, notificationType })
        }
    }, [clientId, scope, notificationType, fetchNotification])

    const dataSource = useMemo(() => {
        return notification?.data?.notices || []
    }, [notification?.data?.notices]);

    const loading = useMemo(() => notificationTypes === undefined || notificationTypes.length < 1, [notificationTypes]);
    const options = useMemo(() =>
        notificationTypes === undefined
            ? []
            : notificationTypes.map(t => ({ key: t.id, value: t.id, label: pretty(t.name) }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        [notificationTypes]
    );
    
    useEffect(() => {
        if (notificationTypes.length > 0) {
            setNotificationType(notificationTypes[0].id)
        }
    }, [notificationTypes]);

    const hasData = dataSource.length > 0;
    const fetching = notification.isLoading || notification.isFetching || notification.status === 'pending';

    return (
        <Card bodyStyle={{ paddingTop: 6 }}>
            <Title level={5}>
                Notifications
            </Title>
            <Form layout={"horizontal"}>
                <Form.Item label="View">
                    <Select id="select-notification-type"
                        value={notificationType}
                        style={{ width: 150 }}
                        onChange={e => setNotificationType(e)}
                        loading={loading}
                        options={options}>
                    </Select>
                </Form.Item> 
            </Form>
            {(!clientId || !scope || !notificationType) ? (
                <Empty description="Please select a client, a scope and a notification type." />
            ) : !fetching && !hasData ? (
                <Empty description="No notifications found for the selected parameters." />
            ) : (
                <Table
                    dataSource={dataSource} columns={columns} bordered size={"small"}  
                    pagination={{ defaultPageSize: 10, showSizeChanger: false,current: currentPageValue,onChange: (page) => setCurrentPageValue(page),total: notification?.data?.totalrecords-1} }
                    footer={(notification?.data?.totalrecords || 0) > 50 ? () => (
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                            <span>More than 50 notices available. Run the Notice Dates report to see all notices.</span>
                        </div>
                    ) : undefined}
                    loading={fetching}
                    scroll={{ x: 'max-content' }}
                />
            )}
        </Card>
    );
};

export default Notifications;
