import { SelectProps, Select as AntdSelect } from "antd";
import { FunctionComponent, useMemo } from "react";
import {
    IBaseField,
    FieldTypes,
    IBaseFieldProps,
    IBaseValidationProps,
    IConfirmProps,
    getModalConfirmFunc
} from "./base";

export interface SelectOption {
    label: string;
    value: string;
}

export interface IFieldSelect extends IBaseField {
    type: FieldTypes.Select;
    options?: (SelectOption | string)[];
    dynamicOptions?: {
        data: { isFetching: boolean; isSuccess: boolean; data: any[] };
        mappingFn: (item: any) => SelectOption;
        emptyDataMessage: string;
    };
    selectProps: SelectProps;
    fieldProps?: SelectProps;
    validationProps: IBaseValidationProps;
    onChange?: (input: any) => void;
    onConfirm?: (value: any) => Promise<boolean>;
}

export interface ISelectProps extends IBaseFieldProps {
    field: IFieldSelect;
    value: any;
    onChange: (input: any) => void;
}
export const Select: FunctionComponent<ISelectProps> = ({ field, value, onChange }) => {
    const options: SelectOption[] = useMemo(() => {
        if (field.options) {
            if (field.options.length > 0) {
                return typeof field.options[0] === "string"
                    ? field.options.map(o => ({ value: o as string, label: (o as string).pretty() } as SelectOption))
                    : (field.options as SelectOption[]);
            }
        }

        if (field.dynamicOptions) {
            if (field.dynamicOptions.data.isFetching) {
                return [{ value: "", label: "Loading..." }];
            }
            if (field.dynamicOptions.data.isSuccess) {
                if (field.dynamicOptions.data.data.length < 1) {
                    return [{ value: "", label: field.dynamicOptions.emptyDataMessage || "No options available" }];
                }
                const options = field.dynamicOptions.data.data.map(field.dynamicOptions?.mappingFn);
                return options;
            }
            return [{ value: "", label: "Unable to load options" }];
        }
        return [{ value: "", label: "No options available" }];
    }, [field.options, field.dynamicOptions]);

    const handleChange = async (newValue: any) => {
        if (!field.dynamicOptions || field.dynamicOptions?.data.isSuccess === true) {
            const draft = { ...value, [field.fieldKey]: newValue };
            if ("confirmOpts" in field) {
                const changes = await (field.confirmOpts as any).onConfirm(value, draft);
                if (changes) {
                    const updatedValue = { ...draft, ...changes };
                    onChange(updatedValue);
                    if (field.onChange) {
                        field.onChange(updatedValue);
                    }
                }
            } else {
                onChange(draft);
                if (field.onChange) {
                    field.onChange(draft);
                }
            }
        }
    };

    const filterOption = (inputValue: string, option: any) => {
        const inputValueRegex = new RegExp(`\\b${inputValue.toLowerCase()}`);
        return (
            option.label.toLowerCase().match(inputValueRegex) !== null ||
            option.value.toLowerCase().match(inputValueRegex) !== null
        );
    };

    return field.disabled && options[0]?.label === field.dynamicOptions?.emptyDataMessage ? (
        <p>{field.dynamicOptions?.emptyDataMessage}</p>
    ) : (
        <AntdSelect
            id={`select-${field.fieldKey}`}
            disabled={field.disabled}
            className={field.className}
            style={{ width: "100%", ...(field.style || {}) }}
            value={value[field.fieldKey]}
            loading={field.dynamicOptions?.data?.isFetching}
            onChange={handleChange}
            options={options}
            showSearch={true} // prop to enable searching
            filterOption={filterOption} // prop to match search results according to input
            {...(field.fieldProps || {})}></AntdSelect>
    );
};

export const SelectField = (
    fieldKey: string,
    label: string,
    options: (SelectOption | string)[],
    selectProps?: SelectProps,
    validationProps?: IBaseValidationProps,
    disabled?: boolean
) => ({ fieldKey, label, options, type: FieldTypes.Select, selectProps, validationProps, disabled });

export const DynamicSelectField = (
    fieldKey: string,
    label: string,
    options: { data: any; mappingFn: (item: any) => SelectOption; emptyDataMessage?: string },
    selectProps?: SelectProps,
    validationProps?: IBaseValidationProps,
    disabled?: boolean,
    onChange?: (input: any) => void
) => ({
    fieldKey,
    label,
    dynamicOptions: options,
    type: FieldTypes.Select,
    selectProps,
    validationProps,
    disabled,
    onChange
});

export const DynamicSelectCallbackField = (
    fieldKey: string,
    label: string,
    dynamicOptions: { data: any; mappingFn: (item: any) => SelectOption; emptyDataMessage?: string },
    selectProps?: SelectProps,
    validationProps?: IBaseValidationProps,
    onChange?: (input: any) => void,
    disabled?: boolean,
    onConfirm?: (value: any) => Promise<boolean>
) => ({
    fieldKey,
    label,
    dynamicOptions,
    type: FieldTypes.Select,
    selectProps,
    validationProps,
    onChange,
    disabled,
    onConfirm
});

export const ConfirmOnChangeSelectfield = (
    fieldKey: string,
    label: string,
    dynamicOptions: { data: any; mappingFn: (item: any) => SelectOption; emptyDataMessage?: string },
    confirmOpts: IConfirmProps,
    selectProps?: SelectProps,
    validationProps?: IBaseValidationProps,
    disabled?: boolean,
    onChange?: (input: any) => void
) => ({
    fieldKey,
    label,
    dynamicOptions,
    type: FieldTypes.Select,
    selectProps,
    validationProps,
    disabled,
    confirmOpts: { ...confirmOpts, onConfirm: getModalConfirmFunc(confirmOpts) },
    onChange
});
