import React from "react";

import { Row, Col, Typography } from "antd";

import "./TermsOfService.css";
import tos from "./tos";

const { Title } = Typography;

function TermsOfService() {

    const scrollContainerStyle = {
        maxHeight: window.innerHeight * 0.65,
        overflow: "scroll",
        whiteSpace: "pre-wrap",
    } as React.CSSProperties;

    return (
        <div>
            <Row>
                <Col md={24}>
                    <Typography>
                        <Title>EZLease Terms of Service</Title>
                    </Typography>
                </Col>
            </Row>
            <div style={scrollContainerStyle}>{tos}</div>
        </div>
    );
}

export default TermsOfService;
