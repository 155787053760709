import { DatePicker, DatePickerProps } from "antd";
import moment from "moment";
import { FunctionComponent } from "react";
import { IBaseField, FieldTypes, IBaseValidationProps, IRuleMessage, IBaseFieldProps } from "./base";

export interface IFieldDate extends IBaseField {
    type: FieldTypes.Date;
    inputProps?: DatePickerProps;
    mode?: "range";

    validationProps?: IDateValidationProps;
}

export interface IDateValidationProps extends IBaseValidationProps {
    noPast?: IRuleMessage;
    noFuture?: IRuleMessage;
}

export const DateField = (fieldKey:string, label:string, inputProps?: DatePickerProps, validationProps?: IDateValidationProps) => ({fieldKey, label, type: FieldTypes.Date, inputProps, validationProps });
export interface IDateProps extends IBaseFieldProps {
    field: IFieldDate;
    value: any;
    onChange: (input: any) => void;
}

export const Date: FunctionComponent<IDateProps> = ({ field, value, onChange }) => {
    const isMoment = value[field.fieldKey] && value[field.fieldKey].constructor.name === "Moment";
    return field.mode === "range" ? (
        <DatePicker.RangePicker
            id={`rangepicker-${field.fieldKey}`}
            value={!isMoment && value[field.fieldKey] ? moment(value[field.fieldKey]) : value[field.fieldKey]}
            onChange={e => onChange((val: any) => ({ ...val, [field.fieldKey]: e }))}
        />
    ) : (
        <DatePicker
            id={`datepicker-${field.fieldKey}`}
            style={{ width: "100%" }}
            value={!isMoment && value[field.fieldKey] ? moment(value[field.fieldKey]) : value[field.fieldKey]}
            onChange={(e: any) => onChange((val: any) => ({ ...val, [field.fieldKey]: e?.toDate() }))}
        />
    );
};
