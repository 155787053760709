import { Typography } from "antd";
import { TextProps } from "antd/lib/typography/Text";
import { FunctionComponent, ReactElement } from "react";
import { IBaseField, FieldTypes, IBaseFieldProps } from "./base";

export interface IFieldJSX extends IBaseField {
    type: FieldTypes.JSX;
    child: ReactElement|((value:any, record:any) => ReactElement);
}

export interface IJSXProps extends IBaseFieldProps {
    field: IFieldJSX,
    value:any
}

export const JSX:FunctionComponent<IJSXProps> = ({field, value}) => typeof field.child === "function" ? field.child(value[field.fieldKey], value) : field.child;

export const JSXField = (fieldKey:string, label: string, child:ReactElement|((value:any, record:any) => ReactElement)) => ({fieldKey, label, type:FieldTypes.JSX, child})