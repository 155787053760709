import { Action, createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser } from "../../shapes/multiclient";
import { FilterValue } from "antd/lib/table/interface";
import { RootState } from "../store";
import axiosInstance from "../../utils/axiosInstance";
import { Auth } from "aws-amplify";

interface IState {
    currentUser?: IUser;
    error?: string;
    pins: { [key: string]: string[] };
    tableStates: { [key: string]: ITableState } & {
        users: ITableState;
    };
    blockRefresh: boolean;
}

export interface ITableState {
    filters: Record<string, FilterValue | null>;
    sort?: { key: string; order: "ascend" | "descend" };
    currentPage: number;
    pageSize: number;
}

export const setTosAcceptedAsync = createAsyncThunk("user/setToSAccepted", async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    if (state.system.currentUser) {
        const result = await axiosInstance.post(`/client/user/confirm-tos`);
        if (result.status === 200) {
            return result.data.payload;
        }
    }
    return undefined;
});

export const setMFAEnabled = createAsyncThunk("/user/setMFAEnabled", async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    if (state.system.currentUser) {
        const result = await axiosInstance.post(`/client/user/enable-mfa`);
        if (result.status === 200) {
            return result.data.payload;
        }
    }
    return undefined;
})

export const getUserDetailsAsync = createAsyncThunk("user/getUserDetails", async (_, thunkApi) => {
    try {
        const result = await axiosInstance.get(`/client/userdetails`, {
            suppressStatuses: [404]
        });
        if (result.data.payload.state === "disabled") {
            await Auth.signOut();
            return result.data.payload;
        }
        return result.data.payload;
    } catch (error: any) {
        if (error.status === 404) {
            await Auth.signOut();
            return "NOT_FOUND";
        }
        throw error;
    }
});

export const getStreamingInstance = async (useOld: boolean): Promise<string | undefined> => {
    const result = await axiosInstance.get(`/client/user/streaming-instance`, {
        params: useOld ? { useOld: true } : {}
    });
    if (result.status === 200) {
        return result.data.payload.url;
    }
    return undefined;
};

export const systemSlice = createSlice({
    name: "system",
    initialState: {
        pins: {
            users: []
        },
        clientCountsPerAccount: {},
        userCountsPerAccount: {},
        tableStates: {
            users: { filters: {}, currentPage: 1, pageSize: 10 }
        },
        blockRefresh: false
    } as IState,
    reducers: {
        setCurrentUser(state, action: PayloadAction<IUser>) {
            state.currentUser = action.payload;
        },
        clearCurrentUser(state, _action: Action) {
            state.currentUser = undefined;
        },
        addPin(state, action: PayloadAction<{ table: TableKey; value: string }>) {
            if (state.pins[action.payload.table].indexOf(action.payload.value) < 0) {
                state.pins[action.payload.table] = [...state.pins[action.payload.table], action.payload.value];
            }
        },
        removePin(state, action: PayloadAction<{ table: TableKey; value: string }>) {
            let index = state.pins[action.payload.table].indexOf(action.payload.value);
            if (index >= 0) {
                state.pins[action.payload.table].splice(index, 1);
            }
        },
        clearPins(state, action: PayloadAction<{ table: TableKey }>) {
            state.pins[action.payload.table].length = 0;
        },
        setTableState(state, action: PayloadAction<{ table: TableKey; tableState: ITableState }>) {
            state.tableStates[action.payload.table] = action.payload.tableState;
        },
        blockRefresh(state) {
            state.blockRefresh = true;
        },
        allowRefresh(state) {
            state.blockRefresh = false;
        },
        setError: (state, action: PayloadAction<{ error: string }>) => {
            state.error = action.payload.error;
        },
        clearError: state => {
            state.error = undefined;
        }
    },
    extraReducers(builder) {
        builder.addCase(setTosAcceptedAsync.fulfilled, (state, action) => {
            if (state.currentUser) {
                state.currentUser.tosAccepted = true;
                state.currentUser.tosAcceptedDtUtc = action.payload.tosAcceptedDtUtc;
                state.currentUser.version = action.payload.version;
            }
        });
        builder.addCase(getUserDetailsAsync.fulfilled, (state, action) => {
            if (action.payload === 'NOT_FOUND' || ['active','invite sent'].indexOf(action.payload.state) < 0) {
                state.currentUser = undefined;
                state.error = "User is disabled.";
            } else {
                state.currentUser = action.payload;
                state.error = undefined;
            }
        });
        builder.addCase(setMFAEnabled.fulfilled, (state, action) => {
            if (action.payload) {
                if (state.currentUser) {
                    state.currentUser.accountMFAEnabled = action.payload.accountMFAEnabled;
                    state.currentUser.mfaEnabled = action.payload.mfaEnabled;
                    state.currentUser.version = action.payload.version;
                } else {
                    state.currentUser = action.payload;
                }
            }
        });
    }
});

export type TableKey = "accounts" | "clients" | "users" | string;

export const {
    setCurrentUser,
    clearCurrentUser,
    addPin,
    removePin,
    clearPins,
    setTableState,
    blockRefresh,
    allowRefresh,
    clearError, 
    setError,
} = systemSlice.actions;
