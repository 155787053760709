import { Checkbox as AntdCheckbox } from "antd";
import { IBaseField, FieldTypes, IBaseValidationProps, IRuleMessage, IBaseFieldProps } from "./base";
import { FunctionComponent } from "react";

export interface IFieldCheckbox extends IBaseField {
}

export interface ICheckboxValidationProps extends IBaseValidationProps {
    regex?: { test: RegExp; message: IRuleMessage };
}


export interface ICheckboxProps extends IBaseFieldProps {
    field: IFieldCheckbox;
    value: any;
    onChange: (input: any) => void;
}



export const CheckboxField = (fieldKey:string, label:string, validationProps?:ICheckboxValidationProps) => ({fieldKey, label, type: FieldTypes.Checkbox, validationProps });


export const Checkbox: FunctionComponent<ICheckboxProps> = ({ field, value, onChange }) => (
    <AntdCheckbox
        id={`check-${field.fieldKey}`}
        checked={value[field.fieldKey]}
        onChange={e => onChange((val: any) => ({ ...val, [field.fieldKey]: e.target.checked }))}>
        {field.label || field.fieldKey.pretty()}
    </AntdCheckbox>
);
