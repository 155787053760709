import { IAccount} from "../shapes/multiclient"

export const GAAPType = ["USGAAP", "IFRS", "GASB"];

export const GAAPStandard = {
    USGAAP:0,
    IFRS:1,
    GASB:2
}

export interface IAccountSource {
    isLoading: boolean;
    isFetching: boolean;
    isSuccess: boolean;
    data?: IAccount[];
}

export const TABLE_REFRESH_INTERVAL_MSECS = 120000;