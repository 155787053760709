import { FunctionComponent, useEffect, useMemo, useState } from "react";
import moment, { Moment } from "moment";
import { Button, Card, Col, Row, notification, Spin } from "antd";
import { useParams } from "react-router-dom";
import Forms from "../../../../components/Forms";
import { FieldErrors, FieldType, FieldTypes } from "../../../../components/Forms/type";
import axiosInstance from "../../../../utils/axiosInstance";
import utils from "../../../../utils";
import { useLazyGetLeaseNumbersQuery, useLazyGetSystemOptionsQuery } from "../../../../redux/actions/reports";
import { pretty } from "../../../../utils/inject";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { GAAPStandard } from "../../../../utils/types";
import { SearchProps } from "antd/lib/input";

interface IFormState {
    scope:'lessee'|'lessor'|'sbita',
    leaseSelectionMode: 'singleLease'|'allLeases',
    fragment:string,
    scopeOptions:{value:string,label?:string}[],
    asOfDate:Moment,
    selectedLeases:string[]
}


const CurrentRent: FunctionComponent = () => {
    const params = useParams();
    const clientId = useSelector((state: RootState) => state.client?.clientId || '');
    const [data, setData] = useState<IFormState>({
        scope: "lessee",
        leaseSelectionMode: "allLeases",
        scopeOptions: [{ value: "lessee" }, { value: "lessor" }],
        asOfDate: moment(),
        selectedLeases:[],
        fragment: ''
    });
    const [downloading, setDownloading] = useState<boolean>(false);
    const [errors, setErrors] = useState<FieldErrors>({});
    const [getLeaseNumbers, leaseNumbers] = useLazyGetLeaseNumbersQuery();
    const [getSystemOptions, systemOptions] = useLazyGetSystemOptionsQuery();
    const [searchFragment, setSearchFragment] = useState<string>('');
    const [currentLeaseNumbers, setCurrentLeaseNumbers] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    // reload system options when scope or clientId is changed
    useEffect(() => {
        if (clientId && data.scope) {
            getSystemOptions({ clientId, scope: data.scope });
        }
    }, [clientId, data.scope, getSystemOptions]);
    
    // reload lease numbers when scope or clientId or fragment is changed
    useEffect(() => {
        if (clientId && data.scope && searchFragment) {
            getLeaseNumbers({clientId, scope: data.scope, fragment: searchFragment });
        }
    }, [clientId, searchFragment, data.scope, getLeaseNumbers]); 

    // check for GASB on systemOptions load
    useEffect(() => {
        if (systemOptions.isSuccess && systemOptions.data) {
            setLoading(true);
            const isGAAPTypeGASB = systemOptions.data.enGAAPType === GAAPStandard.GASB
            setData((data: IFormState) => ({
                ...data,
                scopeOptions: isGAAPTypeGASB 
                    ? [{ value: "lessee", label: "Lessee" }, { value: "lessor", label: "Lessor" }, { value: "sbita", label: "SBITA" }]
                    : [{ value: "lessee", label: "Lessee" }, { value: "lessor", label: "Lessor" }]
            }));
        setTimeout(() => {
            setLoading(false);
        }, 2000);            
        }
    }, [systemOptions,clientId])

    useEffect(() => {
        setData((draft:IFormState) => ({...draft, selectedLeases:[]}));
        setCurrentLeaseNumbers([]);
        if (data.leaseSelectionMode === 'singleLease') {
            getLeaseNumbers({clientId, scope: data.scope, fragment: searchFragment});
        }
    }, [clientId, data.leaseSelectionMode, data.scope, getLeaseNumbers, searchFragment]); 

    useEffect(() => {
        if (!leaseNumbers.isFetching && leaseNumbers.isSuccess && leaseNumbers.data) {
            setCurrentLeaseNumbers(leaseNumbers.data.map((i:any) => ({...i, key: i.leaseID})));
        }
    }, [leaseNumbers.isFetching, leaseNumbers.isSuccess, leaseNumbers.data])

    const fields = useMemo<FieldType[]>(() => {
        const res: (FieldType | undefined)[] = [
            {
                type: FieldTypes.Label,
                key: "selectLeases",
                textProps: { strong: true }
            },
            {
                isRequired: true,
                type: FieldTypes.RadioGroup,
                key: "scope",
                label: "Lease type",
                options: data.scopeOptions,
                colProps: { xs: 24, style: { paddingLeft: 20 } }
            },
            { type: FieldTypes.hidden, key: "hidden", colProps: { xs: 24 } },
            {
                isRequired: true,
                type: FieldTypes.RadioGroup,
                key: "leaseSelectionMode",
                colProps: { style: { paddingLeft: 20 } },
                options: [{ value: "allLeases" }, { value: "singleLease" }]
            },
            data.leaseSelectionMode === "singleLease" 
                ? {
                    type: FieldTypes.inputText,
                    key: "fragment",
                    label: " ",
                    mode: "search",
                    inputProps: { 
                        onChange: (e) => {
                            setData((draft) => ({...draft, fragment: (e.target as HTMLInputElement).value}))
                        },
                        onPressEnter: _ => {
                            if (searchFragment === data.fragment) {
                                getLeaseNumbers({clientId, scope: data.scope, fragment: searchFragment});
                            } else {
                                setSearchFragment(data.fragment || '')
                            }
                        },
                        onSearch: _ => { 
                            if (searchFragment === data.fragment) {
                                getLeaseNumbers({clientId, scope: data.scope, fragment: searchFragment});
                            } else {
                                setSearchFragment(data.fragment || '')
                            }
                        }
                    } as SearchProps
                }
                : undefined,
            data.leaseSelectionMode === "singleLease"
                ? { type: FieldTypes.hidden, key: "hidden2", colProps: { xs: 24 } }
                : undefined,
                data.leaseSelectionMode === "singleLease"
                ? {
                    isRequired: true,
                    type: FieldTypes.TableSelect,
                    key: "selectedLeases",
                    label: "Lease",
                    dataSource: currentLeaseNumbers,
                    columns: ["leaseNumber", "description"],
                    colProps: { flex: "auto" },
                    tableProps: {
                        loading:  leaseNumbers.isFetching
                    }
                }
                : undefined,
            {
                type: FieldTypes.Label,
                key: "selectReportOptions",
                colProps: { xs: 24 },
                textProps: { strong: true }
            },
            {
                type: FieldTypes.Date,
                key: "asOfDate",
                label: "As of date",
                colProps: { style: { paddingLeft: 20 } }
            }
        ];
        return res.filter(e => e) as FieldType[];
    }, [data.scopeOptions, data.leaseSelectionMode, data.fragment, data.scope, currentLeaseNumbers, leaseNumbers.isFetching, searchFragment, getLeaseNumbers, clientId]);

    const getLeaseNumber = (leaseId: string) => {
        if (!leaseId) return "";
        return (leaseNumbers.data || []).find((e: any) => e.LeaseID === leaseId)?.LeaseNumber || "";
    };

    const download = async () => {
        setDownloading(true);
        try {
            let newErrors:FieldErrors = {};
            if (data.leaseSelectionMode === 'singleLease' && data.selectedLeases.length < 1) {
                newErrors.selectedLeases = 'Select the lease to report on';
            }

            newErrors = {...newErrors, ...Forms.validate(fields, data)}
           
            setErrors(newErrors);
            if (Object.keys(newErrors).length > 0) {
                setDownloading(false);
                return;
            }

            const response = await axiosInstance({
                method: "POST",
                data: {
                    clientId: clientId,
                    as_of_date: data.asOfDate ? data.asOfDate.toDate() : "",
                    lease_id: (data.selectedLeases || [])[0] || "",
                    lease_number: getLeaseNumber((data.selectedLeases || [])[0] || ""),
                    scope: data.scope
                },
                url: `/client/reports/${clientId}/${data.scope}/currentrent`
            });
            if (response.headers["x-response-status"] === "Exception") {
                throw new Error(response.data);
            }
            utils.processDownload(response);
        } catch (err: any) {
            if (err.errors) {
                setErrors(err.errors);
            } else {
                notification.error({
                    message: "Error",
                    description: err.message,
                });
            }
        }
        setDownloading(false);
    };

    return (
        <Row>
            <Col xs={24}>
                <Spin spinning={loading}>
                <Card loading={systemOptions.isLoading} title={pretty(params?.type ?? '')} style={{ width: "100%" }} type={"inner"}>
                    <Forms fields={fields} state={[data, setData]} errors={errors} />
                    <Row>
                        <Col xs={24}>
                            <Button
                                id="button-download-report"
                                type={"primary"}
                                onClick={download}
                                loading={downloading}>
                                Download Report
                            </Button>
                        </Col>
                    </Row>
                </Card>
                </Spin>
            </Col>
        </Row>
    );
};

export default CurrentRent;
