import axios, { AxiosRequestConfig,AxiosInstance,AxiosResponse } from "axios";
import { BaseQueryFn } from "@reduxjs/toolkit/query";
import { notification } from "antd";
import { Auth } from "aws-amplify";

interface ExtendedAxiosRequestConfig extends AxiosRequestConfig {
    suppressStatuses?: number[];
    _retried?:boolean;
}

interface ExtendedAxionRequestConfig<D> extends AxiosRequestConfig<D> {
    suppressStatuses?: number[];
    _retried?:boolean;
}

interface ExtendedAxiosInstance extends AxiosInstance {
    get<T = any, R = AxiosResponse<T>>(url: string, config?: ExtendedAxiosRequestConfig): Promise<R>;
    post<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: ExtendedAxionRequestConfig<D>): Promise<R>;
}

const axiosInstance: ExtendedAxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_EZL_AMPL_APIENDPOINT,
    headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'x-api-key': process.env.REACT_APP_API_KEY || '',
        Authorization: '',
    },
}) as ExtendedAxiosInstance;


axiosInstance.interceptors.request.use(
    async function (config: ExtendedAxiosRequestConfig) {
        if (!config.headers?.Authorization) {
            axiosInstance.defaults.headers.common.Authorization = `Bearer ${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`;
            if (!config.headers) {
                config.headers = {};
            }
            config.headers.Authorization = axiosInstance.defaults.headers.common.Authorization;
        }
        if (!config.suppressStatuses) {
            config.suppressStatuses = [];
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    function (response) {
        if (response.data.responseText) {
            if (response.status === 200) {
                notification.success({
                    message: 'Success',
                    description: response.data.responseText
                });
            } else {
                notification.warning({
                    message: 'Warning',
                    description: response.data.responseText
                });
            }
        }
        return response;
    },
    async function (error) {
        const originalRequest = error.config as ExtendedAxiosRequestConfig;
        
        // Check for access denied
        if ([401, 403].indexOf(error.response.status) >= 0 && !originalRequest._retried) {
            try {
                originalRequest._retried = true;
                await refreshAxiosToken();
                originalRequest.headers = {...originalRequest.headers, Authorization: axiosInstance.defaults.headers.common.Authorization};
                return await axiosInstance(originalRequest);
              } catch (error) {
                window.location.reload();
                return;
              }
        }
        
        // check whether this error should be suppressed
        const config = error.config as ExtendedAxiosRequestConfig;
        if (config.suppressStatuses?.includes(error.response?.status || 0)) {
            // return just the response data
            throw error.response;
        }
        // generic handling
        switch(error.status) {
            case 401:
            case 403:
                notification.error({
                    message: 'Error',
                    description: 'Access Denied.'
                });
                break;
            case 422:
                notification.error({
                    message: 'Error',
                    description: error.response.data?.responseText ? error.response.data.responseText : 'Invalid Request.'
                });
                break;
            case 404:
                notification.error({
                    message: 'Error',
                    description: error.response.data?.responseText ? error.response.data.responseText : 'The resource does not exist'
                });
                break;
            default:
                notification.error({
                    message: 'Error',
                    description: error.response.data?.responseText ? error.response.data.responseText : 'An API error occurred.'
                });              
        }
        throw error.response;
    }
);

export const refreshAxiosToken = async () => {
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`;
};

export const axiosBaseQuery =
    (): BaseQueryFn<{
        url: string;
        method?: AxiosRequestConfig["method"];
        data?: AxiosRequestConfig["data"];
    },
        unknown,
        unknown> =>
        async ({ url, method = "get", data }) => {
            try {
                const result = await axiosInstance({ url: url, method, data });
                return { data: result.data.payload ? result.data.payload : result.data };
            } catch (axiosError:any) {
                return {
                    error: { status: axiosError.status, data: axiosError.data },
                };
            }
        };
export default axiosInstance;
