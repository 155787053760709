import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { Card, Col, Row, Typography } from "antd";
import Forms from "../../../components/Forms";
import { FieldType, FieldTypes } from "../../../components/Forms/type";
import Notifications from "./Notifications";
import RecentlyAddedLeases from "./RecentlyAddedLeases";
import ActiveAndExpiredLeases from "./ActiveAndExpiredLeases";
import LeaseTotals from "./LeaseTotals";
import { useLazyGetDashboardSelectionListQuery } from "../../../redux/actions/dashboard";
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

interface OwnProps {
    // Add some types here
    temp?: any;
}

type Props = OwnProps;

const Dashboard: FunctionComponent<Props> = (props) => {
    const user = useSelector((state:RootState) => state.system.currentUser);
    const clientId = useSelector((state: RootState) => state.client?.clientId);

    const [getDashboardSelectionList, dashboardSelectionList] = useLazyGetDashboardSelectionListQuery();

    const [data, setData] = useState<any>({
        scope: "lessee"
    })

    const scopeList = useMemo(() => {
        if (dashboardSelectionList.isSuccess && dashboardSelectionList.data) {
            return (dashboardSelectionList.data.scopeList).map((e: any) => ({ value: e.toLowerCase(), label: e }))
        }
        return [];        
    }, [dashboardSelectionList])
    
    useEffect(() => {
        if (data.scope && clientId) {
            getDashboardSelectionList({ scope: data.scope, clientId: clientId })
        }
    }, [data.scope, getDashboardSelectionList, clientId])

    const notificationTypes = useMemo(() => {
        return dashboardSelectionList.isSuccess && dashboardSelectionList.data ? dashboardSelectionList.data.notificationTypeList : []
    }, [dashboardSelectionList]);
  
    const fields = useMemo<FieldType[]>(() => {
        return [
            {
                type: FieldTypes.Select,
                label: "View",
                options: scopeList,
                key: 'scope',
                style: { minWidth: 150  },
                containerStyle: { marginBottom: 24,bottom:28 }
            }
        ]
    }, [scopeList])

    if (!user || !clientId) {
        return null;
    }

    return (
        <>
            <Row>
                <Col>
                    <Typography.Title level={4} style={{ fontWeight: '600' }}>
                        Home
                    </Typography.Title>
                </Col>
            </Row>
            <Row style={{paddingLeft : "4.4%"}}>
                <Col md={40} xs={20} >
                    <Card loading={!user || !clientId} style={{border: "none",height: 75}}>
                        <Forms layout={"horizontal"} fields={fields} state={[data, setData]} errors={{}}/>
                    </Card>
                </Col>
            </Row>
            <Row justify={"center"} align="middle" gutter={[16, 16]}>
                <Col md={100} xs={22}>
                    <Notifications clientId={clientId} scope={data.scope} notificationTypes={notificationTypes}/>
                </Col>
                <Col md={50} xs={11}>
                    <ActiveAndExpiredLeases clientId={clientId} scope={data.scope} />
                </Col>
                <Col md={50} xs={11}>
                    <LeaseTotals clientId={clientId}  scope={data.scope} />
                </Col>
                <Col md={100} xs={22}>
                    <RecentlyAddedLeases  clientId={clientId} scope={data.scope}/>
                </Col>
            </Row>
        </>
    );
};

export default Dashboard;
