import { IBaseField, FieldTypes, IBaseFieldProps } from "./base";
import { Row, Col, Typography, Form as AntdForm } from 'antd';
import { FunctionComponent } from "react";

export interface IFieldSection extends IBaseField {
    fieldKey: string;
    title: string;
    fields: IBaseField[];
}

export const SectionField = (
    fieldKey: string,
    title: string,
    fields: IBaseField[]
) => ({ fieldKey, type: FieldTypes.Section, title, fields });

export interface ISectionProps extends IBaseFieldProps {
    field: IFieldSection;
    value: any;
    onChange: (input: any) => void;
    renderField: { [key: string]: (props: any) => JSX.Element };
}

export const Section: FunctionComponent<ISectionProps & { errors?: { [key: string]: string[] } }> = ({ field, value, onChange, renderField, errors = {} }) => {

    const calculatePadding = (label: any) => {
        const basePadding = 5;
        const maxLength = 20;
        const labelLength = label ? label.length : 0;
    
        if (labelLength <= maxLength) {
            return basePadding;
        } else {
            return basePadding - (labelLength - maxLength) * 0.5;
        }
    };

    return (
        <div className="section-container">
            <h3>{field.title}</h3>
            {field.fields.map((subField, index) => {
                const fieldErrors = (errors || {})[subField.fieldKey] || [];
                const errorString = fieldErrors.join(" ").replace(/,\.\s/g, '. ').trim();
                const itemProps = {
                    validateStatus: (fieldErrors.length > 0 ? "error" : undefined) as "error" | undefined,
                    help: errorString,
                };
                const noLabel = subField.type === FieldTypes.Label || subField.type === FieldTypes.Checkbox || !subField.label;
                const containerPadding = calculatePadding(subField.label);


                return (
                    <Row key={subField.fieldKey} style={{ marginBottom: '8px' }}>
                        <Col span={24} key={subField.fieldKey}>
                            <Row>
                                {!noLabel && (
                                    <Col span={8}>
                                        <div style={{ paddingLeft: containerPadding }}>
                                            {subField.validationProps?.required ? (
                                                <small style={{ marginRight: 3 }}>
                                                    <Typography.Text type={"danger"}>
                                                        *
                                                    </Typography.Text>
                                                </small>
                                            ) : null}
                                            {subField.label}:
                                        </div>
                                    </Col>
                                )}
                                <Col span={noLabel ? 24 : 16}>
                                    <AntdForm.Item {...itemProps} style={{ paddingRight: calculatePadding(subField.label) }}>
                                        {renderField[subField.type]({ field: subField, value, onChange })}
                                    </AntdForm.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                );
            })}
        </div>
    );
};
