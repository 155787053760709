import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Card, Table, Typography,Empty } from "antd";
import { useLazyGetRecentlyAddedLeasesQuery } from '../../../redux/actions/dashboard';

const { Title } = Typography

interface OwnProps {
    clientId?: string | null;
    scope?: string;
}

type Props = OwnProps;

const columns = [
    {
        title: 'Lease',
        dataIndex: 'lease',
        key: 'Lease',
        width: 160
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'Description',
        width: 400
    },
    {
        title: 'Created by',
        dataIndex: 'createdBy',
        key: 'CreatedBy',
        width: 200
    },
]

const RecentlyAddedLeases: FunctionComponent<Props> = ({ clientId, scope }) => {
    const [getRecentlyAddedLeases, recentlyAddedLeases] = useLazyGetRecentlyAddedLeasesQuery()
    const [currentPageValue, setCurrentPageValue] = useState<number>(1)
    useEffect(() => {
        if (scope && clientId) {
            setCurrentPageValue(1)
            getRecentlyAddedLeases({ clientId: clientId, scope: scope })
        }
    }, [clientId, scope, getRecentlyAddedLeases]);

    const dataSource = useMemo(() => {
        if (recentlyAddedLeases.isSuccess && recentlyAddedLeases.data.recentlyAdded.length > 0) {
            return recentlyAddedLeases.data.recentlyAdded.map((r:any) => ({ createdBy: r.createdBy, lease: r.lease, description: r.description, key: r.lease }));
        }
        return [];
    }, [recentlyAddedLeases])

        return (
        <Card bodyStyle={{ paddingTop: 6 }}>
            <Title level={5}>
                Recently Added Leases
            </Title>
            {(!clientId || !scope) ? (
                <Empty description="Please select a client and a scope." />
            ) : recentlyAddedLeases.isSuccess && (!recentlyAddedLeases.data || recentlyAddedLeases.data.length < 1) ? (
                <Empty description="No leases found for the selected client and scope." />
            ) : (
                <Table
                    dataSource={dataSource}
                    pagination={{ defaultPageSize: 10, showSizeChanger: false, current: currentPageValue, onChange: (page) => setCurrentPageValue(page), total: recentlyAddedLeases?.data?.totalRecords }}
                    columns={columns} bordered size={"small"}
                    loading={recentlyAddedLeases.isFetching}
                />
            )}
        </Card>
    );
};

export default RecentlyAddedLeases;
