import { FunctionComponent } from "react";
import { IBaseField, FieldTypes, IBaseFieldProps } from "./base";

export interface IFieldHR extends IBaseField {
    type: FieldTypes.HorizontalRule;
}

export interface IHRProps extends IBaseFieldProps {
    field: IFieldHR
}

export const HorizontalRule:FunctionComponent<IHRProps> = () => <hr />

export const HorizontalRuleField = (fieldKey:string) => ({ fieldKey, type: FieldTypes.HorizontalRule });