import { createContext, FunctionComponent, PropsWithChildren, useContext, useEffect, useMemo, useState } from "react";
import { Breadcrumb } from "antd";
import { Link, useLocation } from "react-router-dom";
import { pretty } from "../../utils/inject";

export interface IBreadcrumb {
    route?: string;
    label?: string;
    key: string;
}

const MainBreadcrumbs: FunctionComponent & {
    Provider: FunctionComponent<PropsWithChildren>;
} = _ => {
    const { breadcrumbs } = useMainBreadcrumbs();

    if (breadcrumbs.length === 1) return <div style={{ height: 22 }} />;

    return (
        <Breadcrumb>
            {breadcrumbs.map(breadcrumb => {
                const label = breadcrumb.label || pretty(breadcrumb.key);
                return (
                    <Breadcrumb.Item key={breadcrumb.key}>
                        {breadcrumb.route ? <Link to={breadcrumb.route}>{label}</Link> : label}
                    </Breadcrumb.Item>
                );
            })}
        </Breadcrumb>
    );
};

interface MainBreadcrumbsHandler {
    setBreadcrumbs: (value: IBreadcrumb[]) => void;
    breadcrumbs: IBreadcrumb[];
}

const MainBreadcrumbsContext = createContext<MainBreadcrumbsHandler>({
    setBreadcrumbs: val => val,
    breadcrumbs: []
});

export const useMainBreadcrumbs = () => useContext<MainBreadcrumbsHandler>(MainBreadcrumbsContext);

export const useSetBreadcrumbs = (breadcrumbs: IBreadcrumb[]) => {
    const location = useLocation();
    const { setBreadcrumbs } = useMainBreadcrumbs();
    useEffect(() => {
        setBreadcrumbs(breadcrumbs);
        return () => setBreadcrumbs([]);
    }, [location.pathname, breadcrumbs, setBreadcrumbs]);
};

const MainBreadcrumbsProvider: FunctionComponent<PropsWithChildren> = props => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>([]);
    const location = useLocation();

    const defaultBreadcrumbs = useMemo<IBreadcrumb[]>(() => {
        const pathnameArr: string[] = (location?.pathname || "/").split("/").filter((e, i) => e || i === 0);
        if (pathnameArr[1] === "home") delete pathnameArr[1];
        return pathnameArr
            .map((e, index) => {
                let val = e;
                if (index === 0) val = "home";
                const fromStart = pathnameArr.slice(0, index + 1);
                return {
                    route: index < pathnameArr.length - 1 ? fromStart.join("/") || "/" : undefined,
                    label: pretty(val),
                    key: `breadcrumb${fromStart.join("-")}`
                };
            })
            .filter(e => e.label !== "Home");
    }, [location?.pathname]);

    return (
        <MainBreadcrumbsContext.Provider
            value={{ setBreadcrumbs, breadcrumbs: breadcrumbs.length > 0 ? breadcrumbs : defaultBreadcrumbs }}>
            {props.children}
        </MainBreadcrumbsContext.Provider>
    );
};

MainBreadcrumbs.Provider = MainBreadcrumbsProvider;

export default MainBreadcrumbs;
