import { IBaseEntity } from "./IBaseEntity";

export type IUserState = 'active' | 'disabled' | 'deleted' | 'invite sent' | 'reset pending';

export interface IUser extends IBaseEntity {
    accountId: string;
    username: string;
    email: string;
    verified: boolean;
    appstreamApiKey: string;
    accountAdmin?: string;
    clients: { [clientId: string]: { name: string } };
    tosAccepted?: boolean;
    tosAcceptedDtUtc?: Date;
    state: IUserState;
    mfaEnabled?: boolean;
    accountMFAEnabled?: boolean;
    usesFederation?: string;
}

export class User implements IUser {
    accountId: string = "";
    username: string = "";
    email: string = "";
    verified: boolean = false;
    appstreamApiKey: string = "";
    accountAdmin?: string | undefined = "false";
    clients: { [clientId: string]: { name: string } } = {};
    tosAccepted?: boolean | undefined;
    tosAcceptedDtUtc?: Date | undefined;
    version: number = -1;
    isDeleted: boolean = false;
    insertedBy?: string | undefined;
    insertedAtUtc?: Date | undefined;
    state: IUserState = 'active';

    constructor(accountId: string) {
        this.accountId = accountId;
    }
}
