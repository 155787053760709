/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMemo, useCallback, FunctionComponent, ReactElement, useState } from "react";
import { Table, Spin, Tooltip, Col, Row } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import styles from "./RoleAssignmentGrid.module.css";
import "./antdOverrides.css";
import { IRole } from "../../../../shapes/multiclient/IRole";
import { FieldTypes, IBaseField, IBaseFieldProps } from "../base";
import { generateColumn } from "../../../../utils/";
import {
    FilterOutlined,
    VerticalAlignBottomOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined
} from "@ant-design/icons";

export interface IRoleRow {
    key: string;
    label: string;
    roles: string[];
}
export type KeyedRoleSet = {
    rows: { [key: string]: IRoleRow };
    isLoading: boolean;
    isSuccess: boolean;
};

export interface IRoleAssignmentGridProps extends IBaseFieldProps {
    fieldKey: string;
    label?: string | ReactElement;
    pageSize: number;
    rowKeyLabel: string;
    rows: KeyedRoleSet;
    roles: IRole[];
    setRoles: React.Dispatch<React.SetStateAction<KeyedRoleSet>>;
    showToggle?: boolean;
    readonly?: boolean;
    onNavigate?: (key: string) => void;
}

export const RoleAssignmentGridField = (
    fieldKey: string,
    label: string | ReactElement,
    rowKeyLabel: string,
    pageSize: number,
    rows: KeyedRoleSet,
    roles: IRole[],
    setRoles: React.Dispatch<React.SetStateAction<KeyedRoleSet>>,
    showToggle?: boolean,
    readonly?: boolean,
    onNavigate?: (key: string) => void
): IFieldRoleAssignmentGrid => ({
    type: FieldTypes.RoleAssignmentGrid,
    fieldKey,
    label,
    rowKeyLabel,
    pageSize,
    rows,
    roles,
    setRoles,
    showToggle,
    readonly,
    onNavigate
});

export interface IFieldRoleAssignmentGrid extends IBaseField, IRoleAssignmentGridProps {}

/** 
key - name of the page to use this component, used for naming interactable element for automation testing
pageSize - prop for determining number of items to display on each page
data - data source values with clientid and permissions
example:
{

   ‘EZ_505901’: ‘admin,write,read’

}
allRoles - "none", "read", "write", "admin", hides the column if not on the array
onSave - callback to save and pass values to api
**/

const RoleAssignmentGrid: FunctionComponent<IRoleAssignmentGridProps> = ({
    fieldKey,
    label,
    pageSize,
    rows,
    roles,
    rowKeyLabel,
    setRoles,
    showToggle = false,
    readonly = false,
    onNavigate
}) => {
    const [filterRole, setFilterRole] = useState<string | undefined>(undefined);
    // updates role state based on the updated rows
    const updateRolesState = useCallback(
        (updatedRows: IRoleRow[]) => {
            const updatedRolesRows = { ...rows.rows };
            updatedRows.forEach(r => {
                updatedRolesRows[r.key] = {
                    key: r.key,
                    label: r.label,
                    roles: r.roles
                };
            });

            setRoles({
                isSuccess: true,
                isLoading: false,
                rows: updatedRolesRows
            });
        },
        [rows.rows, setRoles]
    );

    function applyRoleToRow(rowKey: string, roleCode: string) {
        const matchedRole = roles.find(r => r.code === roleCode);
        if (!matchedRole) {
            return;
        }
        const row = rows.rows[rowKey];
        if (!row) {
            return;
        }

        // filter to non-exluded roles
        row.roles = row.roles.filter((code: string) => matchedRole.removes.indexOf(code) < 0);
        // add required roles, and specific role
        row.roles = [...new Set([...row.roles, ...matchedRole.requires, roleCode])];
        updateRolesState(Object.values({ ...rows.rows, [rowKey]: row }));
    }

    function applyRoletoAll(roleCode: string) {
        const matchedRole = roles.find(r => r.code === roleCode);
        if (!matchedRole) {
            return;
        }
        const updatedRows = Object.values(rows.rows);
        updatedRows.forEach(row => {
            // filter to non-exluded roles
            row.roles = row.roles.filter((code: string) => matchedRole.removes.indexOf(code) < 0);
            // add required roles, and specific role
            row.roles = [...new Set([...row.roles, ...matchedRole.requires, roleCode])];
        });

        updateRolesState(updatedRows);
    }

    const dataSource = useMemo(() => {
        return filterRole
            ? Object.values(rows.rows).filter(r => r.roles.indexOf(filterRole) >= 0)
            : Object.values(rows.rows);
    }, [rows, filterRole]);

    const columns: any = [
        generateColumn({
            key: "key",
            title: rowKeyLabel,
            sorter: (a: IRoleRow, b: IRoleRow) => a.label.localeCompare(b.label),
            onFilter: (value: string, record: IRoleRow) => record.label.toLowerCase().includes(value.toLowerCase()),
            render: (value: string, row: IRoleRow) =>
                !onNavigate || row.key === "all" ? (
                    row.label
                ) : (
                    <Tooltip title={`Navigate to ${row.label}`}>
                        <a
                            href="#"
                            onClick={e => {
                                e.preventDefault();
                                onNavigate(row.key);
                            }}>
                            {row.label}
                        </a>
                    </Tooltip>
                ),
            width: 292
        }),
        ...roles.map(role => ({
            title: (
                <>
                    <pre>
                        <span>{role.label}</span>
                        {readonly ? null : (
                            <Tooltip title={`Apply ${role.label} to all`}>
                                <VerticalAlignBottomOutlined
                                    style={{ marginLeft: 5 }}
                                    onClick={() => applyRoletoAll(role.code)}
                                />
                            </Tooltip>
                        )}
                        <Tooltip title={filterRole === role.code ? "Remove filter" : `Filter by ${role.label}`}>
                            <FilterOutlined
                                style={{ marginLeft: 5, color: filterRole === role.code ? "#1890FF" : undefined }}
                                onClick={() =>
                                    setFilterRole(filterRole === role.code ? undefined : role.code)
                                }></FilterOutlined>
                        </Tooltip>
                    </pre>
                </>
            ),
            dataIndex: role.code,
            key: role.code,
            render: (_: string, row: any) =>
                row.roles.indexOf(role.code) < 0 ? (
                    <CloseCircleOutlined
                        key={`${row.key}-${role.code}`}
                        id={`${row.key}-checkbox-user-editor-${role.code}`}
                        style={{ color: readonly ? undefined : 'red', fontSize: '20px', marginLeft: 15, opacity: readonly ? 0.1 : undefined}}
                        onClick={readonly ? undefined : () => applyRoleToRow(row.key, role.code)}
                    />
                ) : (
                    <CheckCircleOutlined
                        key={`${row.key}-${role.code}`}
                        id={`${row.key}-checkbox-user-editor-${role.code}`}
                        style={{color: readonly ? undefined : 'green', fontSize: '20px', marginLeft: 15}}
                        onClick={readonly ? undefined : () => applyRoleToRow(row.key, role.code)}
                    />
                )
        }))
    ];
    return (
        <div className={styles["clienteditor-table-container"]}>
            <div style={{ position: "relative", marginTop: 10 }}>
                {rows.isLoading ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "absolute",
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            backgroundColor: "rgba(255, 255, 255, 0.8)",
                            zIndex: 100
                        }}>
                        <Spin size="large" />
                    </div>
                ) : null}
                <>
                    <hr />
                    <Row>
                        <Col span={6}>
                            <div style={{ paddingLeft: 5 }}>
                                <strong>{label} :</strong>
                            </div>
                        </Col>
                    </Row>

                    <Table
                        id={`${fieldKey}-table-user-editor`}
                        onRow={record => ({
                            id: `${fieldKey}-row-user-editor-${record.key}`
                        })}
                        columns={columns}
                        dataSource={dataSource}
                        className={styles["clienteditor-table"]}
                        pagination={{
                            itemRender: (page, type, originalElement) => {
                                if (type === "page") {
                                    return (
                                        <a
                                            id={`${fieldKey}-usereditor-page-${page}`}
                                            href="#">
                                            {page}
                                        </a>
                                    );
                                }
                                if (type === "next") {
                                    return (
                                        <span
                                            id={`${fieldKey}-clienteditor-page-next`}
                                            className="ant-pagination-item-link">
                                            <RightOutlined />
                                        </span>
                                    );
                                }
                                if (type === "prev") {
                                    return (
                                        <span
                                            id={`${fieldKey}-clienteditor-page-prev`}
                                            className="ant-pagination-item-link">
                                            <LeftOutlined />
                                        </span>
                                    );
                                }
                                return originalElement;
                            },
                            pageSize: pageSize,
                            showSizeChanger: false // hide showing of size change to limit just to 5 per page
                        }}
                    />
                </>
            </div>
        </div>
    );
};

export default RoleAssignmentGrid;
