import { FunctionComponent, useEffect, useState, useMemo, useCallback } from "react";
import { IUser, User } from "../../../shapes/multiclient";
import { IField } from "../../../components/Forms-Admin";
import { KeyedRoleSet, RoleAssignmentGridField } from "../../../components/Forms-Admin/FieldTypes/RoleAssignmentGrid";
import { ROLES } from "../../../shapes/multiclient/IRole";
import { useResetMFAMutation } from "../../../redux/actions/userApi";
import { isTruthy } from "../../../utils";
import Editor, { EditorAction, IEditorProps } from "../../../components/Editor";
import { Button, Modal, Tag, Tooltip, notification } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { JSXField } from "../../../components/Forms-Admin/FieldTypes/JSX";
import { LabelField } from "../../../components/Forms-Admin/FieldTypes/Label";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

type UserDetailsProps = {
    onAction: (action: string, data: IUser) => void;
};

interface IClientDetails {
    name: string;
    roles: string;
}
const UserDetails: FunctionComponent<
    Omit<IEditorProps, "title" | "fields" | "newRecord" | "actions"> & UserDetailsProps
> = props => {
    const { state, onAction } = props;
    const [data, setData] = state;
    const [resetMFA] = useResetMFAMutation();
    // state for tracking what roles are allocated to which users for post-client-save update
    const [clientRoles, setClientRoles] = useState<KeyedRoleSet>({
        isSuccess: false,
        isLoading: true,
        rows: {}
    });
    const currentUser: any = useSelector<RootState>(state => state.system.currentUser);
    // update clientRoles whenever our clientList changes changes (change of account, or initial load in)
    useEffect(() => {
        const output: KeyedRoleSet = {
            isSuccess: true,
            isLoading: false,
            rows: {}
        };

        Object.entries(currentUser.clients as {[clientId:string]:IClientDetails}).forEach((entry:[string, IClientDetails]) => {
            output.rows[entry[0]] = {
                key: entry[0],
                label: entry[1].name,
                roles: data.clients[entry[0]] ? data.clients[entry[0]].split(',') : data.accountAdmin === 'true' ? ['admin','write','read'] : ['none']
            };
        });
        setClientRoles(output);
    }, [setClientRoles, data.clients, data.accountId, data.accountAdmin, currentUser]);

    const newRecord: () => IUser = () => new User("");

    const ResetMFAButton = useCallback(({ record }: any) => (
        <Tooltip title="Reset the user's MFA, forcing them to register a new software token">
            <Button
                size="small"
                type="default"
                danger
                style={{ marginLeft: 10, borderRadius: 3 }}
                onClick={() => {
                    Modal.destroyAll();
                    Modal.confirm({
                        content: (
                            <>
                                <p>
                                    This will clear the registered MFA software token for user '
                                    {record.username}'. The user will be forced to register a new MFA
                                    software token on next login.
                                </p>
                                <p>
                                    <strong>Reset the user's MFA?</strong>
                                </p>
                            </>
                        ),
                        okButtonProps: { danger: true, type: "primary" },
                        okText: "Reset MFA",
                        cancelText: "Cancel",
                        onOk: async () => {
                            const result = await resetMFA(record).unwrap();
                            setData(result);
                        },
                        onCancel: () => {}
                    });
                }}>
                Reset
            </Button>
        </Tooltip>
    ), [resetMFA, setData]);

    const fields: IField[] = useMemo(() => {
        return [
            JSXField(
                "username",
                "Username",
                <span>
                    {data.username}
                    <CopyOutlined
                        style={{ cursor: "pointer", marginLeft: 4 }}
                        onClick={() => {
                            navigator.clipboard.writeText(data.username);
                            notification.success({ message: "Username copied to clipboard" });
                        }}
                    />
                </span>
            ),
            JSXField(
                "email",
                "Email Address",
                <span>
                    {data.email}
                    <CopyOutlined
                        style={{ cursor: "pointer", marginLeft: 4 }}
                        onClick={() => {
                            navigator.clipboard.writeText(data.email);
                            notification.success({ message: "Email address copied to clipboard" });
                        }}
                    />
                </span>
            ),
            ...[
                ...(data.usesFederation ? [JSXField("usesFederation", "SSO", (value) => <Tooltip title={`Third-party SSO through '${value}'`}><Tag style={{width: 100, textAlign: "center"}} color={"red"}>Third-Pary SSO</Tag></Tooltip>)]:[])
            ],
            JSXField("mfaEnabled", "MFA", (value: boolean | undefined, record: any) => {
                return (record?.accountMFAEnabled === false && value === true) ? (
                    <span>
                        Software token not registered
                        <ResetMFAButton record={record}/>
                    </span>
                ) : value === true ? (
                    <span>
                        Software token registered
                        <ResetMFAButton record={record}/>
                    </span>
                ) : (
                    <span>No</span>
                )                
            }),          
            ...[
                ...(!isTruthy(data.accountAdmin)
                    ? Object.keys(clientRoles.rows).length > 0
                        ? [
                              RoleAssignmentGridField(
                                  "clients",
                                  "Client Roles",
                                  "Client Name",
                                  5,
                                  clientRoles,
                                  ROLES,
                                  setClientRoles,
                                  true,
                                  true
                              )
                          ]
                        : [LabelField("clients", "Client Roles", "This account has no clients")]
                    : ([] as IField[]))
            ]
        ];
    }, [data, clientRoles, ResetMFAButton]);

    const actions: EditorAction[] = useMemo(
        () => [
            {
                action: "toggle",
                label: data.state === "disabled" ? "Enable" : "Disable",
                validate: false,
                handler: () => onAction(data.state === "disabled" ? "enable" : "disable", data as IUser)
            },
            {
                action: "edit",
                label: "Edit",
                validate: false,
                handler: () => onAction("edit", data as IUser)
            },
            {
                action: "delete",
                label: "Delete",
                validate: false,
                enabled: data.state === "disabled" && data.accountAdmin !== "true",
                tooltip:
                    data.state !== "disabled"
                        ? "User must be disabled before deleting"
                        : data.accountAdmin === "true"
                        ? "You cannot delete the Super Admin of an account"
                        : undefined,
                props: { danger: true },
                handler: () => onAction("delete", data as IUser)
            },
            {
                action: "cancel",
                label: "Cancel",
                validate: false,
                handler: () => onAction("cancel", data as IUser)
            }
        ],
        [onAction, data]
    );

    return Editor({
        ...props,
        title: (
            <div style={{ paddingRight: 20 }}>
                <span>{data.username}</span>
            </div>
        ),
        fields,
        newRecord,
        actions,
        isLoading: false,
        width: 750
    });
};

export default UserDetails;