import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../utils/axiosInstance";

export const reportsApi = createApi({
    reducerPath: "reports",
    baseQuery: axiosBaseQuery(),
    endpoints: builder => ({
        getSystemOptions: builder.query({
            query: (data: { clientId: string; scope: string }) => ({
                url: `/client/reports/${data.clientId}/${data.scope.toLowerCase()}`,
                method: "GET",
                data
            })
        }),
        getLeaseNumbers: builder.query({
            query: (data: { clientId: string; scope: string; fragment?: string }) => ({
                url: `/client/reports/${data.clientId}/${data.scope}/leasenumbers`,
                method: "POST",
                data: { pagesize: 50, page: 1, fragment: data.fragment }
            })
        })
    })
});

export const { useLazyGetLeaseNumbersQuery, useLazyGetSystemOptionsQuery } = reportsApi;
