import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../utils/axiosInstance";
import { IClient } from "../../shapes/multiclient";

export const clientApi = createApi({
    reducerPath: "clients",
    baseQuery: axiosBaseQuery(),
    tagTypes: ["client"],
    endpoints: builder => ({
        // get all clients (optional accountId filter)
        getClients: builder.query({
            query: ({ accountId }) => ({
                url: accountId ? `/clients/${accountId}` : "/clients",
                method: "GET"
            })
        }),
        // get the details for a specific client
        getClient: builder.query({
            query: ({ clientId }) => ({
                url: `/client/${clientId}`,
                method: "GET"
            }),
            async onQueryStarted(params, { dispatch, queryFulfilled }) {
                try {
                    const client = await queryFulfilled;
                    // update the cached account
                    dispatch(
                        clientApi.util.updateQueryData("getClients", {}, (clients: IClient[]) =>
                            clients.map(c => (c.clientId === client.data.clientId ? client.data : c))
                        )
                    );
                } catch (err: any) {
                    dispatch(clientApi.util.invalidateTags([{ type: "client", id: params.clientId }]));
                    console.log("clientApi.onQueryStarted", err);
                }
            },
            providesTags: (result, error) => (error ? [] : [{ type: "client", id: result.clientId }])
        })
    })
});

export const {
    useLazyGetClientsQuery,
    useLazyGetClientQuery,
    reducer
} = clientApi;
