import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../utils/axiosInstance";

export const dashboardApi = createApi({
    reducerPath: "dashboard",
    baseQuery: axiosBaseQuery(),
    endpoints: (builder) => ({
        getLeaseStatus: builder.query({
            query: (data:{clientId:string, scope:string}) => ({
                url: `/client/dashboard/${data.clientId}/statussummary/${data.scope.toLowerCase()}`,
                method: "GET",
                data
            }),
        }),
        getLeaseTotals: builder.query({
            query: (data:{clientId:string, scope:string}) => ({
                url: `/client/dashboard/${data.clientId}/leasetotals/${data.scope.toLowerCase()}`,
                method: "GET",
                data
            }),
        }),
        getDashboardSelectionList: builder.query({
            query: (data:{clientId:string, scope:string}) => ({
                url: `/client/dashboard/${data.clientId}/selectionlist/${data.scope.toLowerCase()}`,
                method: "GET",
                data
            }),
        }),	
        getNotification: builder.query({
            query: (data = {}) => ({
                url: `/client/dashboard/${data.clientId}/notifications/${data.scope.toLowerCase()}/${data.notificationType}`,
                method: "GET",
                data
            }),
        }),
        getRecentlyAddedLeases: builder.query({
            query: (data:{clientId:string, scope:string}) => ({
                url: `/client/dashboard/${data.clientId}/recentlyadded/${data.scope.toLowerCase()}`,
                method: "GET",
                data
            }),
        })
    }),
});

export const { useLazyGetLeaseStatusQuery, useLazyGetLeaseTotalsQuery, useLazyGetDashboardSelectionListQuery, useGetDashboardSelectionListQuery, useLazyGetNotificationQuery, useLazyGetRecentlyAddedLeasesQuery } = dashboardApi;
