import { FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";
import routes from "./routes";
import Layout from "../layout/Layout";
import Reports from "../pages/Portal/Reports";
import Templates from "../pages/Portal/Templates";
import ReportDetails from "../pages/Portal/Reports/Details";
import { Spin } from "antd";
import Dashboard from "../pages/Portal/Dashboard";
import UserManagement from "../pages/Portal/UserManagement";
import MainBreadcrumbs from "../layout/MainLayout/MainBreadcrumbs";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  signOut?: (data?:any|undefined) => void;
}

const Navigation: FunctionComponent<Props> = ({signOut}) => {
  const user = useSelector((state:RootState) => state.system.currentUser)
  const navigate = useNavigate();
  const [signedIn, setSignedIn] = useState(false);

  const renderHome = () => {
    if (!user)
      return <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Spin />
      </div>
      return <Dashboard />
  }

  useEffect(() => {
    if (user && !signedIn) {
      setSignedIn(true); 
      navigate(routes.home, { replace: true });
    } else if (!user) {
      setSignedIn(false);
    }
  }, [user, navigate, signedIn]);

  return (
    <MainBreadcrumbs.Provider>
      <Routes>
        {user ? (
          <>
            <Route path="/" element={<Layout signOut={signOut} />}>
              <Route index element={renderHome()} />
              <Route path={routes.home} element={renderHome()} />
              <Route path={routes.dashboard} element={renderHome()} />
              <Route path={routes.reports} element={<Reports />} />
              <Route path={routes.reportDetails} element={<ReportDetails />} />
              <Route path={routes.templates} element={<Templates />} />
              <Route path={routes.users} element={<UserManagement />} />
              <Route path={routes.settings} element={<div>Settings</div>} />
            </Route>
          </>
        ) : (
          <></>
        )}
      </Routes>
    </MainBreadcrumbs.Provider>
  );
};
export default Navigation;