import { FC, useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import Navigation from "./navigation";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "./redux/store";
import "../src/scss/_global.scss";
import { Amplify, Auth } from "aws-amplify";
import TermsOfService from "./pages/Portal/TermsOfService";
import '@aws-amplify/ui/dist/styles.css';
import { setError } from "./redux/slices/systemSlice";
import { MFA } from './components/MFA'
import LogRocket from "logrocket";
import { AuthContainer } from "./components/AuthContainer";

declare global {
    namespace JSX {
        interface IntrinsicElements {
            "amplify-toast": { children: Element; class: string; id: string };
        }
    }
}


export const App: FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const error = useSelector((state: RootState) => state.system.error);
    const user = useSelector((state: RootState) => state.system.currentUser);
    const [useFed, setUseFed] = useState<false | string | undefined>(false);
    const [configState, setConfigState] = useState<"UNINIT" | "INITING" | "INITED">("UNINIT");
    const [blocked, setBlocked] = useState<boolean>(false);
    useEffect(() => {
        if (process.env.USE_LOG_ROCKET) {
            LogRocket.init("fse7bb/ezlease");
        }
    }, []);

    useEffect(() => {
        let params = new URLSearchParams(document.location.search);
        let pError = params.get("error");
        if (pError === "invalid_request") {
            dispatch(setError({ error: "User does not have access to this application." }));
            setBlocked(true);
        }
    }, [dispatch, setError]);

    useEffect(() => {
        async function init() {
            const key = window.location.pathname.startsWith('/sso/') ? window.location.pathname : '/sso/default';
            const result = await fetch(`${process.env.REACT_APP_EZL_AMPL_APIENDPOINT}/client/auth${key}`);
            if (result.status === 200) {
                const json = await result.json();
                if (json.payload.oauth) {
                    json.payload.oauth.redirectSignIn = window.location.href.split(/[?#]/)[0];
                    json.payload.oauth.redirectSignOut = window.location.href.split(/[?#]/)[0];
                    setUseFed(json.payload.provider);
                } else if (window.location.pathname !== "/") {
                    window.location.assign("/");
                    return;
                }

                Amplify.configure({
                    Auth: {
                        ...json.payload,
                    },
                    API: {
                        endpoints: [
                            {
                                name: "EzLeaseApi",
                                endpoint: process.env.REACT_APP_EZL_AMPL_APIENDPOINT,
                                custom_header: async () => {
                                    return {
                                        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                                    };
                                }
                            }
                        ]
                    }
                });
                setConfigState("INITED");

            } else {
                dispatch(setError({ error: "Unable to communicate with server. Please try again." }));
            }
        }
        if (configState === "UNINIT") {
            setConfigState("INITING");
            init();
        }
    }, [dispatch, setUseFed, configState, setConfigState]);

    /**
     * Set the active user for log rocket when user changes
     */
    useEffect(() => {        
        if (user?.username) {
            if (process.env.USE_LOG_ROCKET) {
                LogRocket.identify(user.username);
            }
        }
    }, [user])

    function renderError() {
        return error ? <amplify-toast
            id="manual-error"
            data-test="authenticator-error"
            class="hydrated">
            <div className="toast">
                <span>{error}</span>
                <slot></slot>
            </div>
        </amplify-toast> : null;
    }

    const handleSignOut = () => {
        Auth.signOut();
    };

    return (
        <>
            {renderError()}
            {configState === "INITED" && !blocked ? <AuthContainer useFed={useFed}>
            <BrowserRouter>{user ? user.accountMFAEnabled ? <MFA signOut={handleSignOut}>
                    <TermsOfService signOut={handleSignOut}>
                        <Navigation signOut={handleSignOut} />
                    </TermsOfService></MFA> :
                    <TermsOfService signOut={handleSignOut}>
                        <Navigation signOut={handleSignOut} />
                    </TermsOfService> : null}</BrowserRouter>
            </AuthContainer> : null}
        </>
    );
};

