import { Col, Divider, Row } from "antd";
import TermsOfService from "./content";
import { AuthEventData } from "@aws-amplify/ui";

export interface TermsOfServiceNotice_Props {
  handleAgreeTosClick: () => void;
  handleLogoutClick?: (data?:AuthEventData|undefined) => void;
  buttonStyleOveride?: {};
  signOutButtonStyle?: {};
  containerStyleOveride?: {};
}

function TermsOfServiceNotice(props: TermsOfServiceNotice_Props) {
  const agreeButtonStyle = {
    ...props.buttonStyleOveride,
    backgroundColor: "#13294B",
    marginLeft: "auto",
  };

  return (
    <div style={props.containerStyleOveride}>
      <TermsOfService />
      <Divider
        dashed
        style={{ paddingTop: "12px", paddingBottom: "12px", margin: "0px" }}
      ></Divider>
      <Row justify="center">
        <Col md={12} style={{ display: "flex" }}>
          <div style={{ marginRight: "auto", height: "32px" }}>
            <button id="button-tos-signout"
              className=""
              style={props.signOutButtonStyle}
              onClick={(e) => {
                console.log('Logging out from Terms of Service');                
                props.handleLogoutClick!(undefined);
              }}
            >
              Click here to sign-out
            </button>
          </div>
        </Col>
        <Col md={12} style={{ display: "flex" }}>
          <button id="button-tos-agree"
            style={agreeButtonStyle}
            onClick={props.handleAgreeTosClick}
            className="ezleaseButton"
          >
            Click here to agree to our terms of service
          </button>
        </Col>
      </Row>
    </div>
  );
}

export default TermsOfServiceNotice;