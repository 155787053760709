import { Typography } from "antd";
import { TextProps } from "antd/lib/typography/Text";
import { FunctionComponent } from "react";
import { IBaseField, FieldTypes, IBaseFieldProps } from "./base";

export interface IFieldLabel extends IBaseField {
    type: FieldTypes.Label;
    text: string|((value:any) => string);
    textProps?: TextProps;
}

export interface ILabelProps extends IBaseFieldProps {
    field: IFieldLabel;
    text: string;
    value: any;
}

export const Label: FunctionComponent<ILabelProps> = ({ field, value }) => (
    <Typography.Text {...field.textProps}>{typeof field.text === 'string' ? field.text : field.text(value[field.fieldKey])}</Typography.Text>
);

export const LabelField = (fieldKey: string, label: string, text: string|((value:any) => string)) => ({
    fieldKey,
    label,
    text,
    type: FieldTypes.Label
});