import { Button as AntdButton, ButtonProps } from "antd";
import { FieldTypes, IBaseField, IBaseFieldProps } from "./base";
import { FunctionComponent } from "react";

export interface IFieldButton extends IBaseField {    
    buttonProps?: ButtonProps;
}

export const ButtonField = (buttonProps?: ButtonProps) => ({ type: FieldTypes.Button,  buttonProps: buttonProps });

export interface IButtonProps extends IBaseFieldProps {
    field: IFieldButton;
}
export const Button: FunctionComponent<IButtonProps> = ({ field }) => (
    <AntdButton id={`button-${field.fieldKey}`} {...(field.buttonProps || {})}>
        {field.label || field.fieldKey.pretty()}
    </AntdButton>
);
