import { FunctionComponent } from "react";
import { Col, Row, Table, Typography } from "antd";
import routes from "../../../navigation/routes";
import { generatePath, Link } from "react-router-dom";

interface OwnProps {
  // Add some types here
  temp?: any;
}

type Props = OwnProps;

const Reports: FunctionComponent<Props> = (props) => {
  const columns = [
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      render: (value: string, item: any) => {
        return <Link id={`anchor-report-${item.key}`} to={item.route}>{value}</Link>;
      },
    },
    { key: "description", title: "Description", dataIndex: "description" },
  ];
  const data = [
    {
      key: "auditTrail",
      name: "Audit Trail",
      route: generatePath(routes.reportDetails, { type: "auditTrail" }),
      description: "A sequential list of all changes to a lease",
    },
    {
      key: "currentRent",
      name: "Current Rent",
      route: generatePath(routes.reportDetails, { type: "currentRent" }),
      description: "Lists the current gross rent payment as of a specific date",
    },
  ];

  return (
    <Row>
      <Col>
        <Typography.Title level={4}>Instant Reports</Typography.Title>
        <Typography>
          We're adding more reports to Instant Reports. In the meantime, all
          other reports can be accessed by choosing "Manage My Leases" and
          clicking on the "Reports" menu.
        </Typography>
      </Col>
      <Col xs={24}>
        <Table columns={columns} dataSource={data} pagination={false} />
      </Col>
    </Row>
  );
};

export default Reports;
