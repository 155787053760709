import { FunctionComponent } from "react";
import { FieldErrors, FieldInputText, FieldType, FieldTypes } from "./type";
import {
    Col,
    DatePicker,
    Form,
    Input,
    InputProps,
    Row, Select,
    Typography,
} from "antd";

import FieldRadioGroup from "./FieldRadioGroup";
import TableSelect from "./TableSelect";
import { FormLayout } from "antd/lib/form/Form";
import { pretty } from "../../utils/inject";
import { SearchProps } from "antd/lib/input";

export type StateHandler = [any, (value: any) => void];

const { Option } = Select;

interface OwnProps {
    fields: FieldType[];
    state: StateHandler;
    errors: FieldErrors;
    layout?: FormLayout;
}

type Props = OwnProps;

type ValidateHandler = (fields: FieldType[], data: any) => FieldErrors;

const Forms: FunctionComponent<Props> & { validate: ValidateHandler } = ({
    fields,
    state,
    errors,
    layout = "vertical",
}) => {
    const [data, setData] = state;

    const renderField = (field: FieldType) => {
        if (field.type === FieldTypes.RadioGroup)
            return (
                <FieldRadioGroup id={`radiogroup-${field.key}`} field={field} options={field.options} state={state} />
            );
        if (field.type === FieldTypes.hidden) return <input id={`hidden-${field.key}`} type={"hidden"} />;
        else if (field.type === FieldTypes.Date) {
            if (field.mode === "range")
                return (
                    <DatePicker.RangePicker id={`rangepicker-${field.key}`}
                        value={data[field.key]}
                        onChange={(e) =>
                            setData((val: any) => ({ ...val, [field.key]: e }))
                        }
                    />
                );
            return (
                <DatePicker id={`datepicker-${field.key}`}
                    value={data[field.key]}
                    onChange={(e) => setData((val: any) => ({ ...val, [field.key]: e }))}
                />
            );
        } else if (field.type === FieldTypes.Label) {
            return (
                <Typography.Text {...field.textProps}>
                    {field.label || pretty(field.key)}
                </Typography.Text>
            );
        } 
        else if (field.type === FieldTypes.LabelWithValue) {
           
            return (
                <Typography.Text {...field.textProps}>
                     {(field.value || []).map(val => 
                         (val.label ))}
                </Typography.Text>
            );
        } 
        else if (field.type === FieldTypes.Select) {
            return (
                <Select id={`select-${field.key}`}
                    disabled={field.disabled}
                    className={field.className}
                    style={{ width: '100%', ...(field.style || {}) }}
                    value={data[field.key]}
                    onChange={(e) => setData((val: any) => ({ ...val, [field.key]: e }))}>
                    {(field.options || []).map(option => (
                        <Option key={`${field.key}-${typeof option === 'object' ? option.value : option}`} value={typeof option === 'object' ? option.value : option}>
                            {typeof option === 'object' ? (option.label || pretty(option.value)) : pretty(option)}
                        </Option>
                    ))}
                </Select>
            );
        } else if (field.type === FieldTypes.TableSelect) {
            return (
                <TableSelect
                    field={field}
                    value={data[field.key]}
                    onChange={(e) => {
                        let value = e;
                        if (typeof value?.length === "undefined")
                            value = [value].filter((e) => e);
                        setData((val: any) => ({
                            ...val,
                            [field.key]: (value || []).map((e: any) => e.key),
                        }));
                    }}
                />
            );
        } else {
            const _field = field as FieldInputText;
            const props: InputProps|SearchProps = {
                value: data[_field.key],
                onChange: (e) =>
                    setData((val: any) => ({ ...val, [field.key]: e.target.value })),
                ...(_field.inputProps || {}),
            };
            if (_field.mode === "search") return <Input.Search id={`search-${field.key}`} {...props} />;
            return <Input id={`input-${field.key}`} {...props} />;
        }
    };
    return (
        <Form layout={layout}>
            <Row gutter={6} align="middle">
                {fields.map((field) => {
                    const error = errors[field.key]
                    return (
                        <Col {...(field.colProps || {})} key={field.key}>
                            {FieldTypes.Label === field.type ? (
                                renderField(field)
                            ) : (
                                <Form.Item
                                    style={field.containerStyle}
                                    validateStatus={error ? "error" : undefined}
                                    help={error}
                                    label={
                                        <>
                                            {field.isRequired ? (
                                                <small style={{ marginRight: 3 }}>
                                                    <Typography.Text type={"danger"}>*</Typography.Text>
                                                </small>
                                            ) : null}
                                            {field.label || pretty(field.key)}
                                        </>
                                    }
                                    hidden={FieldTypes.hidden === field.type}
                                >
                                    {renderField(field)}
                                </Form.Item>
                            )}
                        </Col>
                    );
                })}
            </Row>
        </Form>
    );
};

Forms.validate = (fields, data) => {
    const errors: FieldErrors = {};
    fields.forEach((field) => {
        if (field.isRequired && !data[field.key]) {
            errors[field.key] = `${field.label || pretty(field.key)} is required.`
        }
    });
    return errors;
};

export default Forms;
