import { Authenticator, useTheme, Image, Flex, Text, Heading } from "@aws-amplify/ui-react";

import { FunctionComponent } from "react";

type Props = {
    children?: any;
}

export function Header() {
    const { tokens } = useTheme();

    return (
        <Image
            alt="logo"
            src="./EZLease_Logo.svg"
            maxWidth={200}
            padding={tokens.space.medium}
        />
    );
}

export function Footer() {
    const { tokens } = useTheme();

    return (
        <Flex
            justifyContent="center"
            padding={tokens.space.medium}>
            <Text>&copy; All Rights Reserved</Text>
        </Flex>
    );
}

export function SignInHeader() {
    const { tokens } = useTheme();

    return (
        <Heading level={3} padding={`${tokens.space.xl} ${tokens.space.xl} 0`}>
            Sign in
        </Heading>
    );
}

export function OTPHeader() {
    const { tokens } = useTheme();
    return (
        <div >
            <Heading level={3} marginBottom={tokens.space.medium}>
                Verify your identity
            </Heading>
            <p>
                Enter the verification code generated by your authenticator app.
            </p>
        </div>
    );
}

export const SRP: FunctionComponent<Props> = ({ children }) => {
    return <Authenticator variation="default" className="ezlease-authenticator" components={{
        Header: Header,
        Footer: Footer,
        SignIn: {
            Header: SignInHeader
        },
        ConfirmSignIn: {
            Header: OTPHeader,
        }
    }} hideSignUp>
        {children}
    </Authenticator>
};