export function pretty(txt:string) {
    return txt
        .split("_")
        .join(" ")
        .split("-")
        .join(" ")
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        //.replace(/([A-Z])([a-z])/g, " $1$2")
        .replace(/ +/g, " ")
        .replace(/^./, function (str) {
            return str.toUpperCase();
        });
};

export {};
