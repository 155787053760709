const routes = {
  portal: "/portal",
  home: "/home",
  dashboard:"/dashboard",
  reports: "/instant-reports",
  reportDetails: "/instant-reports/:type",
  templates: "/templates",
  settings: "/settings",
  users:"user-management",
  login: "/login",
};

export default routes;
