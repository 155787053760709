import { /* Table, */ Typography } from "antd";
import { MouseEventHandler } from "react";
import { getStreamingInstance } from "../redux/slices/systemSlice";
const { Title, Paragraph } = Typography;
const titleStyle = { fontSize: "16px" };

const openOldVersionApp:MouseEventHandler<HTMLAnchorElement> = async (e) => {
    e.preventDefault();
    try {
        const params: any = {};
        params.useOld = true;
        const uri = await getStreamingInstance(true);
        window.open(uri, "_blank");
    } catch (err: any) {
        alert(err.message);
    }
};

//const dataSource: any[] = [];

/*const columns = [
    {
        title: "Scenario and impact of update",
        dataIndex: "scenario",
        key: "scenario",
        render: (
            text: string,
            row: { scenario: { scenario: string; impact: string; correction: string; }; },
            index: number
        ) => (
            <>
                <span style={{ fontWeight: "bold" }}>Scenario:</span> {row.scenario.scenario}
                <br />
                <br />
                <span style={{ fontWeight: "bold" }}>Impact:</span> {row.scenario.impact}
                <br />
                <br />
                <span style={{ fontWeight: "bold" }}>Correction entry:</span> {row.scenario.correction}
            </>
        ),
        width: 300
    },
    {
        title: "ASC 842",
        dataIndex: "asc842",
        key: "asc842",
        width: 55
    },
    {
        title: "IFRS 16",
        dataIndex: "ifrs16",
        key: "ifrs16",
        width: 50
    },
    {
        title: "GASB 87",
        dataIndex: "gasb87",
        key: "gasb87",
        width: 55
    },
    {
        title: "GASB 96",
        dataIndex: "gasb96",
        key: "gasb96",
        width: 55
    },
    {
        title: "Lessee",
        dataIndex: "lessee",
        key: "lessee",
        width: 40
    },
    {
        title: "Lessor",
        dataIndex: "lessor",
        key: "lessor",
        width: 40
    },
    {
        title: "ARO",
        dataIndex: "aro",
        key: "aro",
        width: 30
    }
];*/

function WhatsNewPanel() {
    return (
        <div>
            <Typography>
                <Title style={titleStyle}>March 2024 Update</Title>
                <Title style={titleStyle}>Enhancements</Title>
                <Title style={titleStyle}>Multi-Factor Authentication (MFA):</Title>
                <Paragraph>
                    EZLease now supports MFA, which adds an additional layer of security to your account by requiring
                    users to enter a password and a one-time MFA token to log in. Once MFA is enabled for an account,
                    users will be prompted to register an authentication app on their next login. <a
                        href="https://support.ezlease.com/hc/en-us/articles/22701535056155-Multi-Factor-Authentication-MFA"
                        target="_blank"
                        rel="noreferrer">Learn more
                    </a>.
                </Paragraph>
                <Title style={titleStyle}>Locking Periods:</Title>
                <Paragraph>
                    Prevent users from mistakenly booking changes in previous accounting periods by closing, or locking,
                    those periods. When a period is locked, users must book changes in the current or future periods, and
                    EZLease will automatically generate catch-up accounting entries in the open period. <a
                        href="https://support.ezlease.com/hc/en-us/articles/22701146960027-Locking-Periods"
                        target="_blank"
                        rel="noreferrer">Learn more
                    </a>.
                </Paragraph>
                <Title style={titleStyle}>Additional enhancements:</Title>
                <Paragraph>
                    <ul>
                        <li>Login page works with password managers for automatic username/password entry.</li>
                        <li>Ability to hide ARO functionality for users who do not leverage ARO accounting. To hide ARO functionality, navigate to File → System Options → ARO → uncheck “Enable ARO Accounting”</li>
                        <li>Support for manufacturer/dealer lessor accounting with COGS/revenue for new leases as per ASC 842-30-45-4 and IFRS paragraph 71.</li>
                        <li>Removed “Ownership transfer” checkbox from the Inception tab when EZLease is set to GASB accounting.</li>
                        <li>Increased maximum report size EZLease can create.</li>
                    </ul>
                </Paragraph>
                <Title style={titleStyle}>Accounting updates</Title>
                <Paragraph>
                    The March 2023 update includes accounting updates to improve accounting accuracy for various scenarios. View a <a
                        href="https://support.ezlease.com/hc/en-us/articles/23505119031707-Release-9-4-March-2024"
                        target="_blank"
                        rel="noreferrer"
                    >complete list of affected scenarios here</a>.
                </Paragraph>
                <Paragraph>
                    If you believe your leases are affected by one of the scenarios listed below, you can <a
                        href="#nowhere"
                        onClick={openOldVersionApp}
                        target="_blank"
                        rel="noreferrer">
                        open the previous EZLease version
                    </a>  and re-run your reporting outputs for direct comparison
                    to the new version. We encourage customers to leverage the <a
                        href="https://support.ezlease.com/hc/en-us/articles/8788964098843-Account-Reconciliation-Template"
                        target="_blank"
                        rel="noreferrer"
                    >Account Reconciliation Template</a> to make this process easier. The template allows you to directly compare changes in journals between different versions of EZLease, or EZLease and your ERP.
                </Paragraph>
                <Paragraph>
                    <strong>Note:</strong> <em>You should only use previous versions of EZLease to test reporting outputs. You
                        should not add/edit leases, or modify system options while using a previous version of
                        EZLease.</em>
                </Paragraph>
            </Typography>
        </div>
    );
}

export default WhatsNewPanel;
