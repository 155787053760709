export const templates = [
  {
    Name: "Bulk Leases (Lessee)",
    Description: "Bulk lease upload for lessees.",
    Path: "https://ezlease-product-public-templates.s3.amazonaws.com/EZ_ImportTemplate_Lessee.xlsx",
  },
  {
    Name: "Bulk Leases (Lessor)",
    Description: "Bulk lease upload for lessors.",
    Path: "https://ezlease-product-public-templates.s3.amazonaws.com/EZ_ImportTemplate_Lessor.xlsx",
  },
  {
    Name: "Bulk Variable Lease Payments",
    Description: "Variable payments (Lessee/Lessor)",
    Path: "https://ezlease-product-public-templates.s3.amazonaws.com/EZ_Import_BulkVariableLeasePayments.xlsx",
  },
  {
    Name: "Bulk Classification",
    Description:
      "Information needed to transition leases from old to new standard (transition IBR, etc.)",
    Path: "https://ezlease-product-public-templates.s3.amazonaws.com/EZ_Import_BulkClassification.xlsx",
  },
  {
    Name: "Bulk Executory Costs",
    Description:
      "Executory costs for FAS 13 operating leases transitioning to ASC 842",
    Path: "https://ezlease-product-public-templates.s3.amazonaws.com/EZ_Import_BulkExecCosts.xlsx",
  },
  {
    Name: "Bulk ARO",
    Description: "ARO upload template",
    Path: "https://ezlease-product-public-templates.s3.amazonaws.com/EZ_Import_BulkARO.xlsx",
  },
  {
    Name: "Bulk Acquisition",
    Description: "Bulk update lease information related for acquisitions",
    Path: "https://ezlease-product-public-templates.s3.amazonaws.com/EZ_Import_BulkAcquisition.xlsx",
  },
  {
    Name: "Bulk SBITAs",
    Description: "Upload SBITA contracts for GASB 96 compliance",
    Path: "https://ezlease-product-public-templates.s3.amazonaws.com/EZ_ImportTemplate_SBITA.xlsx",
  },
];
