import { SearchOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import { ColumnType } from "antd/lib/table";

export function tableColumnTextFilterConfig<T>(): ColumnType<T> {
    const searchInputHolder: { current: any } = { current: null };

    return {
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input className='input-filter-term'
                    ref={(node) => {
                        searchInputHolder.current = node;
                    }}
                    placeholder="Search"
                    value={selectedKeys[0] ? selectedKeys[0].toString() : ""}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value.toString()] : [])
                    }                    
                    onPressEnter={() => confirm()}
                    style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <Button className="button-filter-search"
                    type="primary"
                    onClick={() => confirm()}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button className="button-filter-reset" size="small" style={{ width: 90 }} onClick={() => {
                    setSelectedKeys([]);
                    confirm();
                    clearFilters && clearFilters();
                }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined className="icon-filter-search" style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInputHolder.current?.select());
            }
        },
    };
};