import { Checkbox } from "antd";
import { FunctionComponent } from "react";
import { IBaseField, FieldTypes, IBaseFieldProps } from "./base";

export interface ICheckbox {
    value: string;
    label?: string;
}

export interface IFieldCheckboxGroup extends IBaseField {
    type: FieldTypes.CheckboxGroup;
    options: ICheckbox[]
}

export interface ICheckGroupProps extends IBaseFieldProps {
    field: IFieldCheckboxGroup,
    value: any,
    onChange: (input:any) => void
}

export const CheckGroup:FunctionComponent<ICheckGroupProps> = ({field, value, onChange}) => (
    <Checkbox.Group
    options={(field.options || []).map(e => ({
        label: e.label || e.value.pretty(),
        value: e.value
    }))}
    disabled={field.disabled}
    defaultValue={[]}
    value={value[field.fieldKey]}
    onChange={e => {
        onChange((val: any) => ({ ...val, [field.fieldKey]: e }));
    }} />
);