import { FunctionComponent } from "react";
import { Outlet } from "react-router-dom";
import styles from "./styles.module.scss";
import HeaderLayout from "../Header";
import NavBar from "../Navbar";
import { Card, Col, Row, Typography } from "antd";
import MainBreadcrumbs, { useMainBreadcrumbs } from "../MainLayout/MainBreadcrumbs";
import versions from '../../data/versions.json';
import { AuthEventData } from "@aws-amplify/ui";

interface Props {
    signOut?: (data?:AuthEventData|undefined) => void;

}

const Layout: FunctionComponent<Props> = ({signOut}) => {
    const { breadcrumbs } = useMainBreadcrumbs();

    return (
        <div className={styles.container}>
            <div className={styles.headerLayoutContainer}>
                <HeaderLayout containerClassName={styles.pageHeader} signOut={signOut}/>
                <div style={{ paddingLeft: 6, paddingTop: 10 }}>
                    <NavBar hasLogo={false} />
                </div>
            </div>
            <div className={styles.contentContainer}>
                <Card
                    title={breadcrumbs.length > 1 ? <MainBreadcrumbs /> : null}
                    bordered={false}
                    style={{ height: "100%" }}
                >
                    <div className={styles.contentWrapper}>
                        <Outlet />
                    </div>

                </Card>
            </div>
            <div className={styles.footerContainer}>
                <Row style={{ padding: "3px 27px" }}>
                    <Col flex={"auto"}>
                        <Typography.Text type={"secondary"}>EZLease V{versions.version}</Typography.Text>
                    </Col>
                    <Col flex={"auto"}>
                        <Typography.Text>
                            Have a question? Check out our <a href="https://support.ezlease.com/" target={"_blank"}
                                rel="noreferrer">Success Center</a>. | We
                            would love to hear from you! <a
                                href="https://forms.office.com/pages/responsepage.aspx?id=Q856p8VsZ0Owe0dQ95fPW6djIpX0929LoGFstzLZZHNUOEtMSFBOWEJUQTFKSkZSRDNMVDNGS1lLMy4u"
                                target={"_blank"} rel="noreferrer">Provide Feedback</a>.
                        </Typography.Text>
                    </Col>
                    <Col flex={"auto"} />
                </Row>
            </div>

        </div>
    );
};

export default Layout;