import React from "react";
import store from "./redux/store";
import { ThemeProvider } from "@aws-amplify/ui-react";
import { App } from "./App";
import "./utils/inject";
import { Provider } from "react-redux";

import { createRoot } from "react-dom/client";
const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <ThemeProvider>
                <App />
            </ThemeProvider>
        </Provider>
    </React.StrictMode>
);
