import { ReactElement } from "react";
import { tableColumnTextFilterConfig } from "./utilsComponents";
import moment from "moment";
import { AxiosResponse } from "axios";
import { Buffer } from "buffer";
import { pretty } from "./inject";


interface GeneratedColumnProps {
  key: string;
  title?: string | Element | ReactElement;
  render?: ((value: any, row?: any) => string | ReactElement) | null;
  sorter?: false | ((a: any, b: any) => boolean | number);
  onFilter?: false | ((value: any, record: any) => boolean);
  exportFieldType?: ExportFieldType;
  width?: number | string;
  className?: string;
  label?:string;
}

export const isTruthy = (value: any): boolean => {
  if (typeof value === 'string') {
      if (value.toLowerCase() === 'true') return true;
      if (value.toLowerCase() === 'false') return false;
  }
  return Boolean(value);
};  

export function isDateString(value: any) {
  return typeof value === 'string' && !isNaN(Date.parse(value));
}

export const dateSorter = (a: any, b: any, field: string) => {
  const aValue = a[field];
  const bValue = b[field];

  const aTimestamp = aValue ? new Date(aValue).getTime() : 0;
  const bTimestamp = bValue ? new Date(bValue).getTime() : 0;

  // check for undefined values and compare timestamps
  return (aValue === undefined && bValue === undefined) ? 0 :
         (aValue === undefined) ? 1 :
         (bValue === undefined) ? -1 :
         aTimestamp - bTimestamp;
};

export function searchDateTimeField(key: string, value: string, record: any) {
  const recordValue = record[key];

  if (isDateString(recordValue)) {
    // Format date to the "MMM DD, YYYY" and "hh:mm a" patterns
    const formattedDate = moment(recordValue).format("MMM DD YYYY hh mm a");

    // Split date and time components
    const dateTimeComponents = formattedDate.split(' ');

    // Check if value is a year
    if (Number(value) >= 1000 && Number(value) <= 9999) {
      const year = moment(recordValue).format("YYYY");
      if (year === value) {
        return true;
      }
    }

    // Check if value is a month number (01 - 12)
    if (Number(value) >= 1 && Number(value) <= 12) {
      const monthNumber = moment(recordValue).format("MM");
      const paddedValue = value.padStart(2, '0');
      if (monthNumber === paddedValue) {
        return true;
      }

      // Convert numeric month value to its abbreviated string representation
      const monthAsString = moment(recordValue).month(Number(paddedValue) - 1).format('MMM').toLowerCase();
      if (monthAsString === dateTimeComponents[0].toLowerCase()) {
        return true;
      }
    }

    // Check if value matches the day
    const day = dateTimeComponents[1].padStart(2, '0');
    if (value === day) {
      return true;
    }

    // Check for am or pm
    const meridiem = dateTimeComponents[5] // "a" for "am" or "pm"
    if (meridiem === value.toLowerCase()) {
      return true;
    }

    // Check if value is included in the formatted date string
    const lowerCasedFormattedDate = moment(recordValue).format("MMM DD, YYYY hh:mm a").toLowerCase();
    if (lowerCasedFormattedDate.includes(value.toLowerCase())) {
      return true;
    }
  }

  return false;
}

export type ExportFieldType = 'D' | 'N' | 'S';

export const generateColumn = (args: GeneratedColumnProps): any => {
  const { key, title, render, sorter, onFilter, exportFieldType, width, className, label } = args;

  const res = {
      title: title || pretty(key),
      dataIndex: key,
      key: key,
      exportFieldType: exportFieldType || "S",
      render: Boolean(render) ? render : (text: string) => text,
      ...tableColumnTextFilterConfig<any>(),
      width,
      className,
      label
  };
  if (sorter !== false) {
    res.sorter = sorter || ((a: any, b: any) => {
      const aValue = a[key];
      const bValue = b[key];
  
      // handling undefined values
      if (aValue === undefined && bValue === undefined) {
        return 0;
      } else if (aValue === undefined) {
        return 1;
      } else if (bValue === undefined) {
        return -1;
      } else {
        // extract numeric and non-numeric substrings from aValue and bValue
        const aMatches = typeof aValue === 'string' ? aValue.match(/(\d+(\.\d+)*|\D+)/g) || [] : [aValue];
        const bMatches = typeof bValue === 'string' ? bValue.match(/(\d+(\.\d+)*|\D+)/g) || [] : [bValue];
  
        for (let i = 0; i < Math.min(aMatches.length, bMatches.length); i++) {
          const aPart = aMatches[i];
          const bPart = bMatches[i];
          
          const aNum = Number(aPart);
          const bNum = Number(bPart);         

          // compare if either part is a number or not
          if (!isNaN(aNum) || !isNaN(bNum)) {
            if (isNaN(aNum)) return 1;
            if (isNaN(bNum)) return -1;

            // numerical comparison
            if (aNum !== bNum) return aNum - bNum;
          }
          // string comparison
          else if (aPart.localeCompare(bPart) !== 0) {
            return aPart.localeCompare(bPart);
          }
        }
        return aMatches.length - bMatches.length;
      }
    });
  }
  if (onFilter !== false) {
    res.onFilter =
      onFilter ||
      ((value: any, record: any) => {
        const recordValue = record[key] || "";
        return (recordValue)
        .toString()
        .toLowerCase()
        .includes(value.toString().toLowerCase());
      });
  } else {
    res.filterIcon = null;
    res.filterDropdown = null;
  }
  return res;
};


export const processDownload = (response: AxiosResponse) => {
  const byteCharacters = atob(response.data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  const blob = new Blob([byteArray], { type: "application/octet-stream" });

  // Amplify is not surfacing the content-disposition headers, hardcode for now. We should be able to look at response.headers['content-disposition'] for the filename
  //const filename = 'AuditTrailReport.xlsx';
  const filename = response.headers["content-disposition"].split('"')[1];

  const blobURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement("a");
  tempLink.style.display = "none";
  tempLink.href = blobURL;
  tempLink.setAttribute("download", filename);

  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (typeof tempLink.download === "undefined") {
    tempLink.setAttribute("target", "_blank");
  }

  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
  window.URL.revokeObjectURL(blobURL);
};

export const processExport = (response: AxiosResponse) => {
  const { data, headers } = response;
  let buff = Buffer.from(data, 'base64');
  const blob = new Blob([buff], { type: "application/octet-stream" });

  // Amplify is not surfacing the content-disposition headers, hardcode for now. We should be able to look at response.headers['content-disposition'] for the filename
  //const filename = 'AuditTrailReport.xlsx';
  const filename = headers["content-disposition"].split('"')[1];

  const blobURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement("a");
  tempLink.style.display = "none";
  tempLink.href = blobURL;
  tempLink.setAttribute("download", filename);

  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (typeof tempLink.download === "undefined") {
    tempLink.setAttribute("target", "_blank");
  }

  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
  window.URL.revokeObjectURL(blobURL);
};

const delay = (time?: number) => new Promise(res => setTimeout(res, time || 1000))

const utils = {
  processDownload,
  processExport,
  delay
};

export default utils;
