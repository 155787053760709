import { FunctionComponent, useEffect, useMemo } from 'react';
import { Card, Typography, Spin } from "antd";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartData, Plugin } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { useLazyGetLeaseStatusQuery } from '../../../redux/actions/dashboard';

ChartJS.register(ArcElement, Tooltip, Legend);

interface OwnProps {
    clientId?: string | null;
    scope?: string;
}

const { Title } = Typography

type Props = OwnProps;

const ActiveAndExpiredLeases: FunctionComponent<Props> = ({ clientId, scope }) => {
    const [getLeaseStatus, leaseStatus] = useLazyGetLeaseStatusQuery();

    useEffect(() => {
        if (scope && clientId) {
            getLeaseStatus({ scope: scope, clientId: clientId })
        }
    }, [clientId, scope, getLeaseStatus])


    const lease = useMemo(() => {
        return leaseStatus?.data?.statuses || []
    }, [leaseStatus?.data?.statuses])

    const total = useMemo(() => {
        return leaseStatus?.data?.totalLeases
    }, [leaseStatus?.data?.totalLeases])

    const plugins = useMemo<Plugin<"doughnut">[]>(() => {
        return [{
            id: 'test',
            beforeDraw: function (chart: ChartJS) {
                if (lease.length === 0)
                    return false;
                var width = chart.width,
                    height = chart.height,
                    ctx = chart.ctx;
                ctx.restore();
                var fontSize = (height / 180).toFixed(2);
                ctx.font = fontSize + "em sans-serif";
                ctx.textBaseline = "top";

                var text = total,
                    textX = Math.round((width - ctx.measureText(text).width) / 2),
                    textY = height / 2.75;
                ctx.fillText(text, textX, textY);
                fontSize = (height / 300).toFixed(2);
                ctx.font = fontSize + "em sans-serif";
                var text2 = "Total Leases",
                    text2X = Math.round((width - ctx.measureText(text).width) / 2.55),
                    text2Y = height / 2.25;
                ctx.fillText(text2, text2X, text2Y);
                ctx.save();
            }
        }]
    }, [lease, total]);

    const data: ChartData<"doughnut"> = useMemo(() => {
        let leaseCopy = [...lease];
        let activeLease = leaseCopy.find((e: any) => e.status === "Active");
        
        if (!activeLease) {
            activeLease = { status: "Active", leases: 0 };
            leaseCopy.push(activeLease);
        }
    
        return {
            labels: leaseCopy.map((e: any) => e.status + "(" + e.leases + ")"),
            datasets: [
                {
                    label: '# of Votes',
                    data: leaseCopy.map((e: any) => parseInt(e.leases)),
                    backgroundColor: [
                        '#7C9FD6',
                        '#13294B',
                    ],
                    borderColor: [
                        '#7C9FD6',
                        '#13294B',
                    ],
                    borderWidth: 1,
                },
            ],
        }
    }, [lease])

    return (
        <Card bodyStyle={{ paddingTop: 22 }} style={{ height: "490px", display: 'flex', flexDirection: 'column' }}>
            <Title level={5}>
                Active and Expired Leases
            </Title>
            {leaseStatus.isFetching || leaseStatus.status === 'pending' ? 
            <div style={{ paddingTop: "200px",flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Spin />
            </div>
            :
            clientId && scope ? 
                (lease.length > 0 ? 
                    <Doughnut
                        key={total}
                        data={data}
                        plugins={plugins}
                        options={{
                            rotation: 90,
                            plugins: {
                                legend: { position: 'bottom', labels: { boxWidth: 12 } },
                            },
                        }} 
                    /> : <div  style={{ paddingTop: "200px",flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>No leases to display.</div>
                )
                : <div  style={{ paddingTop: "200px",flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Please select company and scope.</div>
            }
        </Card>
    );    
};

export default ActiveAndExpiredLeases;
