import React, { FunctionComponent } from "react";
import TermsOfServiceNotice from "./TermsOfServiceNotice";
import EnableSubscriptionNotice from "../../../components/EnableSubscriptionNotice";
import styles from "./styles.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { setTosAcceptedAsync } from "../../../redux/slices/systemSlice";
import { AuthEventData } from "@aws-amplify/ui";

type Props = {
    children?: any;
    signOut?: (data?:AuthEventData|undefined) => void;
};

const buttonStyle = {
    backgroundColor: "#E22961",
    color: "white",
    padding: 10
} as React.CSSProperties;

const launcherStyle = {
    backgroundColor: "#fff",
    padding: "10px"
} as React.CSSProperties;

const signOutButtonStyle = {
    ...buttonStyle,
    backgroundColor: "#13294B",
    cursor: "pointer"
} as React.CSSProperties;

const TermsOfService: FunctionComponent<Props> = ({ children, signOut }) => {
    const dispatch = useDispatch<AppDispatch>();
    const user = useSelector((state: RootState) => state.system.currentUser);

    const confirmToSAccepted = () => {
        dispatch(setTosAcceptedAsync());
    };

    if (!user) {
        return null;
    } else if (!user.tosAccepted) {
        return (
            <div className={styles.contentContainer}>
                <div style={{ boxShadow: "grey 0.9px 0.9px 5px" }}>
                    <TermsOfServiceNotice
                        handleAgreeTosClick={confirmToSAccepted}
                        handleLogoutClick={signOut}
                        buttonStyleOveride={buttonStyle}
                        containerStyleOveride={launcherStyle}
                        signOutButtonStyle={signOutButtonStyle}
                    />
                </div>
            </div>
        );
    } else if (Object.keys(user.clients).length < 1) {
        return (
            <EnableSubscriptionNotice
                handleLogoutClick={signOut}
                containerStyleOveride={launcherStyle}
                buttonStyleOveride={buttonStyle}
            />
        );
    }
    return children;
};

export default TermsOfService;
