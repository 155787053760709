import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, TableProps, Row, Col, notification, TablePaginationConfig, Table, Tooltip } from "antd";
import utils, { ExportFieldType, generateColumn } from "../utils";
import axiosInstance from "../utils/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { FilterValue, GetRowKey, SorterResult } from "antd/lib/table/interface";
import { ITableState, TableKey, addPin, removePin, setTableState } from "../redux/slices/systemSlice";
import { RootState } from "../redux/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbTack } from "@fortawesome/free-solid-svg-icons";

interface ExportConfig {
    baseFileName: string;
    includedAttributes: string[];
    mainTabName: string;
    columns?: any[];
}

interface WithCustomTableProps<T> extends TableProps<T> {
    additionalButtons?: React.ReactNode[];
    exportList?: boolean;
    exportConfig?: ExportConfig;
    dataSource: T[];
    filteredFields?: { [key: string]: string | null };
    table: TableKey;
    pageSizeOptions?: number[] | string[];
    recordType: string;
    rowClicked?: (record: T) => Promise<void>;
    rowKey: string | GetRowKey<T>;
}

type ColumnType = "D" | "N" | "S";

interface ColumnHeader {
    type: ColumnType;
    label: string;
}

interface PostableObject {
    data: Array<Record<string, null | { value: any; type: "D" | "S" | "N" }>>;
    config: {
        filename: string;
        columns: ColumnHeader[];
        mainTabName: string;
        pins:string[];
        values: { [key: string]: string | null };
    };
}

//formatting for filter object, removing empty values
const cleanFilterObject = (obj: { [key: string]: string | null }) => {
    const resultObject: { [key: string]: string } = {};
    for (const [key, value] of Object.entries(obj)) {
        if (value) {
            resultObject[key] = value;
        }
    }

    return resultObject;
};

const getField = (key: string, columns?: any): any | undefined => {
    return columns ? columns.find((c: any) => c.key === key) : undefined;
};

// returns the data type of a value
const getFieldType = (key: string, columns?: any[]): ExportFieldType => {
    const field = getField(key, columns);

    return field?.exportFieldType || "S";
};

const CustomFeatures: React.FC<WithCustomTableProps<any> & { pins:string[] }> = ({
    additionalButtons,
    exportList,
    exportConfig,
    dataSource,
    filteredFields,
    columns,
    pins
}) => {
    const [downloading, setDownloading] = useState(false);

    const download = async (post: any) => {
        setDownloading(true);
        try {
            const response = await axiosInstance.post(`client/util/list`, post);
            utils.processDownload(response);
        } catch (err: any) {
            console.log("Error downloading", err);
            if (err.status === 422) {
                if (err.data.responseText) {
                    notification.error({ message: "Error", description: err.data.responseText });
                }
            } else {
                notification.error({
                    message: "Error",
                    description: "An unexpected error occurred generating the report, please try again."
                });
            }
        }
        setDownloading(false);
    };

    const generatePostableObject = ():PostableObject|undefined => {
        let includedAttributes = exportConfig?.includedAttributes || [];

        const dataToExport = dataSource.map(row => {
            if (includedAttributes.length < 1) {
                includedAttributes = Object.keys(row);
            }

            const processedRow: Record<string, { value: any; type: "D" | "S" | "N" }> = {};
            includedAttributes.forEach(attr => {
                if (Object.hasOwn(row, attr)) {
                    processedRow[attr] = { value: row[attr], type: getFieldType(attr, columns) };
                } else {
                    processedRow[attr] = { value: undefined, type: getFieldType(attr, columns) };
                }
            });

            return processedRow;
        });

        const columnHeaders: ColumnHeader[] = (
            includedAttributes.length > 0 ? includedAttributes : Object.keys(dataSource[0] || {})
        ).map(attr => {
            const fieldType = getFieldType(attr, exportConfig?.columns);
            return {
                type: fieldType,
                key: attr,
                label: getField(attr, exportConfig?.columns)?.label || getField(attr, exportConfig?.columns)?.title || attr
            };
        });

        if (exportConfig) {
            const { baseFileName, mainTabName } = exportConfig;

            const postObject: PostableObject = {
                data: dataToExport,
                config: {
                    filename: baseFileName,
                    columns: columnHeaders,
                    mainTabName: mainTabName,
                    pins: pins,
                    values: cleanFilterObject(filteredFields || {})
                }
            };

            return postObject;
        }
        return undefined;
    };

    const handleExportListClick = async () => {
        const postObject = generatePostableObject();
        await download(postObject);
    };

    return (
        <Row
            justify="space-between"
            align="middle"
            style={{ marginBottom: "10px" }}>
            <Col>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    {additionalButtons &&
                        additionalButtons.map((button, index) => (
                            <React.Fragment key={index}>
                                {button}
                                {additionalButtons.length - 1 !== index && <div style={{ marginRight: "20px" }} />}
                            </React.Fragment>
                        ))}
                </div>
            </Col>
            {exportList && (
                <Col>
                    <Button
                        onClick={handleExportListClick}
                        loading={downloading}>
                        Export List
                    </Button>
                </Col>
            )}
        </Row>
    );
};

const isInsideInteractiveElement = (element: HTMLElement | null): boolean => {
    if (!element) {
        return false;
    }
    const interactiveClasses = [
        'ant-dropdown-trigger',
        'ant-table-cell-actions',
        'ant-dropdown-menu-item',
        'pin',
        'actions',
        'ant-btn',
    ];
    // check if the element or its parents have any of the interactive classes
    for (const className of interactiveClasses) {
        if (element.closest(`.${className}`)) {
            return true;
        }
    }
    return false;
};

//private
const selectForTable = (table: TableKey, feature: string) => (state: RootState) =>
    (state.system as any)[feature][table];
export const withCustomTable = <T extends Record<string, any>>(
    WrappedTable: React.ComponentType<WithCustomTableProps<T>>
): React.FC<WithCustomTableProps<T>> => {
    return (props: WithCustomTableProps<T>) => {
        const [filteredFields, setFilteredFields] = useState<{ [key: string]: string | null }>({});
        const [currentData, setCurrentData] = useState<T[] | undefined>(undefined);
        const dispatch = useDispatch();
        const tableState: ITableState = useSelector(selectForTable(props.table, "tableStates"));
        const pins = useSelector(selectForTable(props.table, "pins")) as string[];

        const getKey = useCallback((record: T) =>
            typeof props.rowKey === "string" ? record[props.rowKey as string] : props.rowKey(record), [props]);

        const columns = useMemo(() => {
            const updatedColumns = props.columns!;
            updatedColumns.forEach(column => {
                column.filteredValue = column.key ? tableState.filters[column.key.toString()] || null : null;
                column.sortOrder = tableState.sort
                    ? tableState.sort?.key === column.key
                        ? tableState.sort!.order!
                        : null
                    : null;
                column.defaultSortOrder = column.sortOrder;
            });
            return updatedColumns;
        }, [props.columns, tableState.filters, tableState.sort]);

        const summaryColumns = useMemo(
            () => [
                generateColumn({
                    key: "pin",
                    title: "",
                    className: "pin",
                    render: (val: any, record: T) => (
                        <FontAwesomeIcon
                            icon={faThumbTack}
                            style={{ opacity: 0.8, cursor: "pointer" }}
                            onClick={() =>
                                dispatch(removePin({ table: props.table, value: getKey(record) }))
                            }></FontAwesomeIcon>
                    ),
                    width: 48,
                    sorter: false,
                    onFilter: false
                }),
                ...columns
            ],
            [columns, dispatch, getKey, props.table]
        );

        const tableColumns = useMemo(
            () => [
                generateColumn({
                    key: "pin",
                    title: "",
                    className: "pin",
                    render: (val: any, record: T) => {
                        if (pins.find((p: string) => p === getKey(record))) {
                            return (
                                <Tooltip title={`Unpin this ${props.recordType}`}>
                                    <FontAwesomeIcon
                                        icon={faThumbTack}
                                        style={{ opacity: 0.8, cursor: "pointer" }}
                                        onClick={() =>
                                            dispatch(removePin({ table: props.table, value: getKey(record) }))
                                        }></FontAwesomeIcon>
                                </Tooltip>
                            );
                        } else {
                            return (
                                <Tooltip title={`Pin this ${props.recordType}`}>
                                    <FontAwesomeIcon
                                        icon={faThumbTack}
                                        style={{ opacity: 0.2, cursor: "pointer", transform: "rotate(0.125turn)" }}
                                        onClick={() =>
                                            dispatch(addPin({ table: props.table, value: getKey(record) }))
                                        }></FontAwesomeIcon>
                                </Tooltip>
                            );
                        }
                    },
                    width: 48,
                    sorter: false,
                    onFilter: false
                }),
                ...columns
            ],
            [columns, dispatch, getKey, pins, props.recordType, props.table]
        );

        const fixedRecords = useMemo<T[]>(() => {
            return pins.length > 0 ? props.dataSource.filter(u => pins.indexOf(getKey(u)) >= 0) : [];
        }, [getKey, pins, props.dataSource]);

        const unfixedRecords = useMemo<T[]>(
            () => (pins.length < 1 ? props.dataSource : props.dataSource.filter((r: T) => pins.indexOf(getKey(r)) < 0)),
            [getKey, pins, props.dataSource]
        );

        const summary = useCallback(() => {
            return fixedRecords.length > 0 ? (
                <Table.Summary fixed={"top"}>
                    {fixedRecords.map(fixedRecord => (
                        <Table.Summary.Row key={`tsr_${getKey(fixedRecord)}`}>
                            {summaryColumns.map((c: any, i: number) => (
                                <Table.Summary.Cell
                                    key={c.key}
                                    index={i}>
                                    {c.key !== "action" && c.key !== "pin" ? (
                                        <div
                                            style={{ cursor: "pointer", height: "100%", width: "100%" }}
                                            onClick={
                                                props.rowClicked ? () => props.rowClicked!(fixedRecord) : undefined
                                            }>
                                            {c.render((fixedRecord as any)[c.key as string], fixedRecord)}
                                        </div>
                                    ) : (
                                        c.render((fixedRecord as any)[c.key as string], fixedRecord)
                                    )}
                                </Table.Summary.Cell>
                            ))}
                        </Table.Summary.Row>
                    ))}
                </Table.Summary>
            ) : undefined;
        }, [fixedRecords, getKey, props.rowClicked, summaryColumns]);

        const updateTableState = (
            pagination: TablePaginationConfig,
            filters: Record<string, FilterValue | null>,
            sorter: SorterResult<T> | SorterResult<T>[],
            extra: { action: "paginate" | "sort" | "filter"; currentDataSource: T[] }
        ) => {
            const draft = { ...tableState, filters };
            switch (extra.action) {
                case "paginate":
                    draft.currentPage = pagination.current || 1;
                    draft.pageSize = pagination.pageSize || 10;
                    break;
                case "sort":
                    if (sorter) {
                        const sort = sorter as SorterResult<T>;
                        draft.sort = {
                            key: sort.columnKey as string,
                            order: sort.order!
                        };
                    } else {
                        if (draft.sort) {
                            draft.sort = undefined;
                        }
                    }
                    break;
                case "filter":
                    draft.filters = filters;
                    break;
            }
            dispatch(setTableState({ table: props.table, tableState: draft }));

            const reducedFilters: { [key: string]: string | null } = {};
            for (const [key, value] of Object.entries(filters)) {
                if (value) {
                    reducedFilters[key] = value ? (value as string[])[0] : null;
                }
            }
            setCurrentData(extra.currentDataSource);
            setFilteredFields(reducedFilters);
        };

        useEffect(() => setCurrentData(unfixedRecords), [unfixedRecords]);

        return (
            <div style={{ padding: "10px 0" }}>
                <CustomFeatures
                    {...{ ...props, dataSource: [...fixedRecords, ...((currentData || []).filter((r:T) => pins.indexOf(getKey(r)) < 0))], pins: pins }}
                    filteredFields={filteredFields}
                />
                <WrappedTable
                    {...props}
                    dataSource={unfixedRecords}
                    onChange={updateTableState}
                    pagination={{
                        current: tableState.currentPage,
                        pageSize: tableState.pageSize,
                        showSizeChanger: true,
                        pageSizeOptions: props.pageSizeOptions || ["10", "25", "50", "100", "500", "1000"]
                    }}
                    columns={tableColumns}
                    summary={summary}
                    onRow={
                        props.rowClicked
                            ? (record: T) => {
                                  return {
                                      onClick: (event: React.MouseEvent) => {
                                          if (isInsideInteractiveElement(event.target as HTMLElement)) {
                                              return;
                                          }
                                          props.rowClicked!(record);
                                      },
                                      style: { cursor: "pointer" }
                                  };
                              }
                            : undefined
                    }
                />
            </div>
        );
    };
};
