import Input, { PasswordProps } from "antd/lib/input";
import { FunctionComponent } from "react";
import { IBaseValidationProps, IBaseField, FieldTypes, IBaseFieldProps } from "./base";

export interface IFieldInputPassword extends IBaseField {
    type: FieldTypes.InputPassword;
    passwordProps: PasswordProps;
    validationProps?: IBaseValidationProps;
}

export interface IPasswordProps extends IBaseFieldProps {
    field: IFieldInputPassword;
    value: any;
    onChange: (input: any) => void;
}

export const Password: FunctionComponent<IPasswordProps> = ({ field, value, onChange }) => (
    <Input.Password
        id={`password-${field.fieldKey}`}
        value={value[field.fieldKey]}
        onChange={(pwd: any) => onChange((current: any) => ({ ...current, [field.fieldKey]: pwd }))}
        {...(field.passwordProps || {})}
    />
);
