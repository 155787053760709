/* eslint-disable jsx-a11y/anchor-is-valid */
import { FunctionComponent, useState } from "react";
import { Button, Dropdown, Space, notification } from "antd";
import { useResendInviteMutation, useResetMFAMutation } from "../../../redux/actions/userApi";
import { DownOutlined } from "@ant-design/icons";
import { IUser } from "../../../shapes/multiclient";
import type { MenuProps } from "antd";
import PopconfirmHelper from "../../../components/PopconfirmHelper";
import LoadingModal from "../../../components/LoadingModal";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
interface IUserActionsProps {
    record: IUser;
    setSelectedUser: (user:IUser) => void;
    setEditorMode: (mode:"closed"|"edit"|"create") => void;
}

type Props = IUserActionsProps;

const UserActions: FunctionComponent<Props> = ({ record, setSelectedUser, setEditorMode }) => {
    const [resendInvite] = useResendInviteMutation();
    // progress modal for simple actions
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [resetMFA] = useResetMFAMutation();
    const currentUser: any = useSelector<RootState>(state => state.system.currentUser);

    // const actionButtonStyles = {
    //     fontWeight: 600,
    //     backgroundColor: "#3176C2",
    //     fontSize: "14px",
    //     borderRadius: "17px",
    //     margin: "10px",
    //     cancel: {
    //         fontWeight: 600,
    //         color: "#3176C2",
    //         backgroundColor: "transparent",
    //         border: "none",
    //         padding: 0,
    //         margin: "10px"
    //     }
    // };
   

    const resendAction = (
        <PopconfirmHelper
            onConfirm={() => resendInviteAction(record)}
            inLoadingOk={false}
            popconfirmTitle={
                <div style={{ maxWidth: 300 }}>
                    Resending the invite will email the user a new temporary password. Confirm you wish to proceed.
                </div>
            }
            label="Resend Invite"
            okButtonText="Resend Invite"
            cancelButtonText="Cancel"
        />
    );

    const resetAction = (
        <PopconfirmHelper
            onConfirm={() => resetMFAAction(record)}
            inLoadingOk={false}
            popconfirmTitle={
                <div style={{ maxWidth: 300 }}>
                    <p>
                        This will clear the registered MFA software token for user '
                        {record.username}'. The user will be forced to register a new MFA
                        software token on next login.
                    </p>
                    <p>
                        <strong>Reset the user's MFA?</strong>
                    </p>
                </div>
            }
            label="Reset MFA"
            okButtonText="Reset MFA"
            cancelButtonText="Cancel"
            okButtonProps={{danger:true}}
            cancelButtonProps={{danger:false}}
        />
    )

    const items: MenuProps["items"] = [
        ...(!record.usesFederation && record?.state === "invite sent"
            ? [
                  {
                      key: "resendAction",
                      label: resendAction
                  }
              ]
            : []),
            ...(!record.usesFederation && currentUser?.accountMFAEnabled === true &&  record.mfaEnabled === true ? [
                {
                    key: "resetAction",
                    label: resetAction
                }
            ] : [])            
    ];  

    const resendInviteAction = async (record: IUser) => {
        try {
            if (record.usesFederation) {
                notification.error({
                    message: "Error",
                    description: `Invite cannot be sent. This user is authenticated via a tbird-party SSO provider.`
                });
            }
            else if (record.state === "invite sent") {
                setModalVisible(true);
                await resendInvite(record);
            } else {
                notification.error({
                    message: "Error",
                    description: `Invite cannot be resent after the user has already logged in.`
                });
            }
        } catch (err: any) {
            const errorResponse = { success: false, error: err.message };
            throw errorResponse;
        } finally {
            setModalVisible(false);
        }
    };

    const resetMFAAction = async (record: IUser) => {
        try {
            if (record.usesFederation) {
                notification.error({
                    message: "Error",
                    description: `MFA cannot be reset. This user is authenticated via a tbird-party SSO provider.`
                });
            } else {
                setModalVisible(true);
                await resetMFA(record);
            }
        } catch (err: any) {
            const errorResponse = { success: false, error: err.message };
            throw errorResponse;
        } finally {
            setModalVisible(false);
        }
        };

    const moreActions = (
        <Space align="center">
            <Button
                id="button-action-edit-user"
                type="link"
                onClick={() => {
                    setSelectedUser(record);
                    setEditorMode("edit");
                }}>
                Edit
            </Button>
            {items.length > 0 ? <Dropdown menu={{ items }}
                trigger={["click"]}>
                <a onClick={e => e.preventDefault()}>
                    <Space>
                        More Actions
                        <DownOutlined />
                    </Space>
                </a>
            </Dropdown> : null}
        </Space>
    );

    return (
        <>
            {moreActions}
            <LoadingModal isOpen={modalVisible} />
        </>
    );
};

export default UserActions;
