import { FunctionComponent,useState, useEffect, useCallback, useMemo, useContext } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../redux/store";
import { setMFAEnabled } from "../redux/slices/systemSlice";
import QRCode from "react-qr-code";
import { TextField, Flex, Text, Grid, Button, Label, Heading } from "@aws-amplify/ui-react";
import { AuthContext } from "./AuthContainer";

type Props = {
    signOut?: (data?: any | undefined) => void;
    children?: any;
}

export const MFA: FunctionComponent<Props> = ({ signOut, children }) => {
    const user = useSelector((state: RootState) => state.system.currentUser);
    const { amplifyUser } = useContext(AuthContext);
    const [secret, setSecret] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [isVerifying, setIsVerifying] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const dispatch = useAppDispatch();

    const resetStateValues = () => {
        setVerificationCode('');
    };

    const tokenCode = useMemo(() => {
        if (user && secret) {
            return `otpauth://totp/EZLease Client:${user.username}?secret=${secret}&issuer=issuer`
        }
        return undefined;
    }, [secret, user])

    const renderSoftwareTokenRegistrationForm = useCallback(() => {
        return (
            <>            
            <div data-amplify-container style={{width: 480, margin: "auto"}}>
            <img className="amplify-image" alt="logo" src="./EZLease_Logo.svg" style={{ maxWidth: 200,  padding: "var(--amplify-space-medium)"}} />
            <Grid as="form" rowGap="15px" columnGap="15px" padding="20px" width="480px" margin="auto" border="1px solid #89949f">
                <Heading children="MFA Registration"></Heading>
                <Text children="Your organization requires an MFA software token registration to proceed"></Text>
                {tokenCode ? <QRCode value={tokenCode} style={{margin: "auto"}}></QRCode>:null}
                <p className="amplify-text amplify-field__description">Scan the QR code or copy and paste this token code into your authenticator app.</p>
                <div style={{maxWidth:400, wordBreak: "break-all"}}>{secret}</div>
                <TextField label="Verification Code" descriptiveText="Enter the code generated in your authenticator app to verify your software token." isRequired={true} type="number" step="any" value={verificationCode} onChange={e => setVerificationCode(e.target.value)}></TextField>
                <Label children={error} className="ant-form-item-explain-error" style={{color: "#ff4d4f"}}></Label>
                <Flex justifyContent="space-between">
                    <Button variation="warning" children="Clear" type="reset" onClick={(event) => {event.preventDefault(); resetStateValues();}}></Button>
                    <Button variation="destructive" children="Back to Sign In" type="button" onClick={(event) => {event.preventDefault(); signOut!();}}></Button>
                    <Flex gap="15px"><Button children="Verify Token" type="submit" variation="primary" isDisabled={!verificationCode || isVerifying} onClick={(e) => {
                        e.preventDefault();
                        setIsVerifying(true);
                        amplifyUser!.verifySoftwareToken(verificationCode, 'EZLease Software Token', {
                            onSuccess: async () => {
                                try {
                                    await dispatch(setMFAEnabled());                                    
                                } catch (err:any) {
                                    console.log('Verify err', err);
                                    setError(`Failed to enable MFA software token. Please try again.`)                                    
                                } finally {
                                    setIsVerifying(false);
                                }
                            },
                            onFailure: (err:any) => {
                                if (err.message === 'Code mismatch') {
                                    setError(`MFA software token verification code did not match. Please try again.`);    
                                } else {
                                    console.log('Verify err', err.message);
                                    setError(`MFA software token could not be verified. Please try again.`);
                                }
                                setIsVerifying(false);
                            }
                        });
                    }}></Button></Flex>
                </Flex>
            </Grid>
            </div>
            <div className="amplify-flex" style={{ justifyContent: "center", padding: "var(--amplify-space-medium)"}}><p className="amplify-text">© All Rights Reserved</p></div>
            </>
        );
    }, [amplifyUser, dispatch, error, isVerifying, secret, signOut, tokenCode, verificationCode]);

    useEffect(() => {
        if (amplifyUser && user && user.accountMFAEnabled === true) {
            if (user.mfaEnabled !== true) {
                amplifyUser.associateSoftwareToken({
                    associateSecretCode: (code:string) => {
                        setSecret(code);
                    },
                    onFailure: (err:any) => {
                        setError(`MFA software token could not be initialised. Please try again.`);
                    }
                })
            }
        }
    }, [amplifyUser, user]);

    return (
        user ? (
            user.usesFederation || user.mfaEnabled === true || user.accountMFAEnabled !== true ? (
                children
            ) : 
              (renderSoftwareTokenRegistrationForm())
        ) : null
    );
}

export default MFA;