import { Modal, Row, Col, Spin } from 'antd';
import { FunctionComponent } from 'react';

interface ILoadingModalProps {
    isOpen: boolean;
    titleText?: string;
    descriptionText?: string;
}

type Props = ILoadingModalProps;

const LoadingModal: FunctionComponent<Props> = ({ isOpen, titleText = "Processing", descriptionText = "Your request is being processed." }) => {
    return (
        <Modal
            visible={isOpen}
            closable={false}
            footer={null}
            centered
            maskClosable={false}
            maskStyle={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
            style={{ backgroundColor: "transparent", boxShadow: "none", border: "none" }}
        >
            <Row justify="center">
                <Col>
                    <Spin tip="Please Wait...">
                        <div
                            style={{ width: '100%', height: '100%', backgroundColor: 'transparent' }}
                        >
                            <h2>{titleText}</h2>
                            <p>{descriptionText}</p>
                        </div>
                    </Spin>
                </Col>
            </Row>
        </Modal>
    );
};

export default LoadingModal;