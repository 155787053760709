import { Radio, Space } from "antd";
import { FunctionComponent } from "react";
import { IBaseValidationProps, IBaseField, FieldTypes, IBaseFieldProps } from "./base";

export interface IRadioOption {
    value: string;
    label?: string;
}

export interface IFieldRadioGroup extends IBaseField {
    type: FieldTypes.RadioGroup;
    options: (IRadioOption | string)[];
    spaceDirection?: "vertical" | "horizontal";
    validationProps?: IBaseValidationProps;
}

export interface IRadioGroupProps extends IBaseFieldProps {
    field: IFieldRadioGroup;
    value: any;
    onChange: (input: any) => void;
}

export const RadioGroup: FunctionComponent<IRadioGroupProps> = ({ field, value, onChange }) => {
    return (
        <Radio.Group
            onChange={e => onChange((val: any) => ({ ...val, [field.fieldKey]: e.target.value }))}
            value={value[field.fieldKey]}>
            <Space direction={field.spaceDirection || "horizontal"}>
                {field.options.map(option => {
                    const isString = typeof option === "string";
                    return (
                        <Radio
                            value={typeof option === "string" ? option : option.value}
                            key={isString ? option : option.value}>
                            {isString ? option.pretty() : option.label || option?.value?.pretty()}
                        </Radio>
                    );
                })}
            </Space>
        </Radio.Group>
    );
};
