import { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Row, notification, Spin  } from "antd";
import { useParams } from "react-router-dom";
import Forms from "../../../../components/Forms";
import { FieldErrors, FieldType, FieldTypes } from "../../../../components/Forms/type";
import axiosInstance from "../../../../utils/axiosInstance";
import utils from "../../../../utils";
import { useLazyGetLeaseNumbersQuery, useLazyGetSystemOptionsQuery } from "../../../../redux/actions/reports";
import { pretty } from "../../../../utils/inject";
import { useSelector } from "react-redux";
import { RootState } from '../../../../redux/store';
import { GAAPStandard } from "../../../../utils/types";
import { Moment } from "moment";
import { SearchProps } from "antd/lib/input";

interface IFormState {
    scope:'lessee'|'lessor'|'sbita',
    reportDuration:'sinceLeaseCreation'|'dateRange',
    leaseSelectionMode: 'singleLease'|'allLeases',
    fragment:string,
    scopeOptions:{value:string,label?:string}[],
    range:Moment[],
    selectedLeases:string[]
}

const AuditTrail: FunctionComponent = () => {
    const params = useParams();
    const clientId = useSelector((state: RootState) => state.client?.clientId || '');
    const [data, setData] = useState<IFormState>({
        scope: "lessee",
        reportDuration: "sinceLeaseCreation",
        leaseSelectionMode: "allLeases",
        scopeOptions: [{ value: "lessee", label: "Lessee" }, { value: "lessor", label: "Lessor" }],
        fragment: '',
        selectedLeases: [],
        range: []
    });
    const [downloading, setDownloading] = useState<boolean>(false);
    const [errors, setErrors] = useState<FieldErrors>({});
    const [getLeaseNumbers, leaseNumbers] = useLazyGetLeaseNumbersQuery();
    const [getSystemOptions, systemOptions] = useLazyGetSystemOptionsQuery();
    const [searchFragment, setSearchFragment] = useState<string>('');
    const [currentLeaseNumbers, setCurrentLeaseNumbers] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    // reload system options when scope or clientId is changed
    useEffect(() => {
        if (clientId && data.scope) {
            getSystemOptions({ clientId, scope: data.scope });
        }
    }, [clientId, data.scope, getSystemOptions]);

    // reload lease numbers when scope or clientId or fragment is changed
    useEffect(() => {
        if (clientId && data.scope && searchFragment) {
            getLeaseNumbers({clientId, scope: data.scope, fragment: searchFragment });
        }
    }, [clientId, searchFragment, data.scope, getLeaseNumbers]); 

    // check for GASB on systemOptions load
    useEffect(() => {
        if (systemOptions.isSuccess && systemOptions.data) {
            setLoading(true);
            const isGAAPTypeGASB = systemOptions.data.enGAAPType === GAAPStandard.GASB
            setData((data: IFormState) => ({
                ...data,
                scopeOptions: isGAAPTypeGASB 
                    ? [{ value: "lessee", label: "Lessee" }, { value: "lessor", label: "Lessor" }, { value: "sbita", label: "SBITA" }]
                    : [{ value: "lessee", label: "Lessee" }, { value: "lessor", label: "Lessor" }]
            }));
        setTimeout(() => {
            setLoading(false);
        }, 2000);
        }
    }, [systemOptions,clientId])


    useEffect(() => {
        setData((draft:IFormState) => ({...draft, selectedLeases:[]}));
        setCurrentLeaseNumbers([]);
        if (data.leaseSelectionMode === 'singleLease') {
            getLeaseNumbers({clientId, scope: data.scope, fragment: searchFragment});
        }
    }, [clientId, data.leaseSelectionMode, data.scope, getLeaseNumbers, searchFragment]);

    useEffect(() => {
        if (data.reportDuration !== "dateRange") {
            setData((draft:IFormState) => ({ ...draft, range: [] }));
        }
    }, [data.reportDuration]);

    useEffect(() => {
        if (!leaseNumbers.isFetching && leaseNumbers.isSuccess && leaseNumbers.data) {
            setCurrentLeaseNumbers(leaseNumbers.data.map((i:any) => ({...i, key: i.leaseID})));
        }
    }, [leaseNumbers.isFetching, leaseNumbers.isSuccess, leaseNumbers.data])

    const fields = useMemo<FieldType[]>(() => {
        const res: (FieldType | undefined)[] = [
            {
                isRequired: true,
                type: FieldTypes.RadioGroup,
                key: "scope",
                label: "Lease type",
                options: data.scopeOptions,
                colProps: { xs: 24 }
            },
            {
                isRequired: true,
                type: FieldTypes.RadioGroup,
                key: "reportDuration",
                options: [{ value: "sinceLeaseCreation" }, { value: "dateRange" }]
            },
            data.reportDuration === "dateRange"
                ? {
                      isRequired: true,
                      type: FieldTypes.Date,
                      key: "range",
                      mode: "range",
                      colProps: { flex: "auto" }
                  }
                : undefined,
            { type: FieldTypes.hidden, key: "hidden", colProps: { xs: 24 } },
            {
                isRequired: true,
                type: FieldTypes.RadioGroup,
                key: "leaseSelectionMode",
                options: [{ value: "allLeases" }, { value: "singleLease" }]
            },
            data.leaseSelectionMode === "singleLease" 
                ? {
                    type: FieldTypes.inputText,
                    key: "fragment",
                    label: " ",
                    mode: "search",
                    inputProps: {
                        onChange: (e) => {
                            setData((draft) => ({...draft, fragment: (e.target as HTMLInputElement).value}))
                        },
                        onPressEnter: _ => {
                            if (searchFragment === data.fragment) {
                                getLeaseNumbers({clientId, scope: data.scope, fragment: searchFragment});
                            } else {
                                setSearchFragment(data.fragment || '')
                            }
                        },
                        onSearch: _ => { 
                            if (searchFragment === data.fragment) {
                                getLeaseNumbers({clientId, scope: data.scope, fragment: searchFragment});
                            } else {
                                setSearchFragment(data.fragment || '')
                            }
                        }
                    } as SearchProps
                }
                
                
                : undefined,
            data.leaseSelectionMode === "singleLease" 
                ? { type: FieldTypes.hidden, key: "hidden2", colProps: { xs: 24 } }
                : undefined,
            data.leaseSelectionMode === "singleLease"
                ? {
                    isRequired: true,
                    type: FieldTypes.TableSelect,
                    key: "selectedLeases",
                    label: "Lease",
                    dataSource: currentLeaseNumbers,
                    columns: ["leaseNumber", "description"],
                    colProps: { flex: "auto" },
                    tableProps: {
                        loading: leaseNumbers.isFetching
                    }
                }
                
                : undefined
        ];
        return res.filter(e => e) as FieldType[];
    }, [data.scopeOptions, data.reportDuration, data.leaseSelectionMode, data.fragment, data.scope, currentLeaseNumbers, leaseNumbers.isFetching, searchFragment, getLeaseNumbers, clientId]);

    const getLeaseNumber = useCallback((leaseId: string) => leaseId ? currentLeaseNumbers.find((item:any) => item.leaseID === leaseId)?.leaseNumber || "" : "", [currentLeaseNumbers]);

    const download = async () => {
        setDownloading(true);
        try {
            let newErrors:FieldErrors = {};
            if (data.reportDuration === 'dateRange' && data.range.length < 2) {
                newErrors.range = 'Select the date range for the report';
            }
            if (data.leaseSelectionMode === 'singleLease' && data.selectedLeases.length < 1) {
                newErrors.selectedLeases = 'Select the lease to report on';
            }

            newErrors = {...newErrors, ...Forms.validate(fields, data)}
           
            setErrors(newErrors);
            if (Object.keys(newErrors).length > 0) {
                setDownloading(false);
                return;
            }

            const startDateUtc = data.range.length > 0 ? data.range[0].set({hour:0, minute:0, second:0, millisecond: 0}).toDate().toISOString() : "";
            const endDateUtc = data.range.length > 1 ? data.range[1].set({hour:23, minute:59, second:59, millisecond: 999}).toDate().toISOString() : "";

            try {
                const response = await axiosInstance.post(`/client/reports/${clientId}/${data.scope}/audittrail`, {
                    clientId: clientId,
                    leaseId: (data.selectedLeases || [])[0] || "",
                    leaseNumber: getLeaseNumber((data.selectedLeases || [])[0] || ""),
                    scope: data.scope,
                    startDateUtc: startDateUtc,
                    endDateUtc: endDateUtc
                }, { 
                    suppressStatuses: [422]
                });
                utils.processDownload(response);
            } catch (err:any) {
                if (err.status === 422) {
                    if (err.data.responseText) {
                        notification.error({message: 'Error', description: err.data.responseText})
                    }                
                } else {
                    notification.error({message: 'Error', description: 'An unexpected error occurred generating the report, please try again.'})
                }
            }
        } catch (err: any) {
            if (err.errors) {
                setErrors(err.errors);
            } else {
                notification.error({
                    message: "Error",
                    description: err.message,
                });
            }
        }
        setDownloading(false);
    };

    return (
        <Row>
            <Col xs={24}>
            <Spin spinning={loading}>
                <Card title={pretty(params?.type ?? '')} style={{ width: "100%" }} type={"inner"} loading={systemOptions.isLoading}>
                    <Forms fields={fields} state={[data, setData]} errors={errors} />
                    <Row>
                        <Col xs={24}>
                            <Button
                                id="button-download-report"
                                type={"primary"}
                                onClick={download}
                                loading={downloading}>
                                Download Report
                            </Button>
                        </Col>
                    </Row>
                </Card>
                </Spin>
            </Col>
        </Row>
    );
};

export default AuditTrail;
