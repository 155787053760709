export interface IRole {
    code: string,
    label: string,
    requires: string[],
    removes:string[]
}

export const ROLES:IRole[] = [
    {
        code: 'none',
        label: 'None',
        requires:[],
        removes:['read','write','admin']
    },
    {
        code: 'read',
        label: 'View',
        requires:[],
        removes:['none', 'admin', 'write']
    },
    {
        code: 'write',
        label: 'Edit',
        requires: ['read'],
        removes:['none','admin']
    },
    {
        code: 'admin',
        label:'Admin',
        requires:['read', 'write'],
        removes:['none']
    }
];
