import { FunctionComponent } from "react";
import { FieldTypes, IBaseField, IBaseFieldProps } from "./base";

export interface IFieldHidden extends IBaseField {
    type: FieldTypes.Hidden;
}

export interface IHiddenProps extends IBaseFieldProps {
    field: IFieldHidden,
    value: any
}

export const Hidden:FunctionComponent<IHiddenProps> = ({field, value}) => <input id={`hidden-${field.fieldKey}`} type={"hidden"} value={value[field.fieldKey]} />;

