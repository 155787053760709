import { FunctionComponent, useEffect, useState, useMemo, useCallback } from "react";
import { IUser, User } from "../../../shapes/multiclient";
import { IField } from "../../../components/Forms-Admin";
import { TextField } from "../../../components/Forms-Admin/FieldTypes/Text";
import { KeyedRoleSet, RoleAssignmentGridField } from "../../../components/Forms-Admin/FieldTypes/RoleAssignmentGrid";
import { ROLES } from "../../../shapes/multiclient/IRole";
import { useUpdateUserMutation, useCreateUserMutation } from "../../../redux/actions/userApi";
import { isTruthy } from "../../../utils";
import Editor, { EditorAction, EditorRecordType, IEditorProps } from "../../../components/Editor";
import { Tooltip, notification } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { JSXField } from "../../../components/Forms-Admin/FieldTypes/JSX";
import { LabelField } from "../../../components/Forms-Admin/FieldTypes/Label";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

type UserEditorProps = {
    onAction: (action: string, data: IUser) => void;
};

interface IClientDetails {
    name: string;
    roles: string;
}

const UserEditor: FunctionComponent<
    Omit<IEditorProps, "title" | "fields" | "newRecord" | "actions"> & UserEditorProps
> = props => {
    const { state, mode, onAction } = props;
    const [data] = state;
    const [updateUser] = useUpdateUserMutation();
    const [createUser] = useCreateUserMutation();

    // state for tracking what roles are allocated to which users for post-client-save update
    const [clientRoles, setClientRoles] = useState<KeyedRoleSet>({
        isSuccess: false,
        isLoading: true,
        rows: {}
    });

    // update clientRoles whenever our clientList changes changes (change of account, or initial load in)
    const currentUser: any = useSelector<RootState>(state => state.system.currentUser);
    // update clientRoles whenever our clientList changes changes (change of account, or initial load in)
    useEffect(() => {
        const output: KeyedRoleSet = {
            isSuccess: true,
            isLoading: false,
            rows: {}
        };

        Object.entries(currentUser.clients as {[clientId:string]:IClientDetails}).forEach((entry:[string, IClientDetails]) => {
            output.rows[entry[0]] = {
                key: entry[0],
                label: entry[1].name,
                roles: data.clients[entry[0]] ? data.clients[entry[0]].split(',') : data.accountAdmin === 'true' ? ['admin','write','read'] : ['none']
            };
        });
        setClientRoles(output);
    }, [setClientRoles, data.clients, data.accountId, data.accountAdmin, currentUser]);

    const newRecord: () => IUser = () => new User("");

    const fields: IField[] = useMemo(() => {
        return [            
            mode === "create"
                ? TextField("username", "Username", undefined, undefined, {
                      required: { format: `A unique Username is requried.` },
                      minLength: { value: 5, message: { format: `The Username must be at least 5 characters.` } },
                      maxLength: {
                          value: 50,
                          message: { format: `username should not be more than 50 characters.` }
                      },
                      noLeadingSpaces: { format: `The username contains leading whitespace.` },
                      noTrailingSpaces: { format: `The username contains trailing whitespace.` }
                  })
                : JSXField(
                      "username",
                      "Username",
                      <span>
                          <Tooltip title="Username cannot be changed">{data.username}</Tooltip>
                          <CopyOutlined
                              style={{ cursor: "pointer", marginLeft: 4 }}
                              onClick={() => {
                                  navigator.clipboard.writeText(data.username);
                                  notification.success({ message: "Username copied to clipboard" });
                              }}
                          />
                      </span>
                  ),
            mode === "create"
                ? TextField("email", "Email Address", undefined, undefined, {
                      required: { format: "An email address is required." },
                      noLeadingSpaces: { format: `The email address contains leading whitespace.` },
                      noTrailingSpaces: { format: `The email address contains trailing whitespace.` },
                      minLength: { value: 5, message: { format: `The email address must be at least 5 characters.` } },
                      isEmail: { message: { format: `The email address is not in a valid format.` } }
                  })
                : JSXField(
                      "email",
                      "Email Address",
                      <span>
                          <Tooltip title="Email address is cannot be changed">{data.email}</Tooltip>
                          <CopyOutlined
                              style={{ cursor: "pointer", marginLeft: 4 }}
                              onClick={() => {
                                  navigator.clipboard.writeText(data.email);
                                  notification.success({ message: "Email address copied to clipboard" });
                              }}
                          />
                      </span>
                  ),
            ...(!isTruthy(data.accountAdmin)
                ? Object.keys(clientRoles.rows).length > 0
                    ? [
                          RoleAssignmentGridField(
                              "clients",
                              "Client Roles",
                              "Client Name",
                              5,
                              clientRoles,
                              ROLES,
                              setClientRoles,
                              true,
                              false
                          )
                      ]
                    : [LabelField("clients", "Client Roles", "This account has no clients")]
                : [])
        ];
    }, [mode, data.username, data.email, data.accountAdmin, clientRoles]);

    const createSaveHander = useCallback((action: string) => async (data: EditorRecordType) => {
        const newClientRoles: { [clientId: string]: string } = {};
        Object.values(clientRoles.rows).forEach(r => {
            if (r.roles.length === 1 && r.roles[0] === "none") {
                return;
            }
            newClientRoles[r.key] = r.roles.join(",");
        });
        const record = { ...data, clients: newClientRoles };

        try {
            // save the user
            const updatedUser = await (mode === "create" ? createUser(record) : updateUser(record)).unwrap();
            onAction(action, updatedUser);
        } catch (error: any) {
            // suppress error already handled in axios
            if (!error.status) {
                throw error;
            }
        }
    }, [clientRoles.rows, mode, createUser, updateUser, onAction]);

    const actions: EditorAction[] = useMemo(
        () => [
            {
                action: "save",
                label: "Save",
                validate: true,
                handler: createSaveHander("save")
            },
            {
                action: "saveAndAddUser",
                label: "Save and add another user",
                validate: true,
                handler: createSaveHander("saveAndAddUser")
            },
            {
                action: "cancel",
                label: "Cancel",
                validate: false,
                handler: async (data: EditorRecordType) => onAction("cancel", data as IUser)
            }
        ],
        [createSaveHander, onAction]
    );

    return Editor({
        ...props,
        title: mode === "create" ? "Create User" : "Edit User",
        fields,
        newRecord,
        actions,
        isLoading: false,
        width: 750
    });
};

export default UserEditor;
