import { FunctionComponent } from "react";
import { Col, Row, Table, Typography } from "antd";
import { templates } from "./template";

const { Title, Paragraph } = Typography;

interface OwnProps {
  temp?: any;
}

type Props = OwnProps;

const Templates: FunctionComponent<Props> = (props) => {
  const columns = [
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
      render: (text: string, record: any) => (
        <a id={`anchor-report-${record.Name.replaceAll(/[ _()]/g,'-')}`} href={record.Path}>{record.Name}</a>
      ),
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
    },
  ];

    return (
    <Row>
      <Col>
        <Typography>
          <Title level={4}>Import Templates</Title>
          <Paragraph>
            Import templates are a convenient way to make bulk changes, such as
            uploading hundreds of leases at once. Using the templates is
            optional as changes can also be made directly from within EZLease.
            Click a template name to download the file. Visit the Success Center
            for more information on using import templates.
          </Paragraph>
        </Typography>
      </Col>
      <Col>
        <Table rowKey={({Name}) => Name} columns={columns} dataSource={templates} pagination={false} />
      </Col>
    </Row>
  );
};

export default Templates;
