import { FunctionComponent, useEffect, useMemo } from 'react';
import { Card, Typography, Spin } from "antd";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useLazyGetLeaseTotalsQuery } from '../../../redux/actions/dashboard';

const { Title: TitleText } = Typography

interface OwnProps {
    clientId?: string | null;
    scope?: string;
}

type Props = OwnProps;
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
            display: false
        },
        title: {
            display: false,
        },
    },
};

const LeaseTotals: FunctionComponent<Props> = ({ clientId, scope }) => {
    const [getLeaseTotal, leaseTotal] = useLazyGetLeaseTotalsQuery()

    useEffect(() => {
        if (scope && clientId) {
            getLeaseTotal({ clientId: clientId, scope: scope })
        }
    }, [scope, clientId, getLeaseTotal])

    const data = useMemo(() => {
        const leases = leaseTotal?.data?.classifications;
        return {
            labels: (leases || []).map((e: any) => e.classification),
            datasets: [
                {
                    label: 'Lease Total',
                    data: (leases || []).map((e: any) => e.leases),
                    backgroundColor: '#7C9FD6',
                },
            ],
        }
    }, [leaseTotal?.data]);

    return (

        <Card style={{ height: "490px" }}>

            <TitleText level={5}>
                Lease Totals
            </TitleText>
            <div style={{ paddingTop: "200px" }}></div>
            {leaseTotal.isFetching || leaseTotal.status === 'pending' ? 
            <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Spin />
            </div>
            :
            clientId && scope ? 
                (data.datasets[0].data.length > 0 ? 
                    <Bar options={options} data={data} /> 
                    :  <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>No leases to display.</div>
                )
                :  <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Please select client and scope.</div>
            }
        </Card>

    );

};

export default LeaseTotals;
