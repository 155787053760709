import { ButtonProps, Popconfirm } from "antd";
import React, { FunctionComponent, useState } from "react";

interface IPopConfirmProps {
  onConfirm: () => any | void;
  inLoadingOk?: boolean;
  label?: string;
  children?: any;
  popconfirmTitle: any;
  okButtonText?: string;
  cancelButtonText?: string;
  okButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
  placement?: 'topLeft' | 'top' | 'topRight' | 'leftTop' | 'left' | 'leftBottom' | 'rightTop' | 'right' | 'rightBottom' | 'bottomLeft' | 'bottom' | 'bottomRight';
}

type Props = IPopConfirmProps;

const PopConfirmHelper: FunctionComponent<Props> = ({
  onConfirm,
  inLoadingOk,
  label,
  children,
  popconfirmTitle,
  okButtonText = 'Ok',
  cancelButtonText = 'Cancel',
  okButtonProps = {type:"primary"},
  cancelButtonProps = {type:"default", danger:true},
  placement
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  return (
    <Popconfirm
      title={popconfirmTitle}
      onConfirm={async () => {
          await onConfirm();
          setIsVisible(false);
      }}
      open ={isVisible}
      onCancel={() => setIsVisible(false)}
      okButtonProps={{ loading: inLoadingOk,...okButtonProps }}
      cancelButtonProps={{ disabled: inLoadingOk, ...cancelButtonProps }}
      okText={okButtonText}
      cancelText={cancelButtonText}
      placement={placement}
    >
      {children ? (
        React.cloneElement(children, {
          ...children.props,
          onClick: () => setIsVisible(true),
        })
      ) : (
        <a
          onClick={() => setIsVisible(true)}
          style={{ padding: 0 }}
        >
          {label}
        </a>
      )}
    </Popconfirm>
  );
};

export default PopConfirmHelper;