import { FunctionComponent, useMemo, useState, useEffect } from 'react';
import { Badge, Button, Dropdown, Menu, Modal, PageHeader, Space, Select } from 'antd';
import { DownOutlined, NotificationOutlined } from '@ant-design/icons'
import logo from "../../images/EZLease_Logo.svg";
import WhatsNewPanel from "../../components/WhatNewPanel";
import SelectVersion from '../../pages/Portal/Home/SelectVersion';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setClient } from '../../redux/slices/clientSlice';
import { RootState } from '../../redux/store';
import { pretty } from '../../utils/inject';
import { AuthEventData } from "@aws-amplify/ui";


interface ButtonHandler {
    key: string;
    label?: string;
    buttons?: ButtonHandler[];
    onClick?: () => void;
    
}

interface Props {
    containerClassName?: string;
    signOut?: (data?:AuthEventData|undefined) => void;

}

const HeaderLayout: FunctionComponent<Props> = ({ containerClassName, signOut }) => {
    const dispatch = useDispatch();
    const user = useSelector((state:RootState) => state.system.currentUser);
    const clientId = useSelector((state:RootState) => state.client.clientId);

    const activeClient = useMemo(() => {
        if (user && clientId) {
            return user.clients[clientId]
        }
        return undefined;
    }, [user, clientId]);
    const clientList = useMemo(() => {
        if (user) {
            return Object.keys(user.clients).map(clientId => ({ clientId: clientId, name: user.clients[clientId].name }));
        }
        return [];
    }, [user]);

    const [searchValue, setSearchValue] = useState<string>('');

    const filteredOptions = useMemo(() => {
        return !searchValue ? clientList : clientList.filter(
            (client) =>
                client.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
        );
    }, [clientList, searchValue]);


    const handleSearch = (value: string) => {
        setSearchValue(value);
    };

    const handleClientSelect = (clientId: string) => {
        dispatch(setClient({ clientId: clientId }));
    };

    useEffect(() => {
        if (user && !clientId) {
            const ids = Object.keys(user.clients);
            if (ids.length > 0) {
                dispatch(setClient({clientId: ids[0]}));
            }
        }
    }, [user, clientId, dispatch])

    const location = useLocation();

    const [isWhatsNewModalVisible, setIsWhatsNewModalVisible] = useState<boolean>(false);

    const buttons: ButtonHandler[] = [
        { key: 'signOut', onClick: () => {
            console.log('Sign out from Header');
            signOut!(undefined);
         } },
    ];

    const isHome = useMemo(() => {
        return location?.pathname === '/' || location?.pathname === "/home" || location?.pathname === "/home/"
    }, [location])

    const hideWhatsNewModal = () => {
        setIsWhatsNewModalVisible(false);
    };

    const userdetailsHoverContainer = (
        <Menu
            // @ts-ignore
            items={[
                {
                    label: <><b>Username:  </b>{user?.username}</>,
                    key: '0',
                },
                {
                    label: <><b>Email:  </b>{user?.email}</>,
                    key: '1',
                },
            ]}
        />
    );




    const getMenu = (buttons: ButtonHandler[]) => {
        return (
            <Menu>
                {buttons.map((button) => (
                    <Menu.Item id={`menu-${button.key}`} key={button.key} onClick={button.onClick}>
                        {button.label || pretty(button.key)}
                    </Menu.Item>
                ))}
            </Menu>
        );
    };

    return (
        <>
            <PageHeader
                className={[containerClassName, 'dashboard-header'].join(' ')}
                title={
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <img src={logo} alt={"logo"} style={{ maxWidth: 147 }} />
                        {isHome ? <SelectVersion hideSettings
                            borderRadius={50}
                            buttonStyle={{ height: 35, padding: '0 10px', display: "flex", alignItems: "center", justifyContent: "center" }}
                            containerStyle={{ marginLeft: 20 }} /> : <div style={{ height: 35, width: 35 }} />}
                    </div>
                }
                extra={
                    <>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Button
                                id="button-whats-new"
                                type="link"
                                onClick={() => setIsWhatsNewModalVisible(true)}
                            >
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <div style={{ width: 20, height: 20, position: "relative" }}>
                                        <span style={{ color: "#000" }}>
                                            <NotificationOutlined color={"#000"} />
                                        </span>
                                        <div style={{ position: "absolute", top: -5, right: 5 }}>
                                            <Badge dot />
                                        </div>
                                    </div>
                                    <span>What's New</span>
                                </div>
                            </Button>
                            {clientList.length > 0 && (
                                <>
                                    {clientList.length <= 1 ? (
                                            <>
                                            <span style={{ marginLeft: '10px', marginRight: '20px' }}>
                                              Client: {activeClient ? activeClient.name : ''}
                                            </span>
                                          </>
                                    ) : (
                                        <Select
                                            showSearch
                                            value={activeClient ? activeClient.name : undefined}
                                            onChange={handleClientSelect}
                                            onSearch={handleSearch}
                                            placeholder="Select a client"
                                            style={{ width: 250, marginLeft: '10px', marginRight: '30px' }} 
                                            filterOption={false}
                                            notFoundContent={null}
                                        >
                                            {filteredOptions.map((client) => (
                                                <Select.Option key={client.clientId} value={client.clientId}>
                                                    {client.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </>
                            )}
                            <Dropdown overlay={userdetailsHoverContainer} placement="bottomLeft">
                                <a href="/" onClick={(e) => e.preventDefault()}>
                                    <Space style={{ color: "black" }}>
                                        {user?.username}
                                        <DownOutlined />
                                    </Space>
                                </a>
                            </Dropdown>

                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            {buttons.map((button) => {
                                if (button.buttons)
                                    return (
                                        <Dropdown overlay={getMenu(button.buttons)} key={button.key}>
                                            <Button id={`button-${button.key}`} type={'link'} onClick={button.onClick}>
                                                {button.label || pretty(button.key)} <DownOutlined />
                                            </Button>
                                        </Dropdown>
                                    );
                                return (
                                    <Button id={`button-${button.key}`} type="link" key={button.key} onClick={button.onClick}>
                                        {button.label || pretty(button.key)}
                                    </Button>
                                );
                            })}
                        </div></>}
            />
            <Modal
                title="What's New"
                open={isWhatsNewModalVisible}
                footer={[
                    <Button id="button-okay" key="back" onClick={hideWhatsNewModal}>
                        OK
                    </Button>,
                ]}
                closable={false}
                onCancel={hideWhatsNewModal}
                width="70vw"
                className="ezlease-modal"
                style={{ height: 'calc(100vh - 200px)' }}
                bodyStyle={{ overflowY: 'scroll', height: '100%' }}
            >
                <WhatsNewPanel />
            </Modal>
        </>
    );
};

export default HeaderLayout;