import { Button, Col, Modal, Row, Spin, Tooltip } from "antd";
import { FunctionComponent, useState, useEffect, ReactElement, CSSProperties } from "react";
import Forms, { IField } from "./Forms-Admin";
import { ButtonType } from "antd/lib/button";

export type EditorValidationMessages = Record<string, string[]>;

export type EditorRecordType = Record<string, any>;

export type EditorMode = "closed" | "edit" | "create";

export type EditorActionStyle = "danger" | "warning" | "default" | "disabled" | "primary";

export type EditorAction = {
    action: string;
    label: string|ReactElement;
    validate: boolean;
    type?: ButtonType;
    style?: EditorActionStyle;
    props?:any;
    enabled?:boolean;
    tooltip?:string|ReactElement;
    handler: (record: EditorRecordType) => void;
};

export interface IEditorProps {
    width?: number;
    title: string|ReactElement;
    state: [EditorRecordType, (draft: EditorRecordType) => void];
    newRecord: () => EditorRecordType;
    mode: EditorMode;
    fields: IField[];
    /**
     * Preemptively modify/replace record when mode is create
     */
    onCreate?: (record: EditorRecordType) => EditorRecordType;
    /**
     * Preemptively modify/replace record when mode is edit
     */
    onEdit?: (record: EditorRecordType) => EditorRecordType;
    onValidate?: (record: EditorRecordType) => EditorValidationMessages;
    isLoading: boolean;
    actions: EditorAction[];
}

function getStyleImpl(style: string | undefined): CSSProperties | undefined {
    if (!style) {
        return undefined;
    }
    switch (style) {
        case "danger":
            return {
                color: "#D22B2B",
                borderColor: "D22B2B"
            };
    }
    return undefined;
}

function getStyle(style: string | undefined): CSSProperties | undefined {
    const styleOut = getStyleImpl(style);
    return styleOut;
}

/**
 * T - the data type of the editor
 * P - additional props needed to customise the editor in a descendant
 * O - props provided by the customised editor in the descendant
 */
const Editor: FunctionComponent<IEditorProps> = (props: IEditorProps) => {
    const { title, state, mode, onCreate, onEdit, onValidate, fields, actions, isLoading, width } = props;
    const [errors, setErrors] = useState<EditorValidationMessages>({});
    const [record, updateRecord] = state;
    useEffect(() => {
        if (mode === "create") {
            if (onCreate) {
                const newData = onCreate(record);
                updateRecord(newData);
            }
        } else if (mode === "edit") {
            if (onEdit) {
                const newData = onEdit(record);
                updateRecord(newData);
            }
        }
    }, [mode, onCreate, onEdit, record, updateRecord]);

    const footer = (
        <Row
            justify="end"
            gutter={5}
            style={{ display: "flex", justifyContent: "space-between", margin: "25px" }}>
            {actions.map((action: EditorAction) => (
                <Col key={`col-${action.action}`}>
                    <Tooltip title={action.tooltip}>
                    <Button
                        key={`button-${action.action}`}
                        onClick={() => {
                            if (action.validate) {
                                let newErrors: EditorValidationMessages = Forms.validate(record, fields);
                                if (onValidate) {
                                    newErrors = { ...newErrors, ...onValidate(record) };
                                }
                                setErrors(newErrors);
                                if (Object.values(newErrors).filter(field => field?.length > 0).length > 0) {
                                    return;
                                }
                            }
                            action.handler(record);
                        }}
                        type={action.type}
                        style={getStyle(action.style)}
                        disabled={isLoading || (action.enabled !== undefined && !action.enabled) }
                        {...action.props}>
                        {action.label}
                    </Button>
                    </Tooltip>
                </Col>
            ))}
        </Row>
    );

    return (
        <Modal
            key="edit-modal"
            closable={!isLoading} // Prevent modal from closing during loading
            open={mode !== "closed"}
            maskClosable={false}
            onCancel={() => {
                const cancelAction = actions.find((a: EditorAction) => a.action === "cancel");
                if (cancelAction) {
                    cancelAction.handler(record);
                }
            }}
            title={title}
            footer={footer}
            confirmLoading={isLoading}
            width={width}>
            {isLoading ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: "rgba(255, 255, 255, 0.8)",
                        zIndex: 100
                    }}>
                    <Spin size="large" />
                </div>
            ) : (
                <Forms
                    state={[record, updateRecord]}
                    errors={errors}
                    fields={fields}
                />
            )}
        </Modal>
    );
};

export default Editor;
