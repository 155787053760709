import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { reportsApi } from "./actions/reports";
import { dashboardApi } from "./actions/dashboard";
import clientReducer from "./slices/clientSlice";
import { userApi } from "./actions/userApi";
import { clientApi } from './actions/clientApi';
import { accountApi } from './actions/accountApi';
import { systemSlice } from "./slices/systemSlice";

export const store = configureStore({
    reducer: {
        client: clientReducer,
        [reportsApi.reducerPath]: reportsApi.reducer,
        [dashboardApi.reducerPath]: dashboardApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
        [clientApi.reducerPath]: clientApi.reducer,
        [accountApi.reducerPath]: accountApi.reducer,
        system: systemSlice.reducer,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat([
        reportsApi.middleware,
        clientApi.middleware, 
        accountApi.middleware,   
        userApi.middleware, dashboardApi.middleware])
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
