import React from "react";
import { Box } from "grommet";
import { AuthEventData } from "@aws-amplify/ui";
import logo from "../images/logo.png";

export interface EnableSubscriptionNotice_Props {
  handleLogoutClick?: (data?:AuthEventData|undefined) => void;
  buttonStyleOveride?: {};
  containerStyleOveride?: {};
  // onChange?: (value: CascaderValueType, selectedOptions?: CascaderOptionType[]) => void;
}

function EnableSubscriptionNotice(props: EnableSubscriptionNotice_Props) {
  const signOutButtonStyle = {
    ...props.buttonStyleOveride,
    backgroundColor: "#13294B",
  } as React.CSSProperties;

  return (
    <div style={props.containerStyleOveride}>
      <Box alignContent="around" align="start">
        <img
          src={logo}
          alt="EZLease Logo"
          style={{ height: "30px", width: "auto" }}
        ></img>
      </Box>
      <Box alignContent="center" align="center" style={{ paddingTop: "100px" }}>
        <h2 style={{ padding: "20px" }}>
          Please contact EZLease support to have your subscription enabled
        </h2>
      </Box>
      <Box alignContent="center" align="center" style={{ paddingTop: "100px" }}>
        <button id="button-sign-out"
          className="ezl-button"
          onClick={(e) => {
            console.log('Signout from Enable Subscription');
            props.handleLogoutClick!(undefined);
          }}
          style={signOutButtonStyle}
        >
          Sign Out
        </button>
      </Box>
    </div>
  );
}

export default EnableSubscriptionNotice;