import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../utils/axiosInstance";
import { IAccount } from "../../shapes/multiclient";

export const accountApi = createApi({
    reducerPath: "accounts",
    baseQuery: axiosBaseQuery(),
    tagTypes: ["account", "accountDetails"],
    endpoints: builder => ({
        // get all accounts
        getAccounts: builder.query({
            query: () => ({
                url: "/client/accounts",
                method: "GET"
            })
        }),
        // get account details for a specific account
        getAccount: builder.query({
            query: ({ accountId }) => ({
                url: `/client/account/${accountId}`,
                method: "GET"
            }),
            async onQueryStarted(params, { dispatch, queryFulfilled }) {
                try {
                    const account = await queryFulfilled;
                    dispatch(
                        accountApi.util.updateQueryData("getAccounts", {}, (accounts: IAccount[]) =>
                            accounts.map(a => (a.accountId === account.data.accountId ? account.data : a))
                        )
                    );
                } catch (err: any) {
                    dispatch(accountApi.util.invalidateTags([{ type: "account", id: params.accountId }]));
                    console.log("accountApi.onQueryStarted", err);
                }
            },
            providesTags: (result, error) => (!error ? [{ type: "account", id: result.accountId }] : [])
        })     
    })
});

export const {
    useLazyGetAccountsQuery,
    useLazyGetAccountQuery,
    reducer
} = accountApi;
