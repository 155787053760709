import { InputNumberProps, InputNumber } from "antd";
import { FunctionComponent } from "react";
import { IBaseField, FieldTypes, IBaseValidationProps, IRuleMessage, IBaseFieldProps } from "./base";

export interface INumberValidationProps extends IBaseValidationProps {
    min?: { value: number; message: IRuleMessage };
    max?: { value: number; message: IRuleMessage };
    integer?: IRuleMessage;
}

export interface IFieldInputNumber extends IBaseField {
    type: FieldTypes.InputNumber;
    inputProps?: InputNumberProps;
    validationProps?: INumberValidationProps;
}

export interface INumberProps extends IBaseFieldProps {
    field: IFieldInputNumber;
    value: any;
    onChange: (input: any) => void;
}

export const Number: FunctionComponent<INumberProps> = ({ field, value, onChange }) => (
    <InputNumber
        id={`number-${field.fieldKey}`}
        value={value[field.fieldKey]}
        onChange={num => onChange((current: any) => ({ ...current, [field.fieldKey]: num }))}
        {...(field.inputProps || {})}
    />
);

export const NumberField = (fieldKey:string, label:string, inputProps?: InputNumberProps, validationProps?:INumberValidationProps) => ({fieldKey, label, type: FieldTypes.InputNumber, inputProps, validationProps });